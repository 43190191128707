import http from "../http-common";

export default {
    postVerifications(data){
        return http.post('/profile/passport/verifications', data)
    },

    postFiles(formData) {
        return http.post("/files/upload/profile/passport/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    postVerificationsPassport(body) {
        return http.post("/profile/passport/verifications", body);
    },
    getStatuses() {
        return http.get('/profile/passport/verifications/statuses');
    },
    getVerifications(status, page, pageSize, email) {

        const params = {
            page: page,
            pageSize: pageSize
        }

        if (status) {
            params.status = status
        }

        if (email) {
            params.email = email
        }

        return http.get('/profile/passport/verifications', {
            params
        });
    },
    getVerificationsDefault(pageSize) {
        return http.get('/profile/passport/verifications', {
            params: {
                page: 1,
                pageSize: pageSize
            }
        });
    },
    getVerificationDetail(id) {
        return http.get(`/profile/passport/verifications/${id}`)
    },

    updateStatusAppeal(id, status, comment) {
        return http.put(`/profile/passport/verifications/${id}`, {
            status: status,
            comment: comment
        });
    },
    deleteAppeal(id){
        return http.delete(`/profile/passport/verifications/${id}`)
    }
    // get() {
    //     return http.get('/profile/me');
    // },

    // getProfile(email) {
    //     return http.post('/profile/user', {email});
    // },

    // resetPassword(data) {
    //     return http.post('/auth/resetpassword', data);
    // },

    // create(data) {
    //     const refCode = data.refCode;
    //     return http.post(`/auth/register/${refCode}`, data);
    // },

    // login(data) {
    //     return http.post('/auth/login', data);
    // },

    // updatePassword(data) {
    //     return http.put('/auth/updatePassword', data);
    // },

    // updateProfile(data) {
    //     return http.put('/profile', data);
    // },
}
