<template>
  <div>
  <Breadcrumbs title="МЕРОПРИЯТИЯ"/>
  <div class="row">
    <div class="col-md-12 project-list">
      <div class="card">
        <div class="row">


          <div class="col-md-6 d-flex">
            <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist" v-for="(item,index) in tab" :key="index">
              <li class="nav-item"><a class="nav-link" :class="{'active': item.active}" id="top-home-tab"
                                      data-bs-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="top-home"
                                      :aria-selected="item.active ? 'true':'false'" @click.prevent="active(item)">
                <vue-feather :type=item.icon></vue-feather>
                {{ item.name }}</a></li>

            </ul>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-0 me-0"></div>
            <router-link class="btn btn-outline-primary" to='/events/list/create'
                         v-if="userRole.id===2">
              <vue-feather class="me-1" type="plus-square"></vue-feather>
              Добавить
            </router-link>

          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="container-fluid">

    <div class="col-xl-12" v-for="(event,index) in events" :key="index">
      <div class="card">
        <div class="blog-box blog-list row p-3">
          <div class="col-sm-5">
            <router-link
              :to="'/events/list/' + event.id"
            >
            <img class="img-fluid sm-100-w"
                 :src="this.FRONT_URL+event.previewImagePath"
                 alt="">
            </router-link>
            </div>
          <div class="col-sm-7">
            <div class="blog-details">
              <div class="blog-date">
                <span v-text="this.date(event.meetingDateStart,1)"></span>
                {{ this.date(event.meetingDateStart, 2) }}
                {{ this.date(event.meetingDateStart, 3) }}


              </div>
              <h6 v-text="event.topic"></h6>
              <div class="blog-bottom-content">
                <ul class="blog-social">
                  <li v-text="event.speaker"></li>
                  <li class="digits" v-if="event.isOnline"> Онлайн</li>
                  <li class="digits" v-else>Офлайн</li>
                </ul>
                <div class="mt-3" v-if="event.isOnline">
                  <a v-if="event.meetingRecordUrl" :href="event.meetingRecordUrl" target="_blank" class="btn btn-light">Запись
                    мероприятия</a>
                  <a v-else v-if="event.meetingPlace" :href="event.meetingPlace" target="_blank"
                     class="btn btn-light">Подключиться</a>
                </div>
                <div v-else class="mt-3">
                  <p class="mt-0">{{ event.meetingPlace }}</p>
                  <a v-if="event.meetingRecordUrl" :href="event.meetingRecordUrl" class="btn btn-light" target="_blank">Запись
                    мероприятия</a>
                </div>

                <hr>
                <p class="mt-0" v-text="event.shortDescription"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper p-15">
            <router-link
              v-if="userRole.id === 2"
              class="btn btn-outline-primary btn-change"
              :to="'/events/edit/' + event.id"
            >
              Редактировать
            </router-link>
           <router-link
            class="btn btn-outline-primary btn-more"
            :to="'/events/list/' + event.id"
         >
            Подробнее
          </router-link>
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import MeetingDataService from "@/services/MeetingDataService";

export default {
  data() {
    return {
      tab: [
        {
          type: "doingPage",
          name: "Текущие",
          active: true,
          icon: "info",
          id: "top-profile",
          label: "profile-top-tab",
        },
        {
          type: "donePage",
          name: "Прошедшие",
          active: false,
          icon: "check-circle",
          id: "top-contact",
          label: "contact-top-tab",
        },
        {
          type: "allPage",
          name: "Все",
          active: false,
          icon: "target",
          id: "top-home",
          id1: "top-tabContent",
          label: "top-home-tab",
        },
      ],
      events: [],
      userRole: {}
    };
  },

  mounted() {
    this.getAllNotStarted();
    this.getUser();
  },
  methods: {

    getUser() {
      UserDataService.get()
        .then(({ data }) => {
          this.userRole = data.user.role;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAll() {
      MeetingDataService.getAll()
        .then(({ data }) => {
          this.events = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllFinished() {
      MeetingDataService.getAllFinished()
        .then(({ data }) => {
          this.events = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllNotStarted() {
      MeetingDataService.getAllNotStarted()
        .then(({ data }) => {
          this.events = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    active(item) {
      if (!item.active) {
        this.tab.forEach((a) => {
          a.active = false;
        });
      }
      item.active = !item.active;

      if (item.active)
        switch (item.type) {
          case "doingPage":
            this.getAllNotStarted();
            break;
          case "donePage":
            this.getAllFinished();
            break;
          case "allPage":
            this.getAll();
            break;
        }
    },
    date(dateString, who) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

      const date = new Date(dateString);
      const monthIndex = date.getMonth(); // Месяц (индекс, начиная с 0)
      const hours = date.getUTCHours() + 3; // Часы в московском часовом поясе (UTC +3)
      const minutes = date.getMinutes(); // Минуты

      if (who === 1) return date.getDate(); // День (число)
      if (who === 2) return monthNames[monthIndex];
      if (who === 3) return hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    },
  },
};
</script>
<style scoped>
.btn.btn-outline-primary:hover{
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
</style>
