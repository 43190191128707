<template>
  <div>
    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
         tabindex="-1">
      <div class="modal-dialog modal-dialog-centered form-data">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">ВЕРИФИКАЦИЯ</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
          </div>
          <div v-if="!fillingPassport"
               class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
            <img src="@/assets/svg/info-circle.svg" alt="">
            <h4 class="title my-3" style="color: #444444">
              Ошибка!
            </h4>
            <div class="subtitle text-center mb-3" style="color: #52526CBF">
              Для прохождении верификации необходимо сначала заполнить паспортные данные у себя в профиле!
            </div>
            <div class="btn-wrapper w-100">
              <div class="btn btn-primary" data-bs-dismiss="modal" aria-label="Закрыть">
                Ок
              </div>
            </div>
          </div>
          <div v-else-if="successfully"
               class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
            <img src="@/assets/svg/Vector.svg" alt="">
            <h4 class="title my-3" style="color: #444444">
              УСПЕШНО!
            </h4>
            <div class="subtitle text-center mb-3" style="color: #52526CBF">
              Ваша верификация успешно отправлена и будет рассмотрена в ближайшее время
            </div>
            <div class="btn-wrapper w-100">
              <div class="btn btn-primary" data-bs-dismiss="modal" aria-label="Закрыть">
                Ок
              </div>
            </div>
          </div>
          <div v-else-if="error"
               class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
            <img src="@/assets/svg/info-circle.svg" alt="">
            <h4 class="title my-3" style="color: #444444">
              Ошибка!
            </h4>
            <div class="subtitle text-center mb-3" style="color: #52526CBF">
              {{ errorMessage }}
            </div>
            <div class="btn-wrapper w-100">
              <div class="btn btn-primary" data-bs-dismiss="modal" aria-label="Закрыть">
                Ок
              </div>
            </div>
          </div>
          <div v-else-if="isLoading" class="modal-body d-flex justify-content-center align-items-center my-5">
            <div class="loader"></div>
          </div>
          <div v-else class="modal-body">
            <div v-if="message"
                 class="alert alert-success text-center d-flex justify-content-center aling-items-center">
              {{ message }}
            </div>
            <div v-if="errorMessage"
                 class="alert alert-danger text-center d-flex justify-content-center aling-items-center">
              {{ errorMessage }}
            </div>
            <p class="text-danger p-3">
              Максимальный объем загрзки файлов 30мб. <br>
              Загружать файлы можно в формате jpeg, ipg, png, HEIC
            </p>
            <div class="d-flex justify-content-center wrapper-preview aling-items-center px-3">
              <div v-if="showImage1">
                <img v-if="selectedImage1" :src="selectedImage1" class="wrapper-preview my-2" alt="Ошибка">
                <div v-else class="mb-3"> Изображение не добавлено</div>
              </div>
              <div v-if="showImage2">
                <img v-if="selectedImage2" :src="selectedImage2" class="wrapper-preview my-2" alt="Ошибка">
                <div v-else class="mb-3"> Изображение не добавлено</div>
              </div>
              <div v-if="showImage3">
                <img v-if="selectedImage3" :src="selectedImage3" class="wrapper-preview my-2" alt="Ошибка">
                <div v-else class="mb-3"> Изображение не добавлено</div>
              </div>
              <img v-if="showexample1" src="@/assets/images/verification/pastortwithaface.jpg"
                   class="wrapper-preview my-2" alt="Изображение не добавлено">
              <img v-if="showexample2" src="@/assets/images/verification/pastortitself.jpg" class="wrapper-preview my-2"
                   alt="Изображение не добавлено">
              <img v-if="showexample3" src="@/assets/images/verification/pageregistrationpassports.jpg"
                   class="wrapper-preview my-2" alt="Изображение не добавлено">
            </div>
            <!-- <div class="d-flex justify-content-center wrapper-preview aling-items-center">
                <img v-if="selectedImage" :src="selectedImage" class="wrapper-preview" alt="Selected Image">
            </div> -->
            <form action="#" @submit.prevent="onSubmitForm">
              <div class="border-1 border-top p-3">
                <div>
                  <p class="text-start">
                    Фото с вами с развернутым паспортом
                  </p>
                  <input type="file" name="postPreviewField" id="postPreviewField" class="form-control"
                         @change="onSelectFirstImageFile" accept="image/*" required>
                </div>
                <div class="btn btn-primary mt-2 mb-2"
                     @click="showexample1 = false, showexample2 = false, showexample3 = false, showImage1 = true, showImage2 = false, showImage3 = false">
                  Показать изображение
                </div>
                <div class="btn btn-outline-primary mt-2 mb-2 mx-2" @click="showExampleImage1">Показать пример</div>
              </div>
              <div class="border-1 border-top p-3">
                <p class="text-start">
                  Фото с отдельным паспортом (разворот с первыми двумя страницами)
                </p>
                <input type="file" name="postPreviewField" id="postPreviewField" class="form-control"
                       @change="onSelectSecondImageFile" accept="image/*" required>
                <div class="btn btn-primary mt-2 mb-2"
                     @click="showexample1 = false, showexample2 = false, showexample3 = false, showImage1 = false, showImage2 = true, showImage3 = false">
                  Показать изображение
                </div>
                <div class="btn btn-outline-primary mt-2 mb-2 mx-2" @click="showExampleImage2">Показать пример</div>
              </div>
              <div class="border-1 border-top p-3">
                <p class="text-start">
                  Фото с отдельным паспортом (разворот с страницей регистрации)
                </p>
                <input type="file" name="postPreviewField" id="postPreviewField" class="form-control"
                       @change="onSelectThirdImageFile" accept="image/*" required>
                <div class="btn btn-primary mt-2 mb-2"
                     @click="showexample1 = false, showexample2 = false, showexample3 = false, showImage1 = false, showImage2 = false, showImage3 = true">
                  Показать изображение
                </div>
                <div class="btn btn-outline-primary mt-2 mb-2 mx-2" @click="showExampleImage3">Показать пример</div>
              </div>
              <div
                  v-if="!isFileSelected && isTouched7"
                  class="invalid-feedback"
              >
                Пожалуйста, выберите изображение.
              </div>
              <div class="modal-footer d-flex justify-content-end aling-items-center">
                <button class="btn btn-primary" type="submit">Отправить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!btn" class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" @click="getUser">
    Пройти верификацию
  </div>
  <div v-else class="btn btn-castom" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" @click="getUser">
    Пройти верификацию
  </div>

</template>
<script>
import UserDataService from "@/services/UserDataService";
import VerificarionsDataService from "@/services/VerificarionsDataService";

export default {
  props: {
    btn: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      fillingPassport: false,
      errorMessage: null,
      message: null,
      selectedImage1: null,
      selectedImage2: null,
      selectedImage3: null,
      showImage1: false,
      showImage2: false,
      showImage3: false,
      showexample1: false,
      showexample2: false,
      showexample3: false,
      successfully: false,
      error: false,
      weightFiles: false,
      isLoading: false,
      form: {},
      formData: {
        firstphoto: null,
        secondphoto: null,
        thirdphoto: null,
        error: null,
        errorMessage: null,
        url: [],
      },
    };
  },
  methods: {
    showExampleImage1() {
      this.showexample1 = true;
      this.showexample2 = false;
      this.showexample3 = false;
      this.showImage1 = false;
      this.showImage2 = false;
      this.showImage3 = false;
    },
    showExampleImage2() {
      this.showexample1 = false;
      this.showexample2 = true;
      this.showexample3 = false;
      this.showImage1 = false;
      this.showImage2 = false;
      this.showImage3 = false;
    },
    showExampleImage3() {
      this.showexample1 = false;
      this.showexample2 = false;
      this.showexample3 = true;
      this.showImage1 = false;
      this.showImage2 = false;
      this.showImage3 = false;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    onSelectFirstImageFile(e) {
      if (e.target.files[0].type.includes('image/')) {
        this.formData.firstphoto = e.target.files[0];
        this.formData.error = false;
        this.showImage2 = false;
        this.showImage3 = false;
        this.showexample1 = false;
        this.showexample2 = false;
        this.showexample3 = false;
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage1 = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.formData.error = true;
        this.formData.errorMessage = "Загружать можно только изображения!"
        e.target.value = null;
        console.log("Ошибка!");
      }
    },
    onSelectSecondImageFile(e) {
      if (e.target.files[0].type.includes('image/')) {
        this.formData.secondphoto = e.target.files[0];
        this.formData.error = false;
        this.showImage1 = false;
        this.showImage3 = false;
        this.showexample1 = false;
        this.showexample2 = false;
        this.showexample3 = false;
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage2 = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.formData.error = true;
        this.formData.errorMessage = "Загружать можно только изображения!"
        e.target.value = null;
        console.log("Ошибка!");
      }
    },
    onSelectThirdImageFile(e) {
      if (e.target.files[0].type.includes('image/')) {
        this.formData.thirdphoto = e.target.files[0];
        this.formData.error = false;
        this.showImage1 = false;
        this.showImage2 = false;
        this.showexample1 = false;
        this.showexample2 = false;
        this.showexample3 = false;
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage3 = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.formData.error = true;
        this.formData.errorMessage = "Загружать можно только изображения!"
        e.target.value = null;
        console.log("Ошибка!");
      }
    },

    async onSubmitForm() {
      this.isLoading = true;
      this.errorMessage = '';
      this.message = '';
      try {
        const formDataAll = new FormData();
        formDataAll.append("files", this.formData.firstphoto);
        formDataAll.append("files", this.formData.secondphoto);
        formDataAll.append("files", this.formData.thirdphoto);
        this.formData.url = await VerificarionsDataService.postFiles(formDataAll)
            .then(({data}) => {
              this.weightFiles = true
              return data.data[0];
            })
            .catch((error) => {
              if (error.response.status === 413) {
                this.weightFiles = false
                const errorMessage = error.response.data.message;
                this.message = ''
                this.errorMessage = "Вес файлов слишком большой! Загрузите фотографию не более 50 Мб!";
              }
              console.log(error.message);

            });
        if (this.weightFiles) {
          this.message = await VerificarionsDataService.postVerificationsPassport(this.formData.url)
              .then(({data}) => {
                this.errorMessage = ''
                this.message = data.message
                this.successfully = true
                return data.message;
              })
              .catch(error => {
                console.log(error.response.status);
                if (error.response) {
                  if (error.response.status === 400) {
                    const errorMessage = error.response.data.message;
                    this.message = ''
                    this.errorMessage = "Для совершения операции заполните все данные в профиле, чтобы на основании их мы могли подготовить документы.";
                  } else if (error.response.status === 413) {
                    const errorMessage = error.response.data.message;
                    this.message = ''
                    this.errorMessage = "Вес файлов не должен превышать 50 Мб!";
                  } else {
                    this.message = ''
                    console.log(error.response.statusCode);
                    const errorMessage = error.response.data.message;
                    this.errorMessage = "Что-то пошло не так, возможно вы уже подали заявление на верификацию.";
                  }
                  this.error = true;
                } else {
                  console.log("Error:", error);

                }
              });
        }
      } catch (e) {
        this.error = true;
        console.log(e);
      } finally {
        this.isLoading = false
      }


    },

    getUser() {

      UserDataService.get()
          .then((response) => {

            this.form = response.data;

            if (
                this.form.isRussiansPassport
            ) {
              if (
                  this.form.phone &&
                  this.form.firstName &&
                  this.form.lastName &&
                  this.form.dateBirthday &&
                  this.form.passportAddress &&
                  this.form.inn &&
                  this.form.snils &&
                  this.form.passportCode &&
                  this.form.gender &&
                  this.form.passportNumber &&
                  this.form.passportDate &&
                  this.form.passportIssued
              ) {

                this.fillingPassport = true

              } else {

                this.fillingPassport = false

                return;

              }

            } else {

              if (
                  this.form.phone &&
                  this.form.firstName &&
                  this.form.lastName &&
                  this.form.dateBirthday &&
                  this.form.gender &&
                  this.form.passportNumber &&
                  this.form.passportDate &&
                  this.form.passportIssued
              ) {

                this.fillingPassport = true

              } else {

                this.fillingPassport = false

                return;
              }

            }

          })
          .catch((e) => {
            console.log(e);
          });
    },
    postFiles(formDataAll) {
      VerificarionsDataService.postFiles(formDataAll)
          .then(() => {


          })
          .catch((error) => {
            console.log(error);
          });
    },
    postVerifications(data) {
      VerificarionsDataService.postVerifications(data)
          .then(() => {
            this.$router.push("/events/list");
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>
<style scoped>
.modal-body {
  padding: 0;
}

.btn-outline-primary {
  color: var(--theme-deafult);
  border-color: var(--theme-deafult) !important;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}

.wrapper-preview {
  max-height: 300px;
  width: auto;
  overflow: hidden;
}

.btn-castom {
  background: #fff;
}

.form-data {
  color: black;
}

.text-danger {
  color: var(--theme-secondary);
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #474747;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
