<template>
  <Breadcrumbs :main="paymentData.name" title="ПОКУПКА"/>
  <div class="container invoice" id="print">
    <div class="row">
      <div class="col">
        <form id="checkoutForm" class="mx-auto">
          <div class="card">
            <div class="card-body">

              <p class="alert alert-warning" v-if="errorMessage">{{ errorMessage }}</p>
              <p class="alert alert-success" v-if="successMessage">{{ successMessage }}</p>

              <p class="card-subtitle mb-2 text-muted text-end">
                Сайт не собирает и не хранит данные Ваших карт.</p>

              <div class="row">
                <div class="border border-secondary rounded  col-xl-6 col-sm-12 p-3">
                  <div class="">
                    <label for="c_cardnumber" class="form-label">Номер карты</label>
                    <input type="text"
                           id="c_cardnumber"
                           class="form-control"
                           v-mask="'0000 0000 0000 0000'"
                           v-model="cardNumber"
                           required>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <label for="c_expire" class="form-label">ММ/ГГ</label>
                      <input type="text"
                             class="form-control text-sm-start ps-1"
                             id="c_expire"
                             v-mask="'00/00'"
                             v-model="expire"
                             required>
                    </div>

                    <div class="col-sm-3">
                      <label for="c_secret" class="form-label">CVV</label>
                      <div class="input-group has-validation">
                        <input type="password" class="form-control"
                               id="c_secret"
                               v-mask="'000'"
                               v-model="secret"
                               required>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <label for="c_name" class="form-label">Имя Владельца (как на карте)</label>
                    <div class="input-group has-validation">
                      <input type="text" class="form-control"
                             id="c_name"
                             v-model="name"
                             required>
                    </div>
                  </div>
                  <button
                      class="w-100 btn btn-primary btn-lg mt-3"
                      type="button"
                      @click="createCryptogram" id="c_generate">Оплатить
                  </button>
                </div>
                <div class=" col-xl-6 col-sm-12 p-3">
                  <div class="checkout-details">
                    <div class="order-box">
                      <div class="title-box">
                        <div class="checkbox-title">
                          <h4 class="mb-0">Оплата</h4>
                        </div>
                      </div>
                      <ul class="qty">
                        <li>{{ paymentData.name }}</li>
                      </ul>
                      <ul class="sub-total">
                        <li>Цена <span class="count">{{ paymentData.summa }} ₽</span></li>
                        <li v-if="paymentData.isPromoCode">
                          Скидка
                          <span class="count">{{ paymentData.summa * 0.5 }} ₽</span></li>
                      </ul>
                      <ul class="sub-total total">
                        <li v-if="paymentData.isPromoCode" > К оплате <span class="count">{{ paymentData.summa *0.5}} ₽</span></li>
                        <li v-else> К оплате <span class="count">{{ paymentData.summa }} ₽</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <p>У нас используется Технология Checkout — уникальная технология токенизации карт для приема платежей
                  на
                  вашем сайте. Данные платежных карт шифруются, поэтому Наш сайт не принимает участие в обработке и
                  хранении номеров, что значительно сокращает область применения требований PCI DSS.</p>
                <p> Это соответствует PCI DSS — стандарт информационной безопасности, принятый в индустрии платежных
                  карт
                  Visa и Mastercard. Соблюдать требования стандарта обязаны все компании, которые принимают карты к
                  оплате. Компания подтвердило свое соответствие.</p>
                <p>
                  Данные Вашей платежной карты гарантировано защищены в соответствии со стандартами безопасности PCI
                  DSS.
                  Данные карты вводятся на защищенной платежной странице, передача информации происходит с
                  применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым
                  банковским
                  сетям, имеющим наивысший уровень надежности. </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <form
      ref="secureForm"
      :action="acsUrl"
      method="POST"
  >
    <input type="hidden" name="PaReq" :value="paReq">
    <input type="hidden" name="MD" :value="md">
    <input type="hidden" name="TermUrl" :value="termUrl">
  </form>

</template>
<script>
import MoneyDataService from "@/services/MoneyDataService";

export default {
  name: 'invoiceViewPayment',
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      paymentData: {
        isAmountAccount: false,
        selectPaymentSystem: 0,
        typePayment: '',
        summa: '',
      },
      cardNumber: '',
      expire: '',
      secret: '',
      name: '',
      acsUrl: '',
      paReq: '',
      md: '',
      termUrl: '',
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://checkout.cloudpayments.ru/checkout.js';
    script.onload = () => console.log('CloudPayments widget loaded');
    document.head.appendChild(script);
    Object.assign(this.paymentData, JSON.parse(localStorage.getItem('paymentData')));
  },
  methods: {
    createCryptogram() {
      try {
        if (!this.cardNumber || !this.expire || !this.name || !this.secret) {
          this.errorMessage = 'Не введены все данные';
          return;
        }

        const cardData = {
          cvv: this.secret,
          cardNumber: this.cardNumber,
          cardExpiry: this.expire,
          expDateMonth: this.expire.split('/')[0],
          expDateYear: this.expire.split('/')[1],
          name: this.name,
        };

        const checkout = new cp.Checkout({
          publicId: 'pk_6821d00ec20d62b1b9ad4e89f6d4c',
        });

        checkout.createPaymentCryptogram(cardData)
            .then((cryptogram) => {
              this.payment(cryptogram);
            })
            .catch((errors) => {
              this.errorMessage = 'Проверьте реквизиты карты';
              console.log(errors);
            });
      } catch (e) {
        this.errorMessage = 'Ошибка связи с банком. Обновите страницу и попробуйте еще раз.';
      }

    },
    payment(cryptogram) {
      MoneyDataService.payment({
        ...this.paymentData,
        nameUserCard: this.name,
        cryptogram: cryptogram
      }).then(response => {
        if (response.data.success)
          this.successMessage = 'Оплата прошла успешно.';

        if (!response.data.success) {
          this.errorMessage = response.data.message;
        }

        if (response.data.model && !response.data.success) {

          if (!response.data.model.AcsUrl) {
            this.errorMessage = 'Ошибка. Проверьте  данные карты и попробуйте еще раз.';
            return;
          }

          this.acsUrl = response.data.model.AcsUrl;
          this.paReq = response.data.model.PaReq;
          this.md = response.data.model.MD;
          this.termUrl = response.data.model.termUrl;
          this.$nextTick(() => {
            this.$refs.secureForm.submit();
          });
        }
      })
          .catch(e => {
            this.errorMessage = 'Ошибка приобретения подписки. ' + e.response.data.message;
            console.log(e);
          });

    }
  }
}
</script>