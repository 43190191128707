<template>
  <Breadcrumbs title="ИСТОРИЯ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card p-3">
          <div class="card-body d-flex justify-content-center align-items-center h-100">
            <div  v-if="money.length > 1 && !loading" class="h-100 w-100">
              <div v-for="(group, date) in groupedOperations" :key="date">
                <h5>{{ formatDate(date) }}</h5>
                <div v-for="(item) in group" :key="item.id" class="my-3 px-2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="text-item-history d-flex justify-content-center align-items-center">
                      <div>
                        <svg width="36" height="36" class="me-2">
                          <use :xlink:href="require('@/assets/svg/types-operation/icons-type.svg') + `#certificate`"></use>
                        </svg>
                      </div>
                      <span>
                      {{ item.description }}
                    </span>
                    </div>
                    <div style="white-space: nowrap">
                    <span v-if="!item.isPoint" class="font-danger">
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                      <span
                          v-if="item.isAddPoint && item.isPoint"
                          class="font-success"
                      >
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                      <span
                          v-if="!item.isAddPoint && item.isPoint"
                          class="font-warning"
                      >
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!loading">
              Пока пусто...
            </div>
            <div v-else-if="loading" class="loader-cover"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import MoneyDataService from "@/services/MoneyDataService";
import certificate from "@/assets/svg/types-operation/certificate.svg";
import course from "@/assets/svg/types-operation/course.svg"

export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      successMessage: "",
      money: [],
      isCollapsed: {},
    };
  },
  components: {
    AlertSuccess,
    AlertError,
  },
  computed: {
    groupedOperations() {
      const groups = {};

      this.money.forEach(item => {
        const date = new Date(item.updated_at);
        const dateString = date.toISOString().split('T')[0];

        if (!groups[dateString]) {
          groups[dateString] = [];
        }
        groups[dateString].push(item);

      });

      return groups;
    }
  },
  mounted() {

    this.loading = true
    MoneyDataService.history()
      .then((response) => {
        console.log("response", response.data)
        this.money = response.data;
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
      .catch((e) => {
        console.log(e);
        setTimeout(() => {
          this.loading = false
        }, 1000)
      });
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      const dayBeforeYesterday = new Date();
      dayBeforeYesterday.setDate(today.getDate() - 2);
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(today.getFullYear() - 1);

      if (date.toDateString() === today.toDateString()) {
        return 'Сегодня';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Вчера';
      } else if (date.toDateString() === dayBeforeYesterday.toDateString()) {
        return 'Позавчера';
      } else if (date < oneYearAgo) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('ru-RU', options);
      } else {
        // Форматируем дату в месяц и число
        const options = { month: 'long', day: 'numeric' };
        return date.toLocaleDateString('ru-RU', options);
      }
    },
    toggleCollapse(index) {
      for (let key in this.isCollapsed) {
        if (key !== index) {
          this.isCollapsed[key] = false;
        }
      }
      this.isCollapsed[index] = !this.isCollapsed[index];
    }
  }
};
</script>

<style scoped>
.card{
  margin: 0 auto;
  width: min(100%, 600px);
  height:  clamp(400px, 100%, 700px);
}
.card-body{
  padding:  20px 0;
  overflow: auto;
}
.card-body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.card-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
.card-body {
  font-size: 15px !important;
}
h5 {
  position: relative;
}
.sidebar-toggles {
  position: absolute;
  right: 1%;
  top: 30%;
}
.rotate180 {
  transform: rotate(90deg);
  transition: all .3s;
}
.text-item-history{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2F2F3B;
}

</style>
