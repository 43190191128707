<template>
  <div class="card wrapper-projects-top widget-hover p-5">
    <h5 class="wrapper-projects-top-title">
      Топ проектов
    </h5>
    <div class="wrapper-projects-top-items row">
      <div v-for="(item, index) of topProjects" class="col-12 col-md-6 col-xl-4 mt-4">
        <div class="img-wrapper">
          <img class="w-100 projects-top-item-img" :src="FRONT_URL + item.preview" alt="">
        </div>
        <div class="wrapper-subtitle mt-3">
          <div class="wrapper-subtitle-title">
            {{ item.name }}
          </div>
          <div class="wrapper-subtitle-author d-flex">
            Автор:
            <p class="ms-2 cursor-pointer">
              {{ item.author }}
            </p>
          </div>
          <div class="more-about d-flex justify-content-between align-items-center">
            <div class="more-about-city">
              <p class="title">
                Город
              </p>
              <p>
                {{ truncateText(item.city, 16) }}
              </p>
            </div>
            <div class="more-about-price text-end">
              <p class="title">
                Сумма сбора
              </p>
              <p>
                {{ format(item.price.toFixed(2)) }} ₽
              </p>
            </div>
          </div>
          <router-link :to="'/vote/' + item.id" class="btn-more d-block text-center widget-hover m-0">
            Поддержать проект
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import AlertSuccess from "@/components/alert-succes.vue";
  import AlertError from "@/components/alert-error.vue";
  import UserDataService from "@/services/UserDataService"
  export default {

    props: {
      topProjects: {
        type: Object
      },
    },

    data() {
      return {

      };
    },
    methods: {

      format: val => `${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '\$1 ').replace(/(\.\d+)\s+/g, '\$1'),

      truncateText(text, maxLength) {
        return text.length > maxLength
            ? text.slice(0, maxLength) + '...'
            : text;
      }
    },
  };
</script>

