<template>
  <div>
    <div class="container-fluid-login">
      
      <div class="login-main-wrapper">
        <div class="login-left">
          <h1>Добро пожаловать на платформу <span>"Рантье"</span></h1>
          <p>Мы готовы помочь вам улучшить эффективность вашей работы и повседневной жизни.</p>
          <img src="@/assets/images/login/Group64.png" alt="">
        </div>
      <div class="row row-cstm">
        <div class="col-12">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                      class="img-fluid for-light"
                      src="../assets/images/logo/logos.svg"
                      alt="looginpage"
                  />
                  <img
                      class="img-fluid for-dark"
                      src="../assets/images/logo/logo_dark.png"
                      alt="looginpage"
                  />
                </a>
              </div>
              <div v-if="refCode" class="wrapper-alert alert alert-success dark mb-2">
                <p class="text-white">
                Вы перешли по реферальной ссылке {{ owner }}
                </p>
              </div>
              <div v-else class="wrapper-alert alert alert-danger dark mb-2">
                <p style="color: white; font-size: 14px; text-align: start; line-height: 20px;">
                 Ваша реферальная ссылка недействительна.<br />
                 Ваш партнёр - аккаунт администратора.
                </p>
              </div>
              <div class="login-main">
                <form class="theme-form" >
                  <h4>Регистрация на платформе "Рантье"</h4>
                  <p>Зарегистрироваться на платформе можно только по партнерской ссылке (возьмите ее у того, кто
                    рассказал вам о проекте, либо, если такого человека нет - напишите в Поддержку).</p>
                  <p class="alert alert-danger" style="color:white" v-if="alertMessage"> {{ alertMessage }}</p>
                  <p class="alert alert-success" style="color:white" v-if="successMessage">
                    {{ successMessage }}
                    <router-link class="ms-2" tag="a" to="/auth/login">
                      Войти
                    </router-link>
                  </p>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Ваши данные</label>
                    <div class="row g-2">
                      <div class="col-6">
                        <input
                            class="form-control"
                            type="text"
                            required=""
                            v-model="form.firstName"
                            placeholder="Имя"
                        />
                      </div>
                      <div class="col-6">
                        <input
                            class="form-control"
                            type="text"
                            required=""
                            v-model="form.lastName"
                            placeholder="Фамилия"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Отчество</label>
                    <input
                        class="form-control"
                        type="text"
                        required=""
                        v-model="form.middleName"
                        placeholder="Отчество"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input
                        class="form-control"
                        type="email"
                        required=""
                        placeholder="Введите свой E-mail"
                        name="email"
                        v-model="form.email"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Telegram</label>
                    <small style="color:#898989"><br/>Введите свой логин Telegram</small>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="@nik"
                        v-model="form.telegram"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Пароль</label>
                    <div class="form-input position-relative">
                      <input
                          class="form-control"
                          :type="active?'password':'text'"
                          name="password"
                          v-model="form.password"
                          required=""
                          placeholder="*********"
                      />
                      <div class="show-hide" @click.prevent="show">
                        <img v-if="active" class="svg-icon" src="@/assets/svg/eye-close.svg" alt="">
                        <img v-else class="svg-icon" src="@/assets/svg/eye-open.svg" alt="">  
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" v-model="isPolitics"/>
                      <label class="text-muted ms-3" for="checkbox1">
                        Принимаю
                        <a class="ms-2" href="https://rentier.world/terms-of-use.pdf" target="_blank">Пользовательское
                          соглашение</a>,
                        <a class="ms-2" href="https://rentier.world/privacy-policy.pdf" target="_blank">политику
                          конфиденциальности </a> и
                        <a class="ms-2" href="https://rentier.world/cookie.pdf" target="_blank">политику использования
                          файлов cookie</a>.
                      </label>
                    </div>
                    <button type="button" @click="submitForm" class="btn btn-primary btn-block" style="width: 100%;">
                      Зарегистрироваться
                    </button>
                  </div>
                  <p class="mt-4 mb-0">
                    Уже зарегистрированы?
                    <router-link class="ms-2" tag="a" to="/auth/login">
                      Войти
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserDataService from '../services/UserDataService';

export default {
  data() {
    return {
      active: true,
      alertMessage: '',
      successMessage: '',
      isPolitics: false,
      refCode: false,
      owner: '',
      form: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        middleName: ''
      }
    }
  },
  created () {
    var refCode = this.$route.query.start
  },
  methods: {
    show() {
      this.active = !this.active
    },
    getOwnerRefCode(refCode) {
      UserDataService.getOwnerRefCode(refCode)
      .then(({data})=> {
        this.refCode = true;
        this.owner = data;
      })
      .catch((e) => {
        this.refCode = false
        console.log(e);
      })
    },
    async submitForm() {

      if (!this.isPolitics) {
        this.alertMessage = 'Необходимо принять политику безопасности.';
        return;
      }

      const refCode = this.$route.query.start;

      await UserDataService.create({...this.form, ...{refCode: refCode}})
          .then(response => {
            this.alertMessage = '';
            this.successMessage = 'Вы успешно зарегистрированы, можете войти в систему';
            this.$router.push({path: "login", query: {access: true}})
          })
          .catch(e => {
            this.alertMessage = e.response.data.message;
            console.log(e);
          });
    }

  },
  mounted() {
    this.getOwnerRefCode(this.$route.query.start)
  }
}
</script>
<style scoped>
.row-cstm{
  width: 30%
}
.svg-icon{
  width: 25px;
}
.wrapper-alert{
  max-width: 450px;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  margin: 0 auto;
  background-color: #fff;
}
.text-white{
  color: #fafafa !important;
}
.login-main{
  margin-bottom: 30px !important;
}
.login-main-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 2100px;
}
.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 117px;
}
.login-left img {
  width: 501px;
  height: 334px;
  margin-top: 167px;
}
.login-main-wrapper h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 28px;
}
.login-main-wrapper span {
  color: #7366FF;
}
.login-main-wrapper p {
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #898989;
}
@media screen and (max-width: 2052px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1900px;
  }
}
@media screen and (max-width: 1910px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1820px;
  }
}
@media screen and (max-width: 1840px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1750px;
  }
}
@media screen and (max-width: 1756px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1680px;
  }
}
@media screen and (max-width: 1684px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1600px;
  }
  .row-cstm{
    width: 40%
  }
}
@media screen and (min-height: 873px) and (max-width: 1386px){
  .login-left {
    display: none;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
  .login-main-wrapper h1 {
    font-size: 18px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-left img {
    width: 300px;
    height: 200px;
  }
}
@media screen and (max-width: 1600px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1550px;
  }
}
@media screen and (max-width: 1552px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1500px;
  }
}
@media screen and (max-width: 1536px) {
  .login-main-wrapper h1 {
    font-size: 30px;
  }
  .login-main-wrapper {
    max-width: 1300px;
  }
}
@media screen and (max-width: 1536px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 1330px){
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-left img {
    width: 400px;
    height: 250px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-main-wrapper {
    max-width: 1200px;
  }
  .row-cstm{
    width: 40%;
  }
}
@media screen and (max-width: 1214px){
  .login-main-wrapper {
    max-width: 1080px;
  }
  .row-cstm{
    width: 50%;
  }
}
@media screen and (max-width: 1280px) {
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-main-wrapper {
    margin: auto;
    margin-right: 100px;
    margin-left: 50px;
  }
  .login-left img {
    width: 400px;
    height: 250px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  .login-left {
    display: none;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
  .login-main-wrapper h1 {
    font-size: 18px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-left img {
    width: 300px;
    height: 200px;
  }
};
@media screen and (max-width: 768px) {
  .row-cstm{
    width: 100%;
  }
  .login-card{
    margin: 0 auto;
    justify-content: center;
  }
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-main{
    max-width: 450px;
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .login-main-wrapper {
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
  label a {
    font-size: 14px;
  }
}
</style>
