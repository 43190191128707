import http from "../http-common";

export default {
  /**
   * get all projects
   * @param {*} page - page number
   * @param {*} pageSize - page size
   */
  get(page, pageSize) {
    return http.get("/projects", { params: { page, pageSize } });
  },

  /**
   * get project by id
   * @param {*} id - project id
   */
  getById(id) {
    return http.get(`/projects/${id}`);
  },

  /**
   * vote for project
   * @param {*} id - project id
   * @param {*} perProject - vote for project
   * @param {*} againstProject - vote against project
   * @param {*} supportFinancially - user wants to support financially
   */
  voteForProject(id, perProject, againstProject, supportFinancially) {
    return http.post(`/projects/${id}`, {
      perProject,
      againstProject,
      supportFinancially,
    });
  },

  /**
   * Create new project (admin)
   * @param {*} data 
   * @returns 
   */
  adminCreateProject(data) {
    return http.post('/projects', data)
  },

  /**
   * Get projects (admin)
   * @param {*} page 
   * @param {*} pageSize 
   * @returns 
   */
  adminGet(page, pageSize) {
    return http.get("/projects/administrators", { params: { page, pageSize } });
  },

  /**
   * Update project (admin)
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  adminUpdateProject(id, data) {
    return http.put(`/projects/${id}/administrators`, data)
  },

  /**
   * Get statuses (admin)
   * @returns 
   */
  adminGetStatuses() {
    return http.get("/projects/administrators/statuses");
  },

  /**
   * Create category (admin)
   * @param {*} name 
   * @param {*} engName 
   * @returns 
   */
  adminCreateCategory(name, engName) {
    return http.post("/projects/administrators/category", { name, engName });
  },

  /**
   * Get categories (admin)
   * @returns 
   */
  adminGetCategories() {
    return http.get("/projects/administrators/category");
  },

  /**
   * Deletes category (admin)
   * @param {*} id 
   * @returns 
   */
  adminDeleteCategory(id) {
    return http.delete(`/projects/administrators/category/${id}`);
  },

  /**
   * Get soft removed projects (admin)
   * @returns 
   */
  adminGetSoftRemoved() {
    return http.get("/projects/administrators/storage/remote");
  },

  /**
   * Soft remove projects (admin)
   * @param {*} projectsId 
   * @returns 
   */
  adminSoftRemove(projectsId) {
    return http.delete("/projects/administrators/soft", {
      data: {
        projectsId,
      },
    });
  },

  /**
   * Restore project (admin)
   * @param {*} projectsId 
   * @returns 
   */
  adminRestore(projectsId) {
    return http.post("/projects/administrators/restore", {
      projectsId,
    });
  },

  adminUploadPreview(file) {
    const formData = new FormData();
    formData.append('preview', file);
    return http.postForm('/files/upload/projects/image/preview', formData);
  },

  adminUploadImages(files) {
    const formData = new FormData();
    for (const file of files) formData.append('images', file);
    return http.postForm('/files/upload/projects/images', formData);
  },

  adminUploadFiles(files) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
      formData.append('data', file.name);
    }
    return http.postForm('/files/upload/projects/files', formData);
  }
};
