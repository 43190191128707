<template>
    <Breadcrumbs title="ПАРТНЁРЫ"/>
    <div class="container-fluid wrapper-parents">
      <div class="row">
        <div class="col-sm-12">
          <div class="card p-4">
            <p class="m-0">
              При покупке товаров и услуг у наших партнеров вы получаете
              <span class="text-purple">
                10% кешбэк
              </span>
              на свой основной счет от продавца. Для этого достаточно при покупке назвать кодовое слово:
              <span class="text-purple">
                РАНТЬЕ
              </span>
            </p>
          </div>
          <div v-for=" item of partners " class="card p-4 card-item">
            <div class="item-partner d-flex flex-column flex-md-row justify-content-between align-items-center my-4">
              <div class="image-rantie mb-4 mb-md-0 me-0 me-md-5 flex-grow-1">
                <img class="block" src="@/assets/images/partners/grand-coupon.jpg"/>
                <img v-if="item.logoId === 1" src="@/assets/images/logo/logos.svg"/>
                <img v-else src="@/assets/images/partners/grand-coupon.jpg"/>
              </div>
              <div class="content-partner flex-grow-1">
                <h5 class="pb-3">
                  {{ item.title }}
                </h5>
                <p class="m-0">
                  {{ item.content }}
                </p>
                <br/>
                <p class="m-0" v-if="item.id === 1">
                  {{ item.contentInstr }}
                </p>
                <a class="wrapper-parents-href mt-3 d-flex" v-if="item.id === 1" :href="item.pathInstr" target="_blank">
                  <img src="@/assets/svg/network.svg" alt="">
                  <p class="ms-1">
                    Инструкция
                  </p>
                </a>
                <a class="wrapper-parents-href mt-3 d-flex" :href="item.path" target="_blank">
                  <img src="@/assets/svg/network.svg" alt="">
                  <p class="ms-1">
                    Сайт
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AlertError from '@/components/alert-error.vue';
  import AlertSuccess from "@/components/alert-succes.vue";
  import UserDataService from "@/services/UserDataService";
  import networksDataService from "@/services/NetworksDataService";

  import network_icon from "@/assets/svg/network.svg";
  
  export default {
    data() {
      return {
        referralCode: '',
        errorMessage: '',
        successMessage: '',
        network: [],
        partners: [
          {
            id: 1,
            logoId: 2,
            title: 'Сервис Grand Купон: Рекламная площадка для роста вашего бизнеса',
            content: 'Grand Купон предлагает уникальную возможность для бизнеса бесплатно продвигать и рекламировать свои товары и услуги в течение полугода. Grand Купон - это инновационная рекламная площадка, которая помогает бизнесу привлекать новых клиентов и укреплять позиции на рынке.\n' + 'Воспользуйтесь нашим сервисом и увеличьте поток клиентов, а следовательно и прибыль!\n' + `Хотите узнать больше о том, как сервис Grand Купон может помочь вашему бизнесу? Свяжитесь с нами прямо сейчас и получите бесплатную консультацию! Как можно потратить баллы с карты лояльности Рантье? \n\n Смотрите видеоинтрукцию`,
            contentInstr: 'Как можно потратить баллы рантье? Смотрите видеоинструцию.',
            pathInstr: 'https://rutube.ru/video/e15389258f724c1ca792426276917f24/?r=a/',
            path: 'https://grandcoupon.ru/r?rentier'

          },
          {
            id: 2,
            logoId: 1,
            title: 'Рантье-Право | Юридические услуги',
            content: 'Это партнер, оказывающий полный спектр юридических услуг для клиентов компании "Рантье". Они помогают с регистрацией бизнеса, сопровождают сделки купли-продажи недвижимости, предоставляют консультации по наследственным и семейным вопросам, а также представляют интересы клиентов в судебных разбирательствах. Команда опытных юристов гарантирует высокий уровень правовой защиты и безупречное качество обслуживания.',
            path: 'https://rentier.expert/'
          },
          {
            id: 3,
            logoId: 1,
            title: 'Рантье-ИТ',
            content: 'Это партнер, специализирующийся на предоставлении IT-услуг для клиентов компании "Рантье". Они отвечают за разработку и сопровождение программного обеспечения, создание и поддержку веб-сайтов, а также обеспечивают безопасность информационных систем. Команда квалифицированных IT-специалистов гарантирует надежную и эффективную работу всех технологических решений.',
            path: 'https://rentier.group/'
          },
          {
            id: 4,
            logoId: 1,
            title: 'Рантье-Страхование',
            content: 'Это партнер, предлагающий широкий спектр страховых услуг для клиентов компании "Рантье". Они помогают с оформлением полисов страхования жизни, имущества, ответственности, а также медицинского и пенсионного страхования. Команда опытных страховых консультантов поможет подобрать оптимальные страховые продукты, чтобы обеспечить надежную защиту ваших активов и семьи.',
            path: 'https://rentier.life/'
          },
          {
            id: 5,
            logoId: 1,
            title: 'Рантье-Дизайн',
            content: 'Это партнер, специализирующийся на предоставлении услуг в области дизайна для клиентов компании "Рантье". Они отвечают за разработку фирменного стиля, создание визуальных идентификаций, дизайн интерьеров и экстерьеров объектов недвижимости. Команда талантливых дизайнеров гарантирует креативные решения, соответствующие вашим пожеланиям и высоким стандартам качества.',
            path: 'https://rentier.space/'
          }
        ],
      };
    },
    components: {
      AlertSuccess,
      AlertError
    },
    mounted() {
      UserDataService.get()
          .then(response => {
            const user = response.data;

            this.referralCode = user.user.referralCode;
  
          })
          .catch(e => {
            console.log(e);
          });
  
      networksDataService.get()
          .then(response => {
            this.network = response.data;

          })
          .catch(e => {
            console.log(e);
          });
    },
    methods: {
      
    }
  };
  </script>
  <style scoped>
  </style>