import http from "../http-common";

export default {
    getServicesFromAdmin(pageSize, status, pageServiceIdbyAdmin) {

        const params = {
            pageSize: pageSize
        };

        if (status !== undefined) {
            params.status = status;
        }

        if (pageServiceIdbyAdmin !== undefined) {
            params.page = pageServiceIdbyAdmin - 1;
        }



        return http.get('/services/administrators', { params });
    },

    getCurrent() {
        return http.get(`/services/users`, {
            params: {
                pageSize: 15
            },
        });
    },

    getCurrentServiceFromAdmin(serviceId) {
        return http.get(`/services/${serviceId}/administrators`, {
        });
    },
    postSendService(data) {

        return http.post(`/services`, data)
    },
    getServices() {
        return http.get('/services/administrators/statuses', {
        });
    },


    putItService(id, data) {
        return http.put(`/services/${id}/administrators`, data);
    },
}

