import http from "../http-common"

export default {
    getAllChats() {
        return http.get('/chatSupport/admin/AllChats')
    },

    getOneChat(userId) {
        return http.get(`/chatSupport/admin/oneChat/${userId}`)
    }
}