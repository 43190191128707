<template>
  <div>

    <div class="container-fluid-login">
      <div class="login-main-wrapper">
        <div class="login-left">
          <h1>Добро пожаловать на платформу <span>"Рантье"</span></h1>
          <p>Мы готовы помочь вам улучшить эффективность вашей работы и повседневной жизни.</p>
          <img src="@/assets/images/login/Group64.png" alt="">
        </div>
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card d-flex flex-column">
            <div>
              <div>
                <a class="logo">
                  <!-- <img class="img-fluid for-light" src="../assets/images/logo/logo.png" alt="looginpage"/>
                  <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="looginpage"/> -->
                  <img class="img-fluid for-light" src="../assets/images/logo/logos.svg" alt="looginpage"/>
                  <img class="img-fluid for-dark" src="../assets/images/logo/logos_dark.svg" alt="looginpage"/>
                </a>
              </div>
              <div v-if="queryParams.access === 'true'" id="query" class="d-flex align-items-center justify-content-center">
                        <p class="alert alert-success" style="color:white">
                            Вы успешно зарегестрированы!
                        </p>
                      </div>
              <div class="login-main">
                <div class="theme-form">
                  <h4>Вход на платформу "Рантье"</h4>
                  <p>Просьба ввести данные для входа</p>
                  <p class="alert alert-cst alert-danger" v-if="errorValidate">{{ errorValidate }}</p>
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input class="form-control" type="email" required="" placeholder="Test@gmail.com"
                           v-model="form.email">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Пароль</label>
                    <div class="form-input position-relative">
                      <input class="form-control" :type="active?'password':'text'"
                             placeholder="*********" v-model="form.password">
                      <div class="show-hide" @click.prevent="show">
                        <img v-if="active" class="svg-icon" src="@/assets/svg/eye-close.svg"  alt="">
                        <img v-else class="svg-icon" src="@/assets/svg/eye-open.svg" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                                           <!-- <input id="checkbox1" type="checkbox">-->
                      <!--                      <label class="text-muted" for="checkbox1">Запомнить пароль</label> -->
                    </div>
                    <router-link to="/auth/resetpassword"> Забыли пароль?</router-link>
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" @click.prevent="login">
                        Войти
                      </button>
                    </div>
                  </div>
                  <p class="mt-4 mb-0 text-center">Не зарегистрированы?<a class="ms-2" href="register?start=noCode">Зарегистрироваться</a>
                  </p>
                </div>

              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserDataService from '../services/UserDataService';
import { fetchData } from '../data/comon'

export default {
  name: 'login',
  data() {
    return {
      active: true,
      pageUpdated: false,
      errorValidate: '',
      query: Boolean,
      form: {
        email: '',
        password: ''
      },
      next: ''
    };
  },
  created(){
    this.queryParams = this.$route.query;
    this.query = this.queryParams.access;
  },
  mounted() {
    this.next = this.$route.query.next ?? '/';
  },
  methods: {
    show() {
      this.active = !this.active;

    },

    async login() {
      if (!this.form.email) {
        this.errorValidate = 'Введите Email';
        return;
      }

      if (!this.form.password) {
        this.errorValidate = 'Введите пароль';
        return;
      }
      this.$router.replace({ query: {} });
      await UserDataService.login(this.form)
          .then(response => {
            localStorage.setItem('authToken', response.data.token);
            localStorage.setItem('User', JSON.stringify({ email: this.form.email, user: true }));
            fetchData()
            this.$router.push(this.next);
          })
          .catch(e => {
            this.errorValidate = e.response.data.message;
            console.log(e);
          });
    },

  },

};
</script>
<style scoped>
.alert-cst{
  color: white !important;
}
.row{
  width: 50%;
}
.svg-icon{
  width: 25px;
}
.login-main-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 2100px;
  padding-top: 117px;
  
}
.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-left img {
  width: 501px;
  height: 334px;
  margin-top: 167px;
}
.login-main-wrapper h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 28px;
}
.login-main-wrapper span {
  color: #7366FF;
}
.login-main-wrapper p {
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #898989;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(-20px);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(-20px);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
@media screen and (max-width: 2052px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1900px;
  }
}
@media screen and (max-width: 1910px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1820px;
  }
}
@media screen and (max-width: 1840px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1750px;
  }
}
@media screen and (max-width: 1756px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1680px;
  }
}
@media screen and (max-width: 1684px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1600px;
  }
}
@media screen and (max-width: 1600px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1550px;
  }
}
@media screen and (max-width: 1552px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    max-width: 1500px;
  }
}
@media screen and (max-width: 1536px) {
  .login-main-wrapper h1 {
    font-size: 30px;
  }
  .login-main-wrapper {
    max-width: 1300px;
  }
}
@media screen and (max-width: 1330px){
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-left img {
    width: 400px;
    height: 250px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-main-wrapper {
    max-width: 1200px;
  }
  .row{
    width: 40%;
  }
}
@media screen and (max-width: 1214px){
  .login-main-wrapper {
    max-width: 1080px;
  }
  .row{
    width: 50%;
  }
}
@media screen and (max-width: 1280px) {
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-left img {
    width: 400px;
    height: 250px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
}
@media screen and (min-height: 873px) and (max-width: 1386px){
  .login-left {
    display: none;
  }
  .row{
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .login-main-wrapper {
    max-width: 1080px;
  }
  .login-left {
    display: none;
  }
  .row{
    width: 100%;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin: auto;
  }
  .login-main-wrapper h1 {
    font-size: 18px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-left img {
    width: 300px;
    height: 200px;
  }
}; 
@media screen and (max-width: 768px) {
  .login-left {
    display: none;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
}
@media screen and (max-width: 640px) {
  .login-main-wrapper {
    padding-top: 50px;
  }
}
</style>
