<template>
  <Breadcrumbs title="Поддержка" />

  <div class="px-2 pb-3">
    <div
      class="btn-toolbar"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <div class="btn-group me-2" role="group" aria-label="Панель сотрудника">
        <button type="button" class="btn btn-danger" @click="switchToOffline()">
          Перейти в офлайн
        </button>
        <button type="button" class="btn btn-success" @click="switchToOnline()">
          Перейти в онлайн
        </button>
      </div>
      <div
        class="btn-group me-2"
        role="group"
        aria-label="Панель чата"
        v-if="chat.id"
      >
        <button
          type="button"
          class="btn"
          :class="chat.questionCompletionTime ? 'btn-success' : 'btn-primary'"
          @click="markChatAsCompletedModal()"
        >
          <template v-if="!chat.questionCompletionTime">
            Пометить как решенный
          </template>

          <template v-else> Помечен как решенный </template>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="onSwitchToAnotherStaff"
        >
          Переключить на другого сотрудника
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col call-chat-sidebar col-sm-12">
      <div class="card">
        <div class="card-body chat-body">
          <div class="chat-box">
            <div class="chat-left-aside">
              <div class="p-2">
                <h5>Список пользователей</h5>
              </div>

              <div class="p-2 pt-0">
                <select
                  class="form-select"
                  aria-label="Выберите фильтр"
                  v-model="filterState"
                >
                  <option value="active">Активные чаты</option>
                  <option value="inactive">Неактивные чаты</option>
                  <!-- <option>Все чаты</option> -->
                </select>
              </div>

              <div class="people-list" id="people-list">
                <ul class="list custom-scrollbar">
                  <li
                    class="clearfix"
                    v-for="(chat, index) in _.filter(chatList, (o) => {
                      switch (filterState) {
                        case 'active':
                          return !o.questionCompletionTime;

                        case 'inactive':
                          return o.questionCompletionTime;
                      }
                    })"
                    :key="index"
                    @click="loadChatWithSocket(chat.user.id)"
                  >
                    <!-- <img class="rounded-circle user-image" :src="getImageUrl(user.thumb)" alt="" /> -->
                    <div class="about">
                      <div class="name">
                        {{
                          `${chat.user.profile.firstName}
                                                ${chat.user.profile.middleName} ${chat.user.profile.lastName}`
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col call-chat-body">
      <div class="card">
        <div class="card-body p-0">
          <div class="row chat-box">
            <div class="col pe-0 chat-right-aside">
              <div class="chat">
                <div class="chat-header clearfix">
                  <!-- Здесь аватарка -->
                  <!-- <img class="rounded-circle" v-if="currentchat.thumb" :src="getImageUrl(currentchat.thumb)"
                                    alt="" /> -->
                  <div class="about">
                    <div class="name">
                      <template v-if="chat.user">
                        {{ chat.user.profile.firstName }}
                        {{ chat.user.profile.middleName }}
                        {{ chat.user.profile.lastName }}
                      </template>
                      <!-- <span class="font-primary f-12 ms-1">печатает...</span> -->
                    </div>
                    <!-- <div class="status">
                                            
                                        </div> -->
                  </div>
                  <ul
                    class="list-inline float-start float-sm-end chat-menu-icons"
                  >
                    <li
                      class="list-inline-item toogle-bar"
                      @click.prevent="chatmenutoogle = !chatmenutoogle"
                    >
                      <!-- <a href="#"><i class="icon-menu"></i></a> -->
                    </li>
                  </ul>
                </div>
                <div
                  class="chat-history chat-msg-box custom-scrollbar"
                  id="chatInput"
                  ref="chatInput"
                >
                  <div id="msg-list">
                    <div
                      v-for="(message, index) in chat.messages"
                      :key="index"
                      v-bind:class="{
                        clearfix: message.user.id != chat.user.id,
                      }"
                    >
                      <div
                        class="message"
                        v-bind:class="{
                          'other-message pull-right':
                            message.user.id != chat.user.id,
                          'my-message': message.user.id == chat.user.id,
                          'text-end': message.user.id != chat.user.id,
                        }"
                      >
                        <!-- chat.user.id -->
                        <!-- Здесь тоже аватарка -->
                        <!-- <img class="rounded-circle float-start chat-user-img img-30 text-end" alt=""
                                                v-if="currentchat.thumb && chat.user.id != 0"
                                                v-bind:src="" /> -->
                        <!-- <img class="rounded-circle float-end chat-user-img img-30" alt=""
                                                v-if="chat.user.id == 0" v-bind:src="getImageUrl('user/1.jpg')" /> -->

                        {{ message.message }}
                        <div
                          class="message-data text-start"
                          v-bind:class="{
                            'text-end': message.user.id != chat.user.id,
                          }"
                        >
                          <span class="message-data-time">
                            {{
                              moment(message.created_at).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-message clearfix">
                  <div class="row">
                    <div class="col-xl-12 d-flex">
                      <div
                        class="input-group text-box"
                        ref="abc"
                        v-if="chat.id"
                      >
                        <input
                          class="form-control input-txt-bx"
                          id="message-to-send"
                          type="text"
                          name="message-to-send"
                          placeholder="Введите текст сообщения..."
                          v-model="messageBox"
                          @keyup.enter="onSendMessage()"
                        />
                        <button
                          class="btn btn-primary input-group-text"
                          type="button"
                          @click="onSendMessage()"
                        >
                          Отправить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for switching staff -->
  <div
    class="modal fade"
    id="switchModal"
    tabindex="-1"
    aria-labelledby="switchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="switchModalLabel">
            Переключить на другого сотрудника
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Выберите сотрудника, на которого необходимо переключить пользователя:
          <div class="p-1 pt-2">
            <select
              class="form-select"
              aria-label="Выберите сотрудника"
              v-model.number="switchModalState.switchToId"
            >
              <option v-for="staff in staffList" :value="staff.id">
                {{ staff.profile.lastName }}
                {{ staff.profile.firstName }}
                {{ staff.profile.middleName }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="switchModalState.obj.hide()"
          >
            Отменить
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="switchChatToAnotherStaff(switchModalState.switchToId)"
          >
            Переключить
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for notifications -->
  <div
    class="modal fade"
    id="infoModal"
    tabindex="-1"
    aria-labelledby="infoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="infoModalLabel">
            {{ infoModalState.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ infoModalState.text }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            v-if="infoModalState.secondaryActionText"
            @click="infoModalState.secondaryActionCallback"
          >
            {{ infoModalState.secondaryActionText }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-if="infoModalState.primaryActionText"
            @click="infoModalState.primaryActionCallback"
          >
            {{ infoModalState.primaryActionText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
// Libraries
import _ from "lodash";
import moment from "moment";
import { io } from "socket.io-client";
import { register } from "register-service-worker";
import { EventSourcePlus } from "event-source-plus";

// Frameworks
import { onMounted, reactive, ref } from "vue";
import { Modal } from "bootstrap";

// API bindings
import UserAPI from "../../services/UserDataService";
import SupportAPI from "../../services/StaffSupportDataService";
//#endregion

//#region Client
// Modal window state
const infoModalState = reactive({
  obj: null,
  title: "",
  text: "",
  primaryActionText: "",
  primaryActionCallback: () => {},
  secondaryActionText: "",
  secondaryActionCallback: () => {},
});

const switchModalState = reactive({
  obj: null,
  switchToId: 0,
});

const filterState = ref("active");
// Message box state
const messageBox = ref("");

// Trigger modal with certain content and action callbacks
function triggerModal(
  title,
  text,
  primaryActionText,
  primaryActionCallback,
  secondaryActionText,
  secondaryActionCallback
) {
  infoModalState.title = title;
  infoModalState.text = text;
  infoModalState.primaryActionText = primaryActionText;
  infoModalState.primaryActionCallback = primaryActionCallback;
  infoModalState.secondaryActionText = secondaryActionText;
  infoModalState.secondaryActionCallback = secondaryActionCallback;
  infoModalState.obj.show();
}

// Scroll message list to bottom
function scrollToBottom() {
  const msgListEl = document.getElementById("chatInput");
  msgListEl.scrollTop = msgListEl.scrollHeight;
}

// Handler of message sending event
async function onSendMessage() {
  await SupportAPI.sendMessage(chat.value.user.id, messageBox.value);
  socket.emit("message", {
    userId: profile.value.user.id,
    chatId: chat.value.id,
    message: messageBox.value,
  });
  await loadChatWithoutSocket(chat.value.user.id);
  await loadChatList();
  messageBox.value = "";
}
//#endregion

//#region Network
const staffList = ref([]);
const profile = ref({});
const chatList = ref([]);
const chat = ref({});
const socket = io(process.env.VUE_APP_BACKEND_API_BASE);

async function loadChatList() {
  const res = await SupportAPI.getChatList();
  chatList.value = res.data;
}

async function loadChatWithSocket(id) {
  // Load support chat info
  const res = await SupportAPI.getChat(id);
  chat.value = res.data;

  socket.emit("join_room", {
    userId: profile.value.user.id,
    chatId: chat.value.id,
  });

  socket.on("message", async () => {
    console.log("RELOAD TRIGGERED");
    await loadChatWithoutSocket(chat.value.user.id);
  });

  setTimeout(() => {
    scrollToBottom();
  }, 50);
}

async function loadChatWithoutSocket(id) {
  // Load support chat info
  const res = await SupportAPI.getChat(id);
  chat.value = res.data;

  setTimeout(() => {
    scrollToBottom();
  }, 50);
}

function markChatAsCompletedModal() {
  if (!chat.value.questionCompletionTime) {
    triggerModal(
      "Пометить чат как завершенный",
      `Вы действительно хотите пометить чат как завершенный?`,
      "Да",
      async () => {
        await markChatAsCompleted();
        infoModalState.obj.hide();
      },
      "Нет",
      () => {
        infoModalState.obj.hide();
      }
    );
  } else {
    triggerModal(
      "Чат уже помечен как завершенный",
      `Если желаете снять пометку, отправьте в чат сообщение`,
      "Закрыть окно",
      () => {
        infoModalState.obj.hide();
      },
      null,
      () => {}
    );
  }
}

async function markChatAsCompleted() {
  await SupportAPI.markAsCompleted(chat.value.user.id);
  await loadChatList();
  await loadChatWithoutSocket(chat.value.user.id);
}

async function onSwitchToAnotherStaff() {
  staffList.value = (await SupportAPI.getSupportStaff()).data;
  staffList.value = _.filter(staffList.value, (o) => {
    return o.id != profile.value.user.id;
  });
  if (staffList.value[0]) switchModalState.switchToId = staffList.value[0].id;

  if (staffList.value.length != 0) {
    switchModalState.obj.show();
  } else {
    triggerModal(
      "Нет сотрудников онлайн",
      `Подождите, пока в сети появятся другие сотрудники`,
      "Закрыть окно",
      async () => {
        infoModalState.obj.hide();
      },
      null,
      () => {}
    );
  }
}

async function switchChatToAnotherStaff(staffId) {
  await SupportAPI.changeSupportStaffForChat(chat.value.user.id, staffId);
  chat.value = {};
  await loadChatList();
  switchModalState.obj.hide();
  triggerModal(
    "Чат переключен на другого сотрудника",
    `Запрос успешно выполнен`,
    "Закрыть окно",
    async () => {
      infoModalState.obj.hide();
    },
    null,
    () => {}
  );
}

async function switchToOffline() {
  try {
    await SupportAPI.switchToOffline();
    triggerModal(
      "Вы оффлайн",
      `Ваша учётная запись переведена в режим "не в сети"`,
      "Закрыть окно",
      () => {
        infoModalState.obj.hide();
      },
      null,
      () => {}
    );
  } catch (e) {
    console.error(e);
    switch (e.response.status) {
      case 409:
        triggerModal(
          "Вы уже оффлайн!",
          `Ваша учётная запись уже не в сети`,
          "Закрыть окно",
          () => {
            infoModalState.obj.hide();
          },
          null,
          () => {}
        );
        break;

      default:
        triggerModal(
          "Ошибка при запросе к серверу",
          `Возникла ошибка при запросе к серверу. Попробуйте снова или обратитесь к технической поддержке.`,
          "Попробовать снова",
          () => {
            infoModalState.obj.hide();
            setTimeout(async () => {
              location.reload();
            }, 500);
          },
          "Вернуться назад",
          () => {
            history.back();
          }
        );
        break;
    }
  }
}

async function switchToOnline() {
  try {
    await SupportAPI.switchToOnline();
    triggerModal(
      "Вы онлайн",
      `Ваша учётная запись переведена в режим "в сети"`,
      "Закрыть окно",
      () => {
        infoModalState.obj.hide();
      },
      null,
      () => {}
    );
  } catch (e) {
    console.error(e);
    switch (e.response.status) {
      case 409:
        triggerModal(
          "Вы уже онлайн!",
          `Ваша учётная запись уже в сети`,
          "Закрыть окно",
          () => {
            infoModalState.obj.hide();
          },
          null,
          () => {}
        );
        break;

      default:
        triggerModal(
          "Ошибка при запросе к серверу",
          `Возникла ошибка при запросе к серверу. Попробуйте снова или обратитесь к технической поддержке.`,
          "Попробовать снова",
          () => {
            infoModalState.obj.hide();
            setTimeout(async () => {
              location.reload();
            }, 500);
          },
          "Вернуться назад",
          () => {
            history.back();
          }
        );
        break;
    }
  }
}
//#endregion

onMounted(async () => {
  // Init info modal
  infoModalState.obj = new Modal(document.getElementById("infoModal"), {
    backdrop: true,
    focus: true,
    keyboard: true,
  });

  // Init switch modal
  switchModalState.obj = new Modal(document.getElementById("switchModal"), {
    backdrop: true,
    focus: true,
    keyboard: true,
  });

  // Load user info
  profile.value = (await UserAPI.get()).data;

  staffList.value = (await SupportAPI.getSupportStaff()).data;
  staffList.value = _.filter(staffList.value, (o) => {
    return o.id != profile.value.user.id;
  });
  if (staffList.value[0]) switchModalState.switchToId = staffList.value[0].id;

  await loadChatList();
});

// Service worker for push
onMounted(() => {
  const messageEventSource = new EventSourcePlus(
    `${process.env.VUE_APP_BACKEND_API_BASE}/chatSupport/sse/registerOnMessages`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
  const chatsEventSource = new EventSourcePlus(
    `${process.env.VUE_APP_BACKEND_API_BASE}/chatSupport/sse/registerOnChatMoves`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
  register("/my/service-worker.js", {
    registered: async (registration) => {
      const grant = await Notification.requestPermission();
      if (grant === "granted") {
        registration.showNotification("Уведомления включены!", {
          body: "Уведомления будут приходить при появлении новых сообщений и чатов",
        });
        messageEventSource.listen({
          onMessage: (message) => {
            const parsed = JSON.parse(message.data);
            console.log(parsed);
            registration.showNotification(
              `Сообщение от ${parsed.from.profile.firstName} ${parsed.from.profile.middleName} ${parsed.from.profile.lastName}`,
              {
                body: parsed.message,
              }
            );
          },
        });
        chatsEventSource.listen({
          onMessage: (message) => {
            const parsed = JSON.parse(message.data);
            console.log(parsed);
            registration.showNotification(parsed.message);
          },
        });
      }
    },
  });
});
</script>
