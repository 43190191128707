<template>
  <Breadcrumbs title="Заявление о зачёте"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 m-auto">
        <div class="card">
          <alert-error v-if="errorMessage" :text="errorMessage"/>
          <alert-success v-if="successMessage" :text="successMessage"/>

          <div class="card-body">
            <p class="text-end">Генеральному директору <br/>
              Общества с ограниченной ответственности <br/>
              «Рантье Финанс Групп»
            </p>
            <h3 class="text-center">Заявление о зачёте</h3>
            <p class="text-end">{{ date }}</p>
            <p>Я, гражданин(ка) РФ
              <span class="fw-bold">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</span> , действуя
              осознанно и по собственной воле, прошу произвести зачёт обязательств Компании передо мной в эквиваленте
              {{ product.amount  }} рублей в счет погашения моих обязательств перед Компанией по оплате 100%
              стоимости приобретаемых мной услуг/товаров:</p>
            <ul style="list-style: square">
              <li style="margin-left:50px ">{{ product.name }} - стоимость {{ product.amount }} рублей.</li>
            </ul>
            <hr/>
            <p class="mt-3">Гражданин(ка) РФ</p>
            <p>
              <span class="fw-bold">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</span> <br/>
              Эл. почта:{{ user.user?.email }} <br/>
              Телефон: {{ user.phone }} <br><br/>
              <strong>Паспорт РФ</strong> <br/>
              <span v-if="user.passportNumber">
                Серия и номер: {{ user.passportNumber }} <br/>
                  выдан {{ new Date(user.passportDate).toLocaleDateString('ru-Ru') }} <br/>
             </span>
              <span v-if="user.passportIssued">{{ user.passportIssued }} <br/></span>
              <span v-if="user.passportCode">Код подразделения: {{ user.passportCode }}<br/></span>
              <span v-if="user.passportAddress">Адрес регистрации: {{ user.passportAddress }}<br/></span>
              <span v-if="user.inn">ИНН: {{ user.inn }}<br/></span>
              <span v-if="user.snils">СНИЛС: {{ user.snils }}<br/></span>
            </p>
            <p class="fw-bold">Подписано путём ввода одноразового пароля</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";

export default {
  data() {
    return {
      date: new Date().toLocaleDateString('ru-Ru'),
      errorMessage: '',
      successMessage: '',
      user: '',
      amount: 0,
      product: {},
    };
  },
  components: {
    AlertSuccess,
    AlertError
  },
  mounted() {

    this.product = JSON.parse(localStorage.getItem('product'));
    console.log(this.product)
    UserDataService.get()
        .then(response => {
          this.user = response.data;
        })
        .catch(e => {
          console.log(e);
        });
  },
};
</script>