<template>
  <Breadcrumbs title="НОВОСТИ" />

  <template v-for="item of newsList">
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-sm-12">
          <div class="card mb-3">
            <div class="row g-0">
              <div class="col-md-auto">
                <router-link
              :to="`/news/${item.id}`"
            >
            <img class="img-fluid p-4"
            :src="`${backendURL}/${item.preview}`"
                 alt="..." style="height: 300px; width: 300px; object-fit: cover;">
            </router-link>
              </div>
                

              

              <div class="col-md">
                <div class="card-body">
                  <h5 class="card-title" v-text="item.title"></h5>
                  <p class="card-text">
                    <small class="text-body-secondary">
                      {{ moment(item.created_at).format("HH:mm DD.MM.YYYY") }}
                    </small>
                  </p>
                  <hr />
                  <p class="card-text" v-text="item.description"></p>
                  <router-link :to="`/news/${item.id}`">
                    <button class="btn btn-outline-primary">
                      Перейти к записи
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
//#region Imports
import _ from 'lodash';
import moment from 'moment';
import { onMounted, ref } from 'vue';

import NewsAPI from "@/services/NewsDataService";
import { computed } from 'vue';
//#endregion

//#region Client
/**
 * Post list
 * @description Contains post list
 * @type {import('vue').Ref<import('@/services/NewsDataService').Post[]>}
 */
const newsList = ref([]);
const backendURL = computed(() => process.env.VUE_APP_BACKEND_API_BASE)
//#endregion

//#region Hooks
onMounted(() => {
  // Load list of news
  NewsAPI.get().then(({ data }) => {
    const list = data;
    // list.forEach((value, index, array) => {
    //   value.preview = value.preview.replace('public/', '')
    // })
    newsList.value = list;
  })
})
//#endregion
</script>
<style scoped>
.btn.btn-outline-primary:hover{
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
</style>