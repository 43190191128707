<template>
  <div>
    <footer class="footer" :class="this.footer == 'footer-dark' ? 'footer-dark' : ''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">Проект запущен: 2023 © Рантье </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footerpage',
  data() {
    return {
      customizer: false,

    };
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>
