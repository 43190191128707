<template>
    <div>
        <Breadcrumbs title="ТЕСТ" />
        <div class="col-md-12 project-list">
            <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-6 d-flex">
                        <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
                            <li class="nav-item">
                                <h5>{{ resp.name }}</h5>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-0 me-0"></div>
                        <router-link class="btn btn-success" :to='"/tests/user/test/" + this.$route.params.id'>
                            
                            Начать
                        </router-link>
                    </div>

                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
                            <li class="nav-item">
                                Этот тест представляет собой комплексный и систематический инструмент оценки и измерения навыков, знаний и способностей в определенной области. В ходе теста респондентам предлагается ряд заданий, цель которых - получить информацию о их профессиональных навыках, когнитивных способностях, личностных чертах или других параметрах, в зависимости от конкретной цели тестирования.
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-12 d-flex question-wrapper">
                       
                     <div class="question d-flex justify-content-between align-items-center"><vue-feather type="help-circle"></vue-feather>Вопросов: {{ resp.numberOfCorrectAnswers }}</div>
                     <div class="QuestionTrue d-flex justify-content-between align-items-center"><vue-feather type="check-circle"></vue-feather> <p class="ms-2"> Правильных ответов для прохождения: {{ resp.numberOfQuestions }} </p></div>
                    </div>

                </div>
            </div>
        </div>
        </div>
    </div>

</template>

<script scoped>
import TestingDataService from '@/services/TestingDataService';

export default {
    data() {
        return {
            resp: '',
        };
    },
    methods: {
        getShortDescrioptionCurrentTest(id){
            TestingDataService.getShortDescrioptionCurrentTest(id)
            .then(( response ) => {

                this.resp = response.data
                console.log(this.resp);
            }
            ) .catch ((error) => {
                console.log(error);
            })
        }
    },
    mounted() {
        this.getShortDescrioptionCurrentTest(this.$route.params.id)
    }
}
</script>

<style scoped>
.question{
    gap: 10px;
    display: flex;
    align-items: center;
}
.question-wrapper{
    margin-top:50px;
    gap: 20px;
}
</style>