<template>
  <div class="wrapper-carousel d-flex">
    <CarouselItem
        class="w-100"
        v-for="item in carouselArray"
        :key="item.id"
        :item_data="item"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        :style="{ transform: 'translateX(' + (-100 * currentSlideIndex) + '%)' }"
    />
  </div>
</template>
<script>
import CarouselItem from "@/components/carousel/carousel-item.vue";

export default {
  name: "carousel",
  components: {
    CarouselItem
  },
  props: {
    carouselArray: {
      type: Array,
      default: () => {}
    },
    interval: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentSlideIndex: 0,
      startX: 0,
      endX: 0
    }
  },
  mounted() {
    if (this.interval > 0) {
      let vm = this
      setInterval( () => {
        this.nextSlide()
      }, vm.interval)
    }
  },
  methods: {
    prevSlide() {
      if (this.currentSlideIndex > 0) {
        this.currentSlideIndex--
      }
    },
    nextSlide() {
      if (this.currentSlideIndex >= this.carouselArray.length - 1) {
        this.currentSlideIndex = 0
      } else {

        this.currentSlideIndex++
      }
    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      this.endX = event.touches[0].clientX;
    },
    onTouchEnd() {
      const distance = this.startX - this.endX;
      if (distance > 50) {
        this.nextSlide();
      } else if (distance < -50) {
        this.prevSlide();
      }
    }
  }
}

</script>