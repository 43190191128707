<template>
<!--  <div class="wrapper-abs">-->
<!--    <a class="floating-action-button" href="/my/support">-->
<!--      <svg-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--        width="32"-->
<!--        height="32"-->
<!--        fill="currentColor"-->
<!--        class="bi bi-headset"-->
<!--        viewBox="0 0 16 16"-->
<!--      >-->
<!--        <path-->
<!--          d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"-->
<!--        />-->
<!--      </svg>-->
<!--    </a>-->
<!--    <div class="floating-action-button-bg"></div>-->
<!--    <vue-feather-->
<!--      @mouseover="showPopover1 = true"-->
<!--      @mouseleave="showPopover1 = false"-->
<!--      class="status_toggle middle sidebar-toggle sign-question"-->
<!--      type="help-circle"-->
<!--    ></vue-feather>-->
<!--    <div v-show="showPopover1" class="popover">-->
<!--        <b> На данный момент мы внедряем новую тех поддержку "Рантье+" </b>-->
<!--        <hr>-->
<!--        Новая техподдержка работает в демо-режиме, в следующем месяце станет основной основным модулем.-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
export default {
  data() {
    return {
      active1: true,

      showPopover1: false,
    };
  },
};
</script>
<style scoped lang="scss">
@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.wrapper-abs{
    position: relative;
}
.sign-question {
  z-index: 1;
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  /* Цвет кнопки можно изменить */
  color: rgb(65, 65, 65);
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.popover {
  position: absolute;
  top: -300px;
  right: 100px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  opacity: 1;
  transition: all 0.5s;
}

.floating-action-button {
  z-index: 1;
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  /* Цвет кнопки можно изменить */
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
    /* Изменение цвета при наведении */
  }

  &:focus {
    outline: none;
    /* Убираем рамку при фокусе */
  }
}

.floating-action-button-bg {
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  /* Цвет кнопки можно изменить */
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  animation: wave 5s ease-in-out infinite;

  &:hover {
    background-color: #0056b3;
    /* Изменение цвета при наведении */
  }

  &:focus {
    outline: none;
    /* Убираем рамку при фокусе */
  }
}
@media (max-width: 800px) {
  .popover {
    position: absolute;
    top: -350px;
    right: 195px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    opacity: 1;
    transition: all 0.5s;
    }
}
</style>
