<template>
<div class="col-xl-12 h-100">
    <div class="market-card h-100">
      <div class="m-0 overall-card h-100 d-flex flex-column justify-content-center align-items-center">
        <img class="mb-4" src="@/assets/images/profile-my-active/tools.svg">
        <span>
          Модуль в разработке...
        </span>
<!--        <div class="market-chart-container">-->
<!--          <apexchart height="315" type="line" :options="cryptoDashboard?.options6" :series="cryptoDashboard?.series6"></apexchart>-->
<!--        </div>-->
      </div>
    </div>
</div>
</template>

<script>
import {
    cryptoDashboard
} from "@/data/comon";

export default {
    data() {
        return {
            cryptoDashboard: cryptoDashboard,
        }
    },
}
</script>
