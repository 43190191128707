<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="tab-content" id="top-tabContent">
          <all-page/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import allPage from '../projectlist/allPage.vue'

export default {
  name: 'topMenu',
  components: {
    allPage
  },
  data() {
    return {}
  },
  methods:
      {}
}

</script>