import http from "../http-common";

export default {
    get(body) {
        return http.post('/operation', body);
    },

    codeValidate(body) {
        return http.post('/operation/validate', body);
    },

    sendTokenTelegram() {
        return http.get('/telegram-bot-api/telegram-verify-token');
    },

    getCheckedToken(token) {
        return http.get('/telegram-bot-api/telegram-verify', {
            params: {
                token: token
            }
        })
    },

    postPaySubscribe() {
        return http.get('/telegram-bot-api/pay-subscribe');
    },

    getPayRank() {
        return http.get('/telegram-bot-api/buy-rank');
    }
}

