<template>
  <table class="table table-bordered table-striped">
    <tbody>
    <tr>
      <td>
        <label>Начало использования:</label>

      </td>
      <td>
        <p class="itemtext digits">{{new Date(startDate).toLocaleDateString('ru-Ru')}}</p>
      </td>
    </tr>
    <tr>
      <td>
        <label>Подписка действует до:</label>

      </td>
      <td>
        <p class="itemtext digits">{{new Date(endDate).toLocaleDateString('ru-Ru')}}</p>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
}
</script>
