<template>
  <Breadcrumbs title="Создание мероприятия"/>
  <div class="row">
    <div class="col-sm-12">
      <div class="card event">

        <div class="card-body">
          <form class="row">

            <div class="col-sm-12 col-md-12">
              <label class="form-label">Тема</label>
              <input
                  v-model="formData.topic"
                  class="form-control"
                  type="text"
                  required
              />
            </div>
            <div class="col-sm-12 col-md-12 mt-3">
              <label class="form-label">Организатор</label>
              <input
                  v-model="formData.fromWhom"
                  class="form-control"
                  type="text"
                  required
              />
            </div>
            <div class="col-sm-12 col-md-12 mt-3">
              <label class="form-label">Анонс (отображается в списке событий)</label>
              <textarea

                  rows="5"
                  v-model="formData.shortDescription"
                  class="form-textarea"
                  required
              ></textarea>
            </div>
            <div class="col-sm-12 col-md-12 mt-3 h-100">
              <label class="form-label">Полное описание (отображается в карточке)</label>
              <quill-editor v-model:value="stateDescription.content"
                            :options="stateDescription.editorOption"
                            @change="onEditorChange($event)"/>
            </div>
            <div class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Ведущий</label>
              <input
                  v-model="formData.speaker"
                  class="form-control"
                  type="text"
                  required
              />
            </div>
            <div class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Онлайн</label>
              <select
                  v-model="formData.isOnline"
                  class="form-control"
                  required
              >
                <option :value="true">Да</option>
                <option :value="false">Нет</option>
              </select>
            </div>
            <div class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Дата начала</label>
              <input
                  v-model="formData.meetingDateStart"
                  class="form-control"
                  type="datetime-local"
                  required
              />
            </div>
            <div class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Дата конца</label>
              <input
                  v-model="formData.meetingDateEnd"
                  class="form-control"
                  type="datetime-local"
              />
            </div>
            <div class="col-sm-6 col-md-6 mt-3" v-if="formData.isOnline">
              <label class="form-label">Ссылка встречи</label>
              <input
                  v-model="formData.meetingPlace"
                  class="form-control"
                  type="text"
              />
            </div>
            <div v-else class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Место проведения</label>
              <input
                  v-model="formData.meetingPlace"
                  class="form-control"
                  type="text"
                  required
              />
            </div>

            <div class="col-sm-6 col-md-6 mt-3">
              <label class="form-label">Цена</label>
              <input
                  v-model="formData.price"
                  class="form-control"
                  type="number"
              />
            </div>
            <div class="col-sm-12 col-md-12 mt-3">
              <label class="form-label">Ссылка на запись</label>
              <input
                  v-model="formData.meetingRecordUrl"
                  class="form-control"
                  type="text"
              />
            </div>
            <div class="col-sm-12 col-md-12 mt-3">
              <div class="mb-3 row">
                <label class="col-sm-3 col-form-label">Изображение анонс</label>
                <div class="col-sm-9">
                  <input class="form-control"
                         ref="fileInput"
                         type="file"
                         @change="handleFileUpload($event, true)"
                         accept="image/*"
                         required
                  ></div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 mt-3">
              <div class="mb-3 row">
                <label class="col-sm-3 col-form-label">Изображение детальное (горизонтальное)</label>
                <div class="col-sm-9">
                  <input class="form-control"
                         ref="fileInput"
                         type="file"
                         @change="handleFileUpload($event, false)"
                         accept="image/*"
                         required
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <figure class="col-xl-3 col-sm-6">
                <img :src="this.FRONT_URL+formData.previewImagePath" alt="" class="img-thumbnail"/>
                <div class="caption text-center"><h6>Изображение анонса</h6>
                </div>
              </figure>

              <figure class="col-xl-3 col-sm-6">
                <img :src="this.FRONT_URL+formData.titleImagePath" alt="" class="img-thumbnail"/>
                <div class="caption text-center"><h6>Изображение детальное</h6>
                </div>
              </figure>
            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <button @click="uploadFile" class="btn btn-outline-primary btn-event">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import MeetingDataService from "@/services/MeetingDataService";
import {reactive} from 'vue'

export default {
  setup() {
    const stateDescription = reactive({
      content: '',
      _content: '',
      editorOption: {
        placeholder: 'core',
      },
      disabled: false
    })
    const onEditorChange = ({quill, html, text}) => {
      stateDescription._content = html;
    }
    return {stateDescription, onEditorChange}
  },
  data() {

    return {
      imageEditing: require('@/assets/images/image_editing.png'),
      fileAnons: null,
      fileDetail: null,
      imageUrl: null,
      formData: {
        topic: "",
        fromWhom: "Рантье+",
        shortDescription: "",
        previewImagePath: "",
        titleImagePath: "",
        text: "",
        speaker: "",
        meetingDateStart: null,
        meetingDateEnd: null,
        isOnline: true,
        meetingPlace: "",
        price: 0,
        meetingRecordUrl: "",
      },
    };
  },
  mounted() {
    this.getMeeting();
  },
  methods: {
    getMeeting() {
      let id = this.$route.params.id;

      MeetingDataService.getById(id)
          .then((response) => {
            this.formData = response.data;
            this.stateDescription.content = this.formData.text;

            this.formData.meetingDateStart = this.convertDate(this.formData.meetingDateStart);

            if (this.formData.meetingDateEnd)
              this.formData.meetingDateEnd = this.convertDate(this.formData.meetingDateEnd);
          })
    },
    handleFileUpload(event, isAnons) {
      if (isAnons)
        this.fileAnons = event.target.files[0];
      else
        this.fileDetail = event.target.files[0];
    },
    async uploadFile() {
      this.formData.text = this.stateDescription.content;

      if (this.fileAnons) {
        let formDataAnons = new FormData();
        formDataAnons.append("file", this.fileAnons);
        this.formData.previewImagePath = await MeetingDataService.fileUploadsMeetingTitle(formDataAnons)
            .then(({data}) => {
              return data.filepath;
            })
            .catch((error) => {
              console.log(error);
            });
      }

      if (this.fileDetail) {
        let formDataDetail = new FormData();
        formDataDetail.append("file", this.fileDetail);

        this.formData.titleImagePath = await MeetingDataService.fileUploadsMeetingPreview(formDataDetail)
            .then(({data}) => {
              return data.filepath;
            })
            .catch((error) => {
              console.log(error);
            });
      }

      this.putMeeting();
    },
    putMeeting() {
      MeetingDataService.putMeeting(this.formData)
          .then(() => {
            this.$frouter.push("/events/list");
          })
          .catch((error) => {
            console.log(error);
          });
    },
    convertDate(dateServer) {
      let serverDateTime = new Date(dateServer);
      serverDateTime.setHours(serverDateTime.getHours() + 3); // UTC+3 для Москвы
      return serverDateTime.toISOString().slice(0, -8); // Удаляем 'Z'

    }
  },
};
</script>

<style lang="scss" scoped>

.no-image {
  background-size: 50px 50px; /* Размер иконки */
  background-repeat: no-repeat;
  background-position: center center; /* Выравнивание иконки по верхнему краю */
}
.btn.btn-outline-primary:hover{
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
</style>