<script>
export default {
  name: "closeChat"
}
</script>

<template>
<div class="wrapper-close-chat d-flex ">
  <div class="wrapper-close-chat-img">
    <img src="../../../assets/images/chats/Circle_Warning_Orange.svg" alt="">
  </div>
  <div class="wrapper-close-chat-text">
    Чат был закрыт, и у вас больше нет возможности продолжать переписку.
  </div>
</div>
</template>

<style scoped>
.wrapper-close-chat-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(82, 82, 108, 0.75);
}
.wrapper-close-chat-text{
  margin-left: 10px;
  display: flex;
  text-align: center;
  align-items: center;
}
</style>