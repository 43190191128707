<template>
  <div>
    <div class="container-fluid-login">
      <div class="login-main-wrapper">
        <div class="login-left">
          <h1>Добро Пожаловать На <span>Рантье</span></h1>
          <p>Мы готовы помочь вам улучшить эффективность вашей работы и повседневной жизни.</p>
          <img src="@/assets/images/login/Group64.png" alt="">
        </div>
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../../assets/images/logo/logos.svg" alt="looginpage"/>
                  <img class="img-fluid for-dark" src="../../assets/images/logo/logos_dark.svg" alt="looginpage"/>
                </a>
              </div>
              <alert-error v-if="errorMessage" :text="errorMessage"/>
              <alert-success v-if="successMessage" :text="successMessage"/>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4>Восстановление пароля</h4>
                  <p>Просьба ввести данные для входа</p>
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input class="form-control" type="email" required="" placeholder="Test@gmail.com"
                           v-model="form.email">
                  </div>

                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                    </div>
                    <router-link to="/auth/login">Перейти на страницу входа</router-link>
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit">
                        Восстановить
                      </button>

                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserDataService from '../../services/UserDataService';
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";

export default {
  name: 'login',
  components: {alertError, AlertSuccess},
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      form: {
        email: '',
      }
    };
  },
  methods: {
    async login() {
      if (!this.form.email) {
        this.errorMessage = 'Введите Email';
        return;
      }

      await UserDataService.resetPassword(this.form)
          .then(response => {
            console.log(response);
            this.successMessage = 'Новый пароль выслан на почту';
          })
          .catch(e => {
            if (e.response?.data) this.errorMessage = e.response.data.message;
            console.log(e);
          });

          if (this.successMessage === 'Новый пароль выслан на почту') {
            this.errorMessage = '';
          } 
    },

  },
};
</script>
<style scoped>
.login-main-wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-right: 300px;
  margin-left: 101px;
  padding-top: 117px;
  
}
.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-left img {
  width: 501px;
  height: 334px;
  margin-top: 167px;
}
.login-main-wrapper h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 28px;
}
.login-main-wrapper span {
  color: #7366FF;
}
.login-main-wrapper p {
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #898989;
}

@media screen and (max-width: 1536px) {
  .login-main-wrapper h1 {
    font-size: 36px;
  }
  .login-main-wrapper {
    margin-right: 250px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 1280px) {
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-main-wrapper {
    margin: auto;
    margin-right: 150px;
    margin-left: 50px;
  }
  .login-left img {
    width: 400px;
    height: 250px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  .login-left {
    display: none;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
  .login-main-wrapper h1 {
    font-size: 18px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
  .login-left img {
    width: 300px;
    height: 200px;
  }
}; 
@media screen and (max-width: 768px) {
  .login-left {
    display: none;
  }
  .login-main-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
  .login-main-wrapper h1 {
    font-size: 24px;
  }
  .login-main-wrapper p {
    font-size: 14px;
    max-width: 400px;
  }
}
@media screen and (max-width: 640px) {
  .login-main-wrapper {
    padding-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin: auto;
  }
}
</style>
