<template>
  <div class="h-100">
    <div class="card h-100">
      <div class="card-header card-no-border text-center">
        <h5>КАК МЕНЯЛАСЬ СТОИМОСТЬ СЕРТИФИКАТА ПРОЕКТА РАНТЬЕ</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row m-0 overall-card overview-card">
          <div>
            <div class="chart-right">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card-body p-0">
                    <div class="current-sale-container order-container">
                      <div class="overview-wrapper">
                        <apexchart
                          height="265"
                          type="line"
                          v-if="showChart"
                          :options="options2"
                          :series="series2"
                        ></apexchart>
                      </div>
                      <div class="back-bar-container">
                        <apexchart
                          height="195"
                          type="bar"
                          :options="options3"
                          :series="series3"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown1 from "./DropDown1.vue";
import { ecomDashboard } from "@/data/comon";
import MoneyDataService from "@/services/MoneyDataService";
export default {
  components: {
    DropDown1,
  },
  data() {
    return {
      ecomDashboard: ecomDashboard,
      options2: {

        chart: {
          height: 300,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            color: '#000',
            opacity: 0.08
          }
        },
        stroke: {
          width: [2, 2, 2],
          curve: 'smooth'
        },
        grid: {
          show: true,
          borderColor: 'var(--chart-border)',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: true
            }
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        colors: ["#7064F5", "#54BA4A", "#FF3364"],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 100]
          }
        },
        labels: [],
        markers: {
          discrete: [{
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: '#7064F5',
            strokeColor: 'var(--white)',
            size: 5,
            sizeOffset: 3
          }, {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: '#54BA4A',
            strokeColor: 'var(--white)',
            size: 5,
          },
            {
              seriesIndex: 2,
              dataPointIndex: 2,
              fillColor: '#FF3364',
              strokeColor: 'var(--white)',
              size: 5,
            },
            {
              seriesIndex: 0,
              dataPointIndex: 5,
              fillColor: '#7064F5',
              strokeColor: 'var(--white)',
              size: 5,
              sizeOffset: 3
            }, {
              seriesIndex: 1,
              dataPointIndex: 5,
              fillColor: '#54BA4A',
              strokeColor: 'var(--white)',
              size: 5,
            },
            {
              seriesIndex: 2,
              dataPointIndex: 5,
              fillColor: '#FF3364',
              strokeColor: 'var(--white)',
              size: 5,
            },
            {
              seriesIndex: 0,
              dataPointIndex: 9,
              fillColor: '#7064F5',
              strokeColor: 'var(--white)',
              size: 5,
              sizeOffset: 3
            }, {
              seriesIndex: 1,
              dataPointIndex: 9,
              fillColor: '#54BA4A',
              strokeColor: 'var(--white)',
              size: 5,
            },
            {
              seriesIndex: 2,
              dataPointIndex: 9,
              fillColor: '#FF3364',
              strokeColor: 'var(--white)',
              size: 5,
            },
          ],
          hover: {
            size: 5,
            sizeOffset: 0
          }
        },
        xaxis: {
          type: 'category',
          tickAmount: 4,
          tickPlacement: 'between',
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            color: 'var(--chart-border)',
          },
          axisTicks: {
            show: false
          }
        },
        legend: {
          show: false,
        },
        yaxis: {
          min: 0,
          tickAmount: 6,
          tickPlacement: 'between',
        },
        tooltip: {
          shared: false,
          intersect: false,
        },
        responsive: [{
          breakpoint: 1200,
          options: {
            chart: {
              height: 250,
            }
          },
        }]

      },
      series2: [
        {
          name: '',
          type: 'area',
          data: [25, 20, 15, 25, 32, 20, 30, 35, 23, 30, 20]
        },
      ],
      options3: {

        chart: {
          type: 'bar',
          height: 180,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        colors: ["var(--light-bg)"],
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.7
        },
        tooltip: {
          enabled: false
        },
        states: {
          normal: {
            filter: {
              type: 'none',
            }
          },
          hover: {
            filter: {
              type: 'none',
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
            }
          },
        },
        responsive: [{
          breakpoint: 405,
          options: {
            chart: {
              height: 150,
            }
          },
        }]
      },
      series3: [{
        name: 'Revenue',
        data: [30, 40, 18, 25, 18, 10, 20, 35, 22, 40, 30, 38, 20, 35, 11, 28, 40, 11, 28, 40, 11, 28, 40, 11, 28, 40, 11]
      }],
      showChart: false
    };
  },
  mounted() {
    this.getPriceHistoryOfCertificate()
  },
  methods: {
    getPriceHistoryOfCertificate() {
      MoneyDataService.getPriceHistoryOfCertificate()
          .then(response => {
            const history = response.data;

            this.options2.labels = history.map(item => {
              const date = new Date(item.created_at);

              const dateOptions = { day: 'numeric', month: 'long' };
              const timeOptions = { hour: '2-digit', minute: '2-digit' };

              const formattedDate = date.toLocaleDateString('ru-RU', dateOptions);
              const formattedTime = date.toLocaleTimeString('ru-RU', timeOptions);

              return `${formattedDate} ${formattedTime}`;
            })

            this.series2[0].data = history.map(item => {
              return item.price
            })

            this.showChart = true;
          })
          .catch(error => {
            console.error('Ошибка:', error);
          });
    }
  }
};
</script>
