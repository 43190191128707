import http from "../http-common";

/**
 * @module NewsDataService
 */

/**
 * @typedef {Object} Post
 * @property {number} id - Post id
 * @property {object} user - User that created the post
 * @property {string} title - Post title
 * @property {string} description - Post description
 * @property {string} text - Post text
 * @property {string} image - image url
 * @property {string} preview - preview image url
 * @property {string} created_at - Date string in ISO format
 * @property {string} deleted_at - Date string in ISO format
 * @property {string} updated_at - Date string in ISO format
 */

/**
 * @typedef {Object} FileData
 * @property {object} data - File data
 * @property {string} data.preview - preview image url
 * @property {string} data.image - image url
 */

export default {
    /**
     * Get all news
     * @returns {Promise<import("axios").AxiosResponse<Post[]>>}
     */
    get() {
        return http.get("/news");
    },

    /**
     * Get news by id
     * @param {number} id
     * @returns {Promise<import("axios").AxiosResponse<Post>>}
     */
    getById(id) {
        return http.get(`/news/${id}`);
    },

    /**
     * Create new post
     * @param {string} title - Post title
     * @param {string} description - Post description
     * @param {string} image - Post image URL
     * @param {string} preview - Post preview image URL
     * @param {string} text - Post text
     * @returns {Promise<import("axios").AxiosResponse<Post>>}
     */
    createPost(title, description, image, preview, text, creationDate) {
        return http.post("/news/administrators", {
            title,
            description,
            image,
            preview,
            text,
            creationDate
        });
    },

    /**
     * Update a post
     * @param {number} id - Post id
     * @param {string} title - Post title
     * @param {string} description - Post description
     * @param {string} image - Post image URL
     * @param {string} preview - Post preview image URL
     * @param {string} text - Post text
     * @returns {Promise<import("axios").AxiosResponse<Post>>}
     */
    updatePost(id, title, description, image, preview, text, creationDate) {
        return http.put(`/news/${id}/administrators`, {
            title,
            description,
            image,
            preview,
            text,
            creationDate
        });
    },

    /**
     * Delete posts
     * @param {number[]} newsIds - Post ids
     * @returns {Promise<import("axios").AxiosResponse<null>>}
     */
    softDeletePosts(newsIds) {
        return http.delete(`/news/administrators/soft`, {
            data: {
                newsIds
            }
        });
    },

    /**
     * Restore posts
     * @param {number[]} newsIds - Post ids
     * @returns {Promise<import("axios").AxiosResponse<null>>}
     */
    restorePosts(newsIds) {
        return http.post(`/news/administrators/restore`, {
            newsIds
        });
    },

    /**
     * Get soft deleted posts
     * @returns {Promise<import("axios").AxiosResponse<Post[]>>}
     */
    getSoftDeletedPosts() {
        return http.get("/news/administrators/storage/remote");
    },

    /**
     * Upload file
     * @param {File} file - File to upload
     * @returns {Promise<import("axios").AxiosResponse<FileData>>} - URLs of uploaded files
     */
    uploadFile(file) {
        const formData = new FormData();
        formData.append("file", file);

        return http.post("/files/upload/news/image", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
}

