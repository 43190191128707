<template>
  <div>
    <Breadcrumbs title="ПАСПОРТНЫЕ ДАННЫЕ" />
    <div class="edit-passport">

      <div class="col-xl-12">
        <div class="passport-preview-sidebar md-sidebar shadow-lg card p-2 overflow-hidden " >
          <ul class="overflow-hidden" v-for="(item, index) in images" :key="index" >
            <li :key="index" v-if="activePhoto === index" class="d-flex justify-content-center aling-items-center mx-5">
              <img :src="this.FRONT_URL + item.path" alt="" @click="showModal = true"/>
              <div v-if="showModal" class="modal" @click="showModal = false">
                <img
                  :src="this.FRONT_URL + item.path"
                  alt="Full Size"
                  class="full-size-image"
                  @click.stop
                />
              </div>
            </li>
          </ul>
          <div class="card-body pagination-padding">
            <nav aria-label="Page navigation example">
              <ul class="pagination pagination-primary">
                <li class="page-item"><a class="page-link" @click="getPhotoVerifications(activePhoto > 0 ? activePhoto - 1 : activePhoto)">Назад</a></li>

                <li class="page-item"><a class="page-link" @click="getPhotoVerifications(activePhoto < images.length - 1 ? activePhoto + 1 : activePhoto)">Следующий</a></li>
              </ul>
            </nav>
          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row form-sidebar md-sidebar card shadow-lg p-4 mx-1">
            <div class="container-fluid">
              <div class="alert alert-success" v-if="this.messageSuccess">
                {{ messageSuccess }}
              </div>
              <div class="alert alert-danger" v-if="this.messageError">
                {{ messageError }}
              </div>
            </div>

            <h5>Паспортные данные</h5>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <label class="form-label">Фамилия</label>
                <input
                    class="form-control"
                    type="text"
                    :value="
                    passport && passport.profile
                      ? passport.profile.lastName
                      : ''
                  "
                    placeholder="Фамилия"
                    disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Имя</label>
                <input
                    class="form-control"
                    type="text"
                    :value="
                    passport && passport.profile
                      ? passport.profile.firstName
                      : ''
                  "
                    placeholder="Имя"
                    disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Отчество</label>
                <input
                    class="form-control"
                    type="text"
                    :value="
                    passport && passport.profile
                      ? passport.profile.middleName
                      : ''
                  "
                    placeholder="Имя"
                    disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Дата рождения</label>
                <input
                    class="form-control"
                    type="text"
                    :value="
                    passport && passport.profile
                      ? passport.profile.dateBirthday
                      : ''
                  "
                    placeholder="Имя"
                    disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Серия и номер</label>
                <input
                  class="form-control"
                  v-mask="'0000-000000'"
                  type="text"
                  :value="
                    passport && passport.profile
                      ? passport.profile.passportNumber
                      : ''
                  "
                  placeholder="Серия и номер"
                  disabled
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <label class="form-label">Дата выдачи</label>
                <input
                  class="form-control"
                  type="Date"
                  :value="
                    passport && passport.profile
                      ? passport.profile.passportDate
                      : ''
                  "
                  placeholder="@Дата выдачи"
                  disabled
                />
              </div>
            </div>
            <div v-if="passport && passport.profile.passportCode" class="col-sm-6 col-md-6">
              <div class="mb-3">
                <label class="form-label">Код подразделения</label>
                <input
                  class="form-control"
                  v-mask="'000-000'"
                  type="text"
                  :value="
                    passport && passport.profile
                      ? passport.profile.passportCode
                      : ''
                  "
                  placeholder="Код подразделения"
                  disabled
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Кем выдан</label>
                <input
                  class="form-control"
                  type="text"
                  :value="
                    passport && passport.profile
                      ? passport.profile.passportIssued
                      : ''
                  "
                  placeholder="Кем выдан?"
                  disabled
                />
              </div>
            </div>
            <div v-if="passport && passport.profile.passportAddress" class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Адрес регистрации</label>
                <input
                  class="form-control"
                  type="text"
                  :value="
                    passport && passport.profile
                      ? passport.profile.passportAddress
                      : ''
                  "
                  placeholder="Адрес регистрации"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Комментарий</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Комментарий"
                  v-model="this.comment"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="row my-3">
                <div class="col-12 col-md-6 col-xl-6 d-flex justify-content-center">
                  <button
                    :disabled="!this.comment"
                    class="btn btn-danger"
                    @click="
                      updateStatusAppeal(this.idDetailPage, 2, this.comment)
                    "
                  >
                    Отказать
                  </button>
                </div>
                <div class="col-12 col-md-6 col-xl-6 d-flex justify-content-center">
                  <button
                    class="btn btn-success"
                    @click="
                      updateStatusAppeal(this.idDetailPage, 3, this.comment)
                    "
                  >
                    Принять
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VerificarionsDataService from "@/services/VerificarionsDataService";
import listVerification from "@/pages/verification/listVerification.vue";
import { ArrowLeftIcon } from 'vue-feather'

export default {
  components: {
    ArrowLeftIcon,
  },
  data() {
    return {
      showModal: false,
      passport: null,
      images: null,
      activePage: null,
      activePhoto: 1,
      status: null,
      comment: null,
      messageSuccess: null,
      messageError: null,
      idDetailPage: this.$route.params.id,
    };
  },
  methods: {
    getPhotoVerifications(imagesIndex){
      this.activePhoto = imagesIndex
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    getVerificationDetail(id) {
      VerificarionsDataService.getVerificationDetail(id)
        .then(({ data }) => {
          this.passport = data;
          this.images = data.images;
          this.status = data.status;
          this.comment = this.passport.comment
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateStatusAppeal(id, status, comment) {
      VerificarionsDataService.updateStatusAppeal(id, status, comment)
        .then(({ data }) => {
          this.messageSuccess = data.message;
          this.$router.push('/verification/my/');
        })
        .catch((e) => {
          console.log(e);
          this.messageError = e.message;
        });
    },
  },
  mounted() {
    this.getVerificationDetail(this.$route.params.id);
  },
};
</script>

<style scoped>
.overflow-hidden{
  margin-top: 10px;
}
.navigation{
  height: 20px;
}
.passport-preview-sidebar{
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
}
.pagination-padding{
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-size-image {
  max-width: 100%;
  max-height: 100%;
}
</style>
