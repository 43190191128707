<template>
  <Breadcrumbs title="МОИ БИЛЕТЫ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <alert-error v-if="errorMessage" :text="errorMessage" />
          <alert-success v-if="successMessage" :text="successMessage" />

          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h5>МОИ БИЛЕТЫ</h5>
              </div>
              <div class="card-body">
                <p>В данном разделе отображаются купленные билеты.</p>
                <div class="default-according" id="accordion">
                  <div class="card" v-for="(item, index) of tickets" :key="item.id">
                    <div class="card-header" :id="'heading'">
                      <h5 class="mb-0">
                        <button class="btn btn-link" @click="toggleAccordion(index)">
                          <span class="accountType">
                            {{ item.meeting.topic }}
                            № {{ item.numberTicket }}
                            - {{ formattedDate(item.meeting.meetingDateStart) }}
                          </span>
                        </button>
                      </h5>
                    </div>
                    <div class="collapse" :id="'collapse' +index" :aria-labelledby="'heading' + index"
                         data-bs-parent="#accordion">
                      <div class="card-body card-body-text">
                        Номер билета:
                        <a href="javascript:{}" @click.stop="copyLink(item.numberTicket)">
                          <code>{{ item.numberTicket }}</code>
                          <i class="icofont icofont-copy-alt"></i>
                        </a>
                        <br/>
                        Мероприятие: {{ item.meeting.topic }} <br/>
                        Дата: {{ formattedDate(item.meeting.meetingDateStart) }}
                        <router-link :to="'/events/list/' + item.meeting.id">Посмотреть</router-link>
                        <p class="mt-3">
                          Вы можете передать ссылка для регистрации на мероприятие
                          участнику, чтобы он самостоятельно зарегистрировался.
                        </p>
                        <a href="https://forms.yandex.ru/cloud/65f95c415d2a060d617914cb/" target="_blank">Зарегистрировать
                          билет</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import MeetingDataService from "@/services/MeetingDataService";
import moment from "moment/moment";

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      tickets: [],
      items: [
        { title: "Заголовок 1", content: "Содержимое 1" },
        { title: "Заголовок 2", content: "Содержимое 2" },
        { title: "Заголовок 3", content: "Содержимое 3" },
      ],
      activeIndex: -1,
    };
  },
  components: {
    AlertSuccess,
    AlertError,
  },
  mounted() {
    this.getTickets();
  },
  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? -1 : index;
    },
    getTickets() {
      MeetingDataService.getTickets()
        .then(({ data }) => {
          this.tickets = data;
          console.log(this.tickets);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copyLink(number) {
      navigator.clipboard.writeText(number);
      this.successMessage = "Номер билета скопирован";
    },
    formattedDate(date) {
      moment.locale("ru");
      return moment(date).format("D MMMM HH:mm");
    },
  },
};
</script>

<style scoped>
.card-body-text {
  font-size: 15px !important;
}
</style>
