<template>
  <div>
    <Breadcrumbs title="Создание теста" />
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <p class="alert alert-warning" v-if="errorMessage">{{ errorMessage }}</p>
          <p class="alert alert-success" v-if="successMessage">{{ successMessage }}</p>
          <h4>Создайте тест</h4>
          <span
            >Здесь Вы можете тщательно подготовить и настроить содержание вашего
            теста.</span
          >
        </div>
        <div class="card-body">
          <div
            class="horizontal-wizard-wrapper vertical-options vertical-variations"
          >
            <div class="row g-3">
              <form class="row g-3 needs-validation" @submit.prevent="creatureTest">
                <div class="col-md-6">
                  <label class="form-label" for="AccountName"
                    >Название<span class="txt-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    id="AccountName"
                    type="text"
                    required
                    placeholder="Напишите название теста"
                    v-model="form.name"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label">Курсы<span class="txt-danger">*</span></label>
                  <select
                      class="form-select"
                      id="validationCustom04"
                      v-model="form.courseId"
                  >
                      <option value="" disabled>Выберите курс</option>
                      <option v-for="(item, index) in courses" :key="index" :value="item.id">{{ item.name }}</option>
                  </select>
                  <div v-if="isDefaultCourseSelected" class="text-danger">Выберите курс</div>
                </div>
                <div class="form-check ps-0 select-account">
                  <div class="col-md-6">
                    <label class="form-label" for="AccountName2"
                      >Кол-во верных ответов для успешного прохождения<span class="txt-danger">*</span></label
                    >
                    <input class="form-control" :id="'AccountName2-' + qIndex" type="text" required placeholder="Напишите название вопроса" v-model="form.numberOfCorrectAnswers" @input="onInput">

                  </div>
                </div>
              </form>

              <div class="col-xl-3 main-horizontal-header">
                <div
                  class="nav nav-pills horizontal-options"
                  id="vertical-n-wizard-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    v-for="(item, index) in form.questions"
                    :key="index"
                    class="nav-link"
                    :class="{ active: index === 0 }"
                    :id="'wizard-n-info-tab-' + index"
                    data-bs-toggle="pill"
                    :href="'#wizard-n-info-' + index"
                    role="tab"
                    :aria-controls="'wizard-n-info-' + index"
                    :aria-selected="selectedTabIndex === index"
                  >
                    <div class="horizontal-wizard" @click="selectedTabIndex = index">
                      <div class="stroke-icon-wizard">
                        <span>{{ index + 1 }}</span>
                      </div>
                      <div class="horizontal-wizard-content">
                        <h6>Вопрос {{ index + 1 }}</h6>
                      </div>
                    </div>
                    <i
                      class="icofont icofont-trash"
                      @click="confirmDeleteQuestion(index)"
                    ></i>
                  </a>
                </div>

                <div class="col-12 text-end">
                  <button class="btn btn-primary" @click="addQuestion">
                    Добавить вопрос
                  </button>
                </div>
              </div>

              <div class="col-xl-9">
                <div
                  class="tab-content dark-field"
                  id="vertical-n-wizard-tabContent"
                >
                  <div
                    v-for="(item, index) in form.questions"
                    :key="index"
                    class="tab-pane fade"
                    :class="{
                      'show active': selectedTabIndex === index,
                      active: index === 0,
                      show: index === 0,
                      'd-none': index !== selectedTabIndex,
                    }"
                    :id="'wizard-n-info-' + index"
                    role="tabpanel"
                    :aria-labelledby="'wizard-n-info-tab-' + index"
                  >
                    <form class="row g-3 needs-validation" novalidate>
                      <div class="col-12">
                        <div class="form-check ps-0 select-account">
                          <div class="col-md-8">
                            <label class="form-label" for="AccountName2"
                              >Название вопроса<span class="txt-danger">*</span></label
                            >
                            <input
                              class="form-control"
                              :id="'AccountName2-' + qIndex"
                              v-model="
                                form.questions[selectedTabIndex].question
                              "
                              type="text"
                              required
                              placeholder="Напишите название вопроса"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(question, index) in form.questions[
                          selectedTabIndex
                        ].answers"
                        :key="index"
                      >
                        <div class="col-12">
                          <div class="form-check ps-0 select-account">
                            <div class="col-md-8">
                              <label
                                class="form-label"
                                :for="'AccountName1-' + index"
                                >Вариант ответа {{ index + 1 }}<span class="txt-danger">*</span></label
                              >

                              <div class="question-inner">
                                <input
                                  class="form-control"
                                  :id="'AccountName1-' + qIndex"
                                  type="text"
                                  required
                                  v-model="
                                    form.questions[selectedTabIndex]
                                      .answers[index].answer
                                  "
                                  placeholder="Напишите ответ"
                                />
                                <div class="checkbox p-0">
                                  <input
                                      v-model="form.questions[selectedTabIndex].answers[index].isCorrectAnswer"
                                      :id="'checkbox-' + index"
                                      type="checkbox"
                                      :disabled="isAnyCheckboxChecked && !form.questions[selectedTabIndex].answers[index].isCorrectAnswer"
                                  />
                                  <label class="text-muted" :for="'checkbox-' + index">Правильный</label>
                                </div>
                                <i
                                  class="icofont icofont-trash"
                                  @click="
                                    deleteAnswer(
                                      index
                                    )
                                  "
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-end">
                        <div
                          @click="addAnswer(index)"
                          class="btn btn-pill btn-outline-primary btn-air-primary"
                        >
                          <i class="icofont icofont-ui-add"></i>
                          Добавить ответ
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end">
                <button class="btn btn-success" @click="creatureTest(this.form)">
                  Создать тест
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import TestingDataService from "@/services/TestingDataService";
import CoursesDataService from '@/services/CoursesDataService';

export default {
  data() {
    return {
    selectedTabIndex: 0,
    courses: null,
    successMessage: null,
    errorMessage: null,
      form: {
        name: "",
        courseId: 0,
        numberOfCorrectAnswers: parseInt('1'),
        questions: reactive([
          {
            question: "",
            answers: reactive([
              {
                answer: "",
                isCorrectAnswer: false,
              },
            ]),
          },
        ]),
      },
    };
  },
  methods: {
    creatureTest(body) {
      if (!body.name) {
    this.errorMessage = "Введите название теста";
    return;
  }

    if (!body.courseId) {
    this.errorMessage = "Выберите курс";
    return;
  }
  for (let question of body.questions) {
    if (!question.question) {
      this.errorMessage = "Введите название вопроса";
      return;
    }
    for (let answer of question.answers) {
      if (!answer.answer) {
        this.errorMessage = "Введите ответ на вопрос";
        return;
      }
    }
  }
        TestingDataService.postCreatureTest(body)
        .then(({data}) => {
                    this.errorMessage = ''
                    this.successMessage = "Тест успешно создан!"
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error.response.data.message
                });
    },
    getAllCourses(){
            CoursesDataService.get()
            .then(( response ) => {

                this.courses = response.data
            }
            ) .catch ((error) => {
                console.log(error);
            })
        },
    onInput() {
        this.form.numberOfCorrectAnswers = this.form.numberOfCorrectAnswers.replace(/\D/g, '');
        this.form.numberOfCorrectAnswers = parseInt(this.form.numberOfCorrectAnswers);
    },
    addQuestion() {
      this.form.questions.push({
        question: "",
        answers: reactive([
          {
            answer: "",
            isCorrectAnswer: false,
          },
        ]),
      });
    },
    addAnswer(index) {
      this.form.questions[index].answers.push({
        answer: "",
        isCorrectAnswer: false,
      });
    },
    confirmDeleteQuestion(index) {
      if (index === 0){
        this.errorMessage = 'Вы не можете удалить первый вопрос'
      } else if (index === this.form.questions.length - 1){
        if (confirm("Вы уверены, что хотите удалить этот вопрос?")) {
          this.selectedTabIndex = index
          this.deleteQuestion(index);
      }
      } else {
        if (confirm("Вы уверены, что хотите удалить этот вопрос?")) {
          this.deleteQuestion(index);
        }
      }
    },
    deleteQuestion(index) {
      this.form.questions.splice(index, 1);
      if (this.selectedTabIndex >= this.form.questions.length) {
        this.selectedTabIndex = this.form.questions.length - 1;
      }
    },
    deleteAnswer(index){
      this.form.questions[this.selectedTabIndex].answers.splice(index, 1);
    }
  },
  computed: {
    isAnyCheckboxChecked() {
        const answers = this.form.questions[this.selectedTabIndex].answers;
        return answers.some(answer => answer.isCorrectAnswer);
    }
  },
  mounted() {
    this.getAllCourses()
  }
};
</script>

<style scoped>
.btn {
  margin-right: 20px;
}

.question-inner {
  display: flex;
  align-items: center;
}

.question-inner i {
  margin-left: 10px;
}

.horizontal-wizard-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.text-muted{
  margin-left: 30px;
}
</style>
