import FoundersDataService from "@/services/FoundersDataService"

async function fetchOverAllBalance() {
    try {

        const authToken = localStorage.getItem('authToken');
        if (!authToken) {

            return;
        }

        await FoundersDataService.getUsersStatistic()
            .then((response) => {

                const statistics = response.data

                overallbalanceTotal[0].amount = statistics.allUsersCount

                overallbalance[0].amount = statistics.newUsersByLastMonth

                overallbalance[1].amount = `${statistics.activeUsers} / <div class="text-second ms-1"> ${statistics.inactiveUsers} </div>`;

            })
            .catch((e) => {
                console.log(e)
                isError = true
            })

        isLoading = false

    } catch (error) {
        console.log(error)
    }
}

export const overallbalance = [
    {
        iconName: "newUsers",
        title: "Новые пользователи",
        amount: "0",
        balance: "",
        balanceClass: "font-success"
    },
    {
        iconName: "activeOrInactive",
        title: "Активные/Неактивные",
        amount: "0",
        balance: "",
        balanceClass: "font-danger"
    },
]

export const overallbalanceTotal = [
    {
        iconName: "allUsers",
        title: "Всего",
        amount: "0",
        balance: "",
        balanceClass: "font-danger"
    },
]

export let isLoading = true

export let isError = false

export { fetchOverAllBalance };