<template>
  <div>
    <Breadcrumbs title="Редактировать новость" />

    <div class="container-fluid">
      <alert-success
        v-if="formState.success"
        :text="formState.successMessage"
      />
      <alert-error v-if="formState.error" :text="formState.errorMessage" />
    </div>

    <div class="container-fluid p-3 pt-1">
      <form action="#" @submit.prevent="onSubmitForm">
        <div class="mb-3">
          <label for="postHeaderField" class="form-label"
            >Заголовок поста</label
          >
          <input
            type="text"
            class="form-control"
            id="postHeaderField"
            v-model="formState.title"
            required
          />
        </div>
        <div class="mb-3">
          <label for="postDescriptionField" class="form-label"
            >Краткое описание поста</label
          >
          <input
            type="text"
            class="form-control"
            id="postDescriptionField"
            v-model="formState.description"
            required
          />
        </div>
        <div class="mb-3">
          <label for="postTextField" class="form-label">Текст записи</label>
          <!-- <textarea class="form-control" id="postTextField" rows="3" v-model="formState.text" required></textarea> -->
          <quill-editor
            v-model:value="quillState.content"
            :options="quillState.editorOption"
            @change="
              ({ quill, html, text }) => {
                quillState._content = html;
              }
            "
          />
        </div>
        <div class="mb-3">
          <label for="postImageField" class="form-label"
            >Изображение записи</label
          >
          <div class="row w-100">
            <div class="col">
              <input
                type="file"
                name="postImageField"
                id="postImageField"
                class="form-control"
                @change="onSelectMainImageFile"
                accept="image/*"
                :required="formState.mainImageChanged"
              />
            </div>
            <div class="col">
              <button
                class="btn btn-primary"
                @click.prevent="
                  displayedImageURL =
                    createDataURL(formState.mainImageFile) ??
                    `${backendURL}/${sourcePost.image}`;
                  imageModal.show();
                "
              >
                Просмотреть изображение
              </button>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="postPreviewField" class="form-label"
            >Обложка записи</label
          >
          <div class="row w-100">
            <div class="col">
              <input
                type="file"
                name="postPreviewField"
                id="postPreviewField"
                class="form-control"
                @change="onSelectPreviewImageFile"
                accept="image/*"
                :required="formState.previewImageChanged"
              />
            </div>
            <div class="col">
              <button
                class="btn btn-primary"
                @click.prevent="
                  displayedImageURL =
                    createDataURL(formState.previewImageFile) ??
                    `${backendURL}/${sourcePost.preview}`;
                  imageModal.show();
                "
              >
                Просмотреть изображение
              </button>
            </div>
          </div>
        </div>
        <div class="mb-3">
            <label class="form-label">Дата публикации</label>
            <input
                v-model="formState.date"
                class="form-control"
                type="datetime-local"
                @input="isTouched5 = true"
                required
            />
        </div>
        <div class="mb-3">
          <button type="submit" class="btn btn-primary">
            Сохранить изменения
          </button>
        </div>
      </form>
    </div>

    <div class="modal" tabindex="-1" id="imageModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Просмотр изображения</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Закрыть"
            ></button>
          </div>
          <div class="modal-body">
            <img :src="displayedImageURL" alt="" class="img-fluid w-100" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import _ from "lodash";
import { onMounted, reactive } from "vue";

import { Modal } from "bootstrap";
import alertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";

import NewsAPI from "@/services/NewsDataService";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
//#endregion

//#region Client
/**
 * Source post object
 * @description Keeps the source post data
 * @type {import('vue').Ref<import('@/services/NewsDataService').Post>}
 */
const sourcePost = ref({});
const postId = computed(() => Number(useRoute().query.id));
/**
 * Form state object
 * @description Holds state of edit form
 * @property {string} title - Post title
 * @property {string} description - Post description
 * @property {string} text - Post text
 * @property {import("vue").Ref<File>} imageFile - Image file
 * @property {boolean} imageChanged - Defines if image changed during edit
 * * @property {string} date - date add news 
 */
const formState = reactive({
  title: "",
  description: "",
  text: "",
  mainImageFile: null,
  mainImageChanged: false,
  previewImageFile: null,
  previewImageChanged: false,
  success: null,
  successMessage: null,
  error: null,
  errorMessage: null,
  date: '2024.01.01'
});

const quillState = ref({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "В начале было слово...",
  },
  disabled: false,
});

/**
 * @type {import('vue').Ref<Modal>}
 */
const imageModal = ref();

const displayedImageURL = ref();

function onSelectMainImageFile(e) {
  formState.mainImageChanged = true;
  if (e.target.files[0].type.includes("image/")) {
    formState.mainImageFile = e.target.files[0];
    formState.error = false;
  } else {
    formState.error = true;
    formState.errorMessage = "Загружать можно только изображения!";
    e.target.value = null;
  }
}

function onSelectPreviewImageFile(e) {
  formState.previewImageChanged = true;
  if (e.target.files[0].type.includes("image/")) {
    formState.previewImageFile = e.target.files[0];
    formState.error = false;
  } else {
    formState.error = true;
    formState.errorMessage = "Загружать можно только изображения!";
    e.target.value = null;
  }
}

function createDataURL(file) {
  try {
    return URL.createObjectURL(file);
  } catch (error) {
    return undefined;
  }
}

const backendURL = computed(() => process.env.VUE_APP_BACKEND_API_BASE);

const router = useRouter();
//#endregion

//#region Network
async function onSubmitForm() {
  // Upload file to server and get urls
  try {
    formState.text = quillState.value.content;
    // let preview, image;
    // if (!formState.imageChanged) {
    //     preview = sourcePost.value.preview;
    //     image = sourcePost.value.image;
    // } else {
    //     const res = (await NewsAPI.uploadFile(formState.imageFile)).data.data;
    //     preview = res.preview;
    //     image = res.image;
    // }
    let mainImageURL, previewImageURL;
    if (formState.mainImageChanged) {
      mainImageURL = (await NewsAPI.uploadFile(formState.mainImageFile)).data
        .data.image;
    } else {
      mainImageURL = sourcePost.value.image;
    }
    if (formState.previewImageChanged) {
      previewImageURL = (await NewsAPI.uploadFile(formState.previewImageFile))
        .data.data.image;
    } else {
      previewImageURL = sourcePost.value.preview;
    }
    const res = await NewsAPI.updatePost(
      postId.value,
      formState.title,
      formState.description,
      mainImageURL,
      previewImageURL,
      formState.text,
      formState.date
    );
    formState.error = false;
    formState.success = true;
    formState.successMessage = "Успешно выполнено, переход к странице поста...";
    setTimeout(() => {
      router.push(`/news/${res.data.id}`);
    }, 200);
  } catch (error) {
    console.log(error);
    let details;
    if (typeof error.response.data.message == "string")
      details = error.response.data.message;
    else if (typeof error.response.data.message == "object")
      details = _.join(error.response.data.message, ", ");

    formState.error = true;
    formState.errorMessage = `Ошибка при выполнении запроса: ${error.response.data.error}<br />
        <details>
            <summary>
                Дополнительная информация об ошибке:
                <br />
            </summary>
            <div
                class="div p-3 mt-2"
                style="background-color: #1c1c1c"
            >
                <code style="color: white">
                    ${details}<br />
                </code>
            </div>
        </details>
        `;
  }
}
//#endregion

//#region Hooks
onMounted(async () => {
  imageModal.value = new Modal(document.getElementById("imageModal"), {
    backdrop: true,
    focus: true,
    keyboard: true,
  });

  sourcePost.value = (await NewsAPI.getById(postId.value)).data;

  formState.title = sourcePost.value.title;
  formState.description = sourcePost.value.description;
  formState.text = sourcePost.value.text;
  formState.date = sourcePost.value.creationDate
});
//#endregion
</script>
