import http from "../http-common"

export default {
    getChatList() {
        return http.get('/chatSupport/admin/chats')
    },

    getChat(userId) {
        return http.get(`/chatSupport/admin/chat/${userId}`)
    },

    sendMessage(userId, message) {
        return http.post(`/chatSupport/admin/chat/${userId}/sendMessage`, {
            message
        })
    },

    getSupportStaff() {
        return http.get('/chatSupport/admin/responsibleUsers')
    },

    changeSupportStaffForChat(userId, responsibleUserId) {
        return http.put(`/chatSupport/admin/chat/${userId}/changeResponsibleUser`, {
            "type": "placeholder"
        }, {
            params: {
                responsibleUserId
            }
        })
    },
    
    markAsCompleted(userId) {
        return http.put(`/chatSupport/admin/chat/${userId}/endChat`)
    },

    switchToOffline() {
        return http.put(`/chatSupport/admin/offline`)
    },

    switchToOnline() {
        return http.put(`/chatSupport/admin/online`)
    }
}