<template>
  <div class="w-100 h-100 p-0">
    <div class="row flex-column flex-sm-row h-100">
      <div class="col-lg-12 col-sm-12 pb-2">
        <div class="card-item h-100 p-3 w-100 d-flex flex-column justify-content-center">
          <h5>
            Личный кабинет, регистрация
          </h5>
          <div class="w-100 row">
            <a class="url col-9" href="javascript:{}" @click.stop="copyLink">
              <code>
                https://rentier.world/my/auth/register?start={{ referralCode }}</code>
            </a>
            <a class="col-3 p-0 d-flex justify-content-center align-items-center " href="javascript:{}" @click.stop="copyLink">
              Копировать <i class="icofont icofont-copy-alt ms-1"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-sm-12 pt-2">
        <div class="card-item h-100 p-3 w-100 d-flex flex-column justify-content-between">
          <h5>
            Лендинг
          </h5>
          <div class="w-100 row">
            <a class="url col-9" href="javascript:{}" @click.stop="copyLink2">
              <code>
                https://rentier.city/?start={{ referralCode }}</code>
            </a>
            <a class="col-3 p-0 d-flex justify-content-center align-items-center " href="javascript:{}" @click.stop="copyLink2">
              Копировать <i class="icofont icofont-copy-alt ms-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserDataService from "@/services/UserDataService";

export default {
  data() {
    return {
      referralCode: '',
    };
  },
  mounted() {
    UserDataService.get()
        .then(response => {
          const user = response.data;

          this.referralCode = user.user.referralCode;

        })
        .catch(e => {
          console.log(e);
        });
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(`https://rentier.world/my/auth/register?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    },
    copyLink2() {
      navigator.clipboard.writeText(`https://rentier.city?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    }
  },
};
</script>

<style scoped>

a{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.url{
  font-size: 12px;
}
code{
  margin: 0;
  padding: 0;
}

</style>