<template>
  <div>
  <Breadcrumbs main="АБОН. ПЛАТА" title="ПОДПИСКА" />
  <div class="container invoice" id="print">
    <div class="row">
      <div class="col-sm-12">
        <div class="card-wrapper d-flex justify-content-between w-100">
          <div class="card-wrapper-left">
            <div class="card-wrapper-left-title">
              Ваш ключ к успеху
            </div>
            <div class="card-wrapper-left-under-title">
              Оплатите 1 500 р и откройте для себя новые возможности!
            </div>
            <div class="card-wrapper-left-list">
              <div class="card-wrapper-left-list-item d-flex">
                <img src="@/assets/images/user/strelka.svg" alt="">
                <div class="card-wrapper-left-list-item-text">Участие в партнерской программе с выгодными условиями</div>
              </div>
              <div class="card-wrapper-left-list-item d-flex">
                <img src="@/assets/images/user/strelka.svg" alt="">
                <div class="card-wrapper-left-list-item-text">Возможность размещения и продвижения ваших товаров и услуг на платформе</div>
              </div>
              <div class="card-wrapper-left-list-item d-flex">
                <img src="@/assets/images/user/strelka.svg" alt="">
                <div class="card-wrapper-left-list-item-text">Бесплатный вводный курс по финансовой грамотности для уверенного управления своими финансами</div>
              </div>
            </div>
            <div v-if="!startDate" class="card-wrapper-left-btn mt-3">
              <div class="dropdown-wrapper position-relative" @click="toggleDropdown">
                <div class="btn btn-primary text-center" >
                  Оформить подписку
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Arrow / Caret_Down_SM">
                      <path id="Vector" d="M15 11L12 14L9 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  </svg>

                </div>
                <transition name="fade">
                  <div class="dropdown-content" v-if="isOpen">
                    <div class="btn" @click="switchModal" >
                      Оплата за рубли
                    </div>
                    <div class="btn" @click="switchModal(1)" >
                      Оплата за баллы
                    </div>
                    <div class="btn" @click="switchModal(2)" >
                      Оплата за внутренний счет
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="card-wrapper-right">
            <img src="@/assets/images/user/back.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-5">
        <div class="card">
          <div class="card-body">
            <div>
              <div>
                <div class="row invo-header">
                  <div class="col-sm-10">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1 m-l-20">
                        <h4 class="d-flex-headin">ДОСТУП К ПЛАТФОРМЕ “РАНТЬЕ”</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row invo-profile">

                <div class="col">
                  <div class="invo-profile-right">
                    <div id="project" class="text-xl-start">
                      <p>Ваша подписка на использование программного комплекса</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <alert-error v-if="errorMessage" :text="errorMessage" />
                <alert-success v-if="successMessage" :text="successMessage" />

                <div class="table-responsive invoice-table" id="table">
                  <InvoiceTable v-if="startDate" :startDate="startDate" :endDate="endDate" />
                </div>
                <div class="row mt-3">
                  <div class="col-md-8" v-if="startDate">
                    <div>
                      <p class="legal"><strong>Благодарим за использование системы.</strong></p>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="!startDate">
                    <div class="col">
                      <div>
                        <p class="legal">На данный момент у Вас нет активной подписки на использование программного
                          комплекса. Стоимость использования на данный момент составляет: 1 500 рублей на год.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="mt-3" v-if="!startDate && idSystemPayment < 3">
              <div class="form-inline">
                <div class="m-2">
                  <button class="btn btn btn-primary" type="button" v-if="!isCode" @click="clickPayment">
                    Оплатить подписку
                  </button>
                </div>
                <div class="mt-2 form-check">
                  <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                    v-model="isAmountAccount" :disabled="isCode">
                  <label class="form-check-label" for="isAmountAccount">Зачесть в погашение 100% стоимости средства со
                    внутреннего счета. <a href="../documents/internal-credit" target="_blank"
                      v-if="isAmountAccount">Заявление
                      на зачет.</a></label>
                </div>
              </div>
              <codeConfirmation :isCode=isCode :validate=validate :isAmountAccount=isAmountAccount :idSystemPayment=idSystemPayment :paymentData=paymentData :typePayment=1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
      <div class="modal-overlay" @click="switchModal"></div>
      <div v-if="isLoadingSend" class="modal-content d-flex justify-content-center p-5" >
        <div class="loader-cover m-auto"></div>
      </div>
      <div v-else-if="errorMessage" class="modal-content" >
        <div
            class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
          <img src="@/assets/svg/info-circle.svg" alt="">
          <h4 class="title my-3" style="color: #444444">
            Ошибка!
          </h4>
          <div class="subtitle text-center mb-3" style="color: #52526CBF">
            {{ errorMessage }}
          </div>
          <div class="btn-wrapper text-end w-100 d-flex justify-content-center">
            <div class="btn btn-primary" @click="switchModal">
              Ок
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="successMessage" class="modal-content" >
        <div
            class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
          <img src="@/assets/svg/Vector.svg" alt="">
          <h4 class="title my-3" style="color: #444444">
            Успех!
          </h4>
          <div class="subtitle text-center mb-3" style="color: #52526CBF">
            {{ successMessage }}
          </div>
          <div class="btn-wrapper text-end w-100 d-flex justify-content-center">
            <div class="btn btn-primary" @click="switchModal">
              Ок
            </div>
          </div>
        </div>
      </div>
      <div v-else class="modal-content d-flex justify-content-center" >
        <div class="modal-message">
          <div class="modal-message-title modal-header d-flex justify-content-between align-items-center">
            <h3 class="modal-title-text m-0">Подтверждение</h3>
            <vue-feather @click="switchModal" type="x" class="cursor-pointer" ></vue-feather>
          </div>
        </div>
        <div class="modal-body px-3 py-4">
          <codeConfirmation :isCode=isCode :validate=validate :isAmountAccount=isAmountAccount :idSystemPayment=idSystemPayment :paymentData=paymentData :typePayment=1 :isActiveModal="isActiveModal" @update:isActiveModal="updateIsActiveModal"/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import InvoiceTable from './activateTable.vue';
import SubscriptionDataService from "@/services/SubscriptionDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertError from "@/components/alert-error.vue";
import codeConfirmation from "./codeConfirmationActivate.vue"

export default {
  
  name: 'invoiceView',
  components: {
    AlertError,
    AlertSuccess,
    InvoiceTable,
    codeConfirmation
  },
  data() {
    return {
      isActiveModal: false,
      isLoadingSend: false,
      isOpen: false,
      isAmountAccount: false,
      errorMessage: '',
      successMessage: '',
      startDate: Date,
      endDate: Date,
      idSubscription: Number,
      idSystemPayment: 0,
      isCode: false,
      validate: {
        id: Number,
        code: '',
      },
      paymentData: {
        name: 'Абонентская плата',
        summa: 1500,
        typePayment: 1,
        isAmountAccount: false
      },
    }
  },
  mounted() {
    MoneyDataService.getIdPaymentService()
      .then(response => {
        this.idSystemPayment = Number(response.data.idSystemPayment);
      })
      .catch(e => {
        console.log(e);
      });

    SubscriptionDataService.get()
      .then(response => {
        this.startDate = response.data.startDate;
        this.endDate = response.data.endDate;
        this.idSubscription = (response.data.id) ? response.data.id : 'Не активна';
      })
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    updateIsActiveModal(newValue) {
      this.isActiveModal = newValue;
    },

    switchModal(idOperation){

      this.isActiveModal = !this.isActiveModal;
      this.isLoadingSend = true
      if (this.isActiveModal) {
        if (idOperation === 1) {

          OperationsDataService.postPaySubscribe()
              .then((response) => {
                this.isLoadingSend = false
                this.errorMessage = ''
                this.successMessage = 'Транзакция успешна!'
                console.log(response)
              })
              .catch((e) => {
                this.errorMessage = e.response.data.message
                this.isLoadingSend = false

              })

          return

        }

        if (idOperation === 2) {

          this.isAmountAccount = true

        }

        this.clickPayment()

      }


    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    clickPayment() {
      this.isLoadingSend = true
      //код показывать
      this.isCode = true;

      OperationsDataService
        .get({ typeOperation: 1 })
        .then(response => {
          this.errorMessage = ''
          this.validate.id = response.data;
        })
          .finally(() => {
            this.isLoadingSend = false
          })
    },
    codeValidate() {
      OperationsDataService
        .codeValidate(this.validate)
        .then(response => {
          console.log(response)
          const isValidate = response.data;
          if (isValidate) this.choiceAcquiring();
          else this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
        })
    },
    choiceAcquiring() {
      localStorage.setItem('paymentData', JSON.stringify(this.paymentData));
      if (this.isAmountAccount) this.makePaymentRedirect();
      if (this.idSystemPayment === 0 && !this.isAmountAccount) window.location.href = '/my/payments/cloudPayments';
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.makePaymentRedirect();
    },
    makePaymentRedirect() {
      MoneyDataService.payment({
        typePayment: 1,
        selectPaymentSystem: 1,
        isAmountAccount: this.isAmountAccount,
        summa: 0,
      })
        .then(response => {

          if (this.isAmountAccount)
            this.successMessage = 'Благодарим за покупку.'
          else {
            window.location.href = response.data.url;
            this.successMessage = 'Оплатите подписку на сайте банка.';
          }
        })
        .catch(e => {
          this.errorMessage = 'Ошибка приобретения подписки. ' + e.response.data.message;
          console.log(e);
        });
    },
    checkBox() {
      if (this.isAmountAccount) {
        localStorage.setItem('isAmountAccount', true);
      } else {
        localStorage.removeItem('isAmountAccount');
      }
    }
  },
}
</script>

<style scoped>
.card-wrapper-left-btn{
  margin-bottom: 40px;
}
.card-wrapper-right{
  margin-top: 62px;
}

.card-wrapper-left-list-item-text{
  color: #2F2F3B;
  font-size: 15px;
  font-weight: 500;
}
.card-wrapper-left-under-title{
  margin-bottom: 25px;
}
.card-wrapper-left-title{
  margin-bottom: 10px;
}
.card-wrapper-left{
  margin-top: 33px;
  margin-left: 40px;
}
.card-wrapper-left-list-item{
  gap: 16px;
}
.card-wrapper{
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  border-radius: 16px;
  background-color: #ffffff;
}
.card-wrapper-left-under-title{
  color: #52526C80;
  font-size: 18px;
  font-weight: 400;
}
.card-wrapper-left-title{
  color: #212529;
  font-size: 24px;
  font-weight: 500;
}
@media screen and (max-width: 768px){
.card-wrapper{
  flex-direction: column;
}
  .card-wrapper-left{
    margin-top: 33px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .card-wrapper-left-title, .card-wrapper-left-under-title, .card-wrapper-left-btn{
    text-align: center;
  }
  .card-wrapper-right{
    display: flex;
    justify-content: right;
  }
  .card-wrapper-right img{
    width: 330px;
  }
}

.btn {
  width: min(100%, 230px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-wrapper{
  width: min(100%, 230px);
}
.btn svg {
  margin-left: 8px;
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0  0 15px 15px;
  left: 50%;
  transform: translate(-50%,0);
}

.dropdown-content .btn {
  padding: 12px;
  text-decoration: none;
  display: block;
  color: #7366FF;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: .5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: #ededed;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.modal-wrapper{
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all .3s;
  .modal-content {
    background-color: white;
    max-width: 523px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    transform: translateY(-30px);
    transition: all .3s;
    .modal-header{
      padding: 10px 15px;
      border-bottom: 1px solid #DEE2E6FF;
    }
  }
  .modal-footer{
    padding: 10px 15px;
    border-top: 1px solid #DEE2E6FF;
  }
}
.modal-wrapper.active{
  visibility: visible;
  opacity: 1;
  transition: all .3s;
  .modal-content {
    transform: translateY(0px);
    transition: all .3s;
  }
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
</style>