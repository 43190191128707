<template>
    <Breadcrumbs title="Поддержка" />

    <div class="px-2 pb-2">
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn" :class="chat.questionCompletionTime ? 'btn-success' : 'btn-primary'" @click="markChatAsCompleted()">
                    <template v-if="!chat.questionCompletionTime">
                        Пометить как решенный
                    </template>
                    <template v-else>
                        Помечен как решенный
                    </template>
                </button>
            </div>
        </div>
    </div>

    <div class="col call-chat-body">
        <div class="card">
            <div class="card-body p-0">
                <div class="row chat-box">
                    <div class="col pe-0 chat-right-aside">
                        <div class="chat">
                            <div class="chat-header clearfix">
                                <!-- Здесь аватарка -->
                                <!-- <img class="rounded-circle" v-if="currentchat.thumb" :src="getImageUrl(currentchat.thumb)"
                                    alt="" /> -->
                                <div class="about">
                                    <div class="name">
                                        <template v-if="chat.responsibleUser">
                                            {{ chat.responsibleUser.profile.lastName }}
                                            {{ chat.responsibleUser.profile.firstName }}
                                            {{ chat.responsibleUser.profile.middleName }}
                                        </template>
                                        <!-- <span class="font-primary f-12 ms-1">печатает...</span> -->
                                    </div>
                                    <div class="status">
                                        онлайн
                                    </div>
                                </div>
                                <ul class="list-inline float-start float-sm-end chat-menu-icons">
                                    <li class="list-inline-item toogle-bar"
                                        @click.prevent="chatmenutoogle = !chatmenutoogle">
                                        <!-- <a href="#"><i class="icon-menu"></i></a> -->
                                    </li>
                                </ul>
                            </div>
                            <div class="chat-history chat-msg-box custom-scrollbar" id="chatInput" ref="chatInput">
                                <div id="msg-list">
                                    <div v-for="(message, index) in chat.messages" :key="index"
                                        v-bind:class="{ clearfix: message.user.id == profile.user.id }">
                                        <div class="message" v-bind:class="{
                                            'other-message pull-right': message.user.id == profile.user.id,
                                            'my-message': message.user.id != profile.user.id,
                                            'text-end': message.user.id == profile.user.id
                                        }">
                                            <!-- Здесь тоже аватарка -->
                                            <!-- <img class="rounded-circle float-start chat-user-img img-30 text-end" alt=""
                                                v-if="currentchat.thumb && chat.user.id != 0"
                                                v-bind:src="" /> -->
                                            <!-- <img class="rounded-circle float-end chat-user-img img-30" alt=""
                                                v-if="chat.user.id == 0" v-bind:src="getImageUrl('user/1.jpg')" /> -->

                                            {{ message.message }}
                                            <div class="message-data text-start"
                                                v-bind:class="{ 'text-end': message.user.id == profile.user.id }">
                                                <span class="message-data-time">
                                                    {{
                                                        moment(message.created_at).format('DD.MM.YYYY HH:mm') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-message clearfix">

                                <div class="row">
                                    <div class="col-xl-12 d-flex">
                                        <div class="input-group text-box" ref="abc">
                                            <input class="form-control input-txt-bx" id="message-to-send" type="text"
                                                name="message-to-send" placeholder="Введите текст сообщения..."
                                                v-model="messageBox" @keyup.enter="onSendMessage()" />
                                            <button class="btn btn-primary input-group-text" type="button"
                                                @click="onSendMessage()">
                                                Отправить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ modalState.title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{ modalState.text }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="modalState.secondaryActionCallback">{{
                        modalState.secondaryActionText }}</button>
                    <button type="button" class="btn btn-primary" @click="modalState.primaryActionCallback">{{
                        modalState.primaryActionText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
//#region Imports
// Libraries
import _ from 'lodash';
import moment from 'moment';
import { io } from 'socket.io-client';

// Frameworks
import { onMounted, reactive, ref } from 'vue';
import { Modal } from 'bootstrap';

// API bindings
import UserAPI from '../../services/UserDataService';
import SupportAPI from '../../services/UserSupportDataService';
//#endregion

//#region Client
// Modal window state
const modalState = reactive({
    obj: null,
    title: '',
    text: '',
    primaryActionText: '',
    primaryActionCallback: () => { },
    secondaryActionText: '',
    secondaryActionCallback: () => { }
});

// Message box state
const messageBox = ref('');

// Trigger modal with certain content and action callbacks
function triggerModal(title, text, primaryActionText, primaryActionCallback, secondaryActionText, secondaryActionCallback) {
    modalState.title = title;
    modalState.text = text;
    modalState.primaryActionText = primaryActionText;
    modalState.primaryActionCallback = primaryActionCallback;
    modalState.secondaryActionText = secondaryActionText;
    modalState.secondaryActionCallback = secondaryActionCallback;
    modalState.obj.show();
}

// Scroll message list to bottom
function scrollToBottom() {
    const msgListEl = document.getElementById('chatInput');
    msgListEl.scrollTop = msgListEl.scrollHeight;
}

// Handler of message sending event
async function onSendMessage() {
    await SupportAPI.sendMessage(messageBox.value);
    socket.emit('message', {
        userId: profile.value.user.id,
        chatId: chat.value.id,
        message: messageBox.value,
    });
    await loadChat();
    messageBox.value = '';
}
//#endregion

//#region Network
const profile = ref({});
const chat = ref({});
const socket = io(process.env.VUE_APP_BACKEND_API_BASE);

async function createChat() {
    try {
        await SupportAPI.create();
    } catch (error) {
        switch (error.response.status) {
            case 403:
                break;

            case 404:
                triggerModal(
                    'Нет сотрудников поддержки онлайн',
                    'На данный момент нет сотрудников поддержки в сети. Попробуйте снова или вернитесь сюда позже.',
                    'Попробовать снова',
                    () => {
                        modalState.obj.hide();
                        setTimeout(async () => {
                            await createChat();
                        }, 500);
                    },
                    'Вернуться назад',
                    () => {
                        history.back();
                    }
                )
                break;

            case 409:
                break;

            default:
                triggerModal(
                    'Ошибка при запросе к серверу',
                    `Возникла ошибка при запросе к серверу. Попробуйте снова или обратитесь к технической поддержке.`,
                    'Попробовать снова',
                    () => {
                        modalState.obj.hide();
                        setTimeout(async () => {
                            await createChat();
                        }, 500);
                    },
                    'Вернуться назад',
                    () => {
                        history.back();
                    }
                )
                break;
        }
    }
}

async function loadChat() {
    // Load support chat info
    const res = await SupportAPI.getChat();
    chat.value = res.data;
    setTimeout(() => {
        scrollToBottom();
    }, 50);
}

async function markChatAsCompleted() {
    await SupportAPI.markAsCompleted();
    await loadChat();
}
//#endregion

onMounted(async () => {
    // Init modal
    modalState.obj = new Modal(document.getElementById('exampleModal'), {
        backdrop: true,
        focus: true,
        keyboard: true
    });

    // Load user info
    profile.value = (await UserAPI.get()).data;

    // Load chat
    try {
        await loadChat();
    } catch (error) {
        switch (error.response.status) {
            case 404:
                await createChat();
                break;

            default:
                console.error(`Failed to fetch chat: ${error}`);
                break;
        }
    }

    socket.emit('join_room', {
        userId: profile.value.user.id,
        chatId: chat.value.id
    });

    socket.on('message', async () => {
        console.log("RELOAD TRIGGERED")
        await loadChat();
    })
});
</script>