<template>
  <div class="wrapper-graph">
    <div class="card widget-hover m-0" >
      <div class="card-body radial-progress-card">
        <div class="d-flex flex-column">
          <h5 class="mb-0">ЛИЧНАЯ АКТИВНОСТЬ</h5>
          <div v-if="isAutoPlay && accountActivityValueEndDate">
            <span class="f-12 f-light f-w-500">
              Следующее списание
            </span>
            <h5 class="font-primary mb-0">
              {{ formatDate(accountActivityValueEndDate) }}
            </h5>
          </div>
          <div v-else class="d-flex flex-column">
            <div class="sale-details" v-if=" accountActivityValueEndDate">
            <span class="f-12 f-light f-w-500">
              Следующая оплата
            </span>
              <h5 class="font-primary mb-0">
                {{ formatDate(accountActivityValueEndDate) }}
              </h5>
            </div>
            <span class="f-12 f-light f-w-500 mt-2">

            Подключите Автоплатеж, чтобы <br>
            не беспокоиться о своих ежемесячных платежах!

          </span>
            <router-link :to="'/users/settings'" class="btn btn-outline-primary btn-learn-more mt-2">
              Подробнее
            </router-link>
          </div>
        </div>
        <div class="radial-chart-wrap">
          <apexchart
            type="radialBar"
            v-if="!cryptoDashboard.isLoading"
            :options="cryptoDashboard?.options"
            :series="cryptoDashboard?.series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cryptoDashboard } from "@/data/comon";
import userDataService from "@/services/UserDataService";
export default {
  props: {
    accountActivityValueEndDate: {
      type: String
    }
  },
  data() {
    return {
      isAutoPlay: null,
      cryptoDashboard: cryptoDashboard,
    };
  },
  mounted() {

    userDataService.getStatusAutoPlay().then((response) => {
      this.isAutoPlay = response.data
    })

    },
  methods: {

    formatDate(input) {
      const months = [
        "Январь", "Февраль", "Март", "Апрель", "Май",
        "Июнь", "Июль", "Август", "Сентябрь", "Октябрь",
        "Ноябрь", "Декабрь"
      ];

      // Разделим строку на дату и время
      let [datePart, timePart] = input.split(' ');

      // Удалим 'г' из даты
      datePart = datePart.replace('г', '').trim();

      // Разделим дату на день, месяц и год
      let [day, month, year] = datePart.split('.').map(Number);

      // Получим название месяца
      let monthName = months[month - 1];

      // Дефолтовое значение для времени
      let timeString = '';

      if (timePart) {
        // Разделим время на часы и минуты
        let [hours, minutes] = timePart.split(':').map(Number);
        timeString = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
      }

      // Форматируем дату
      return `${monthName} ${day}, ${year} ${timeString}`;
    }
  }
};
</script>
