<template>
  <Breadcrumbs title="Заявление о зачёте"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 m-auto">
        <div class="card">
          <alert-error v-if="errorMessage" :text="errorMessage"/>
          <alert-success v-if="successMessage" :text="successMessage"/>

          <div class="card-body">
            <p class="text-end">Генеральному директору <br/>
              Общества с ограниченной ответственности <br/>
              «Рантье Финанс Групп»
            </p>
            <h3 class="text-center">Уведомление о совершении цессии</h3>
            <p class="text-end">{{ date }}</p>
            <p>
              Уведомляю Вас, что между мной и {{ userRecipient.profile?.lastName }} {{ userRecipient.profile?.firstName }} {{ userRecipient.profile?.middleName }} ({{userRecipient.email}}) состоялся договор уступки права требования по обязательствам Компании передо мной в эквиваленте {{amount}} рублей.
            </p>

            <hr />
            <p class="mt-3">Гражданин(ка) РФ</p>
            <p>
              <span class="fw-bold">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }}</span> <br/>
              Эл. почта:{{ user.user?.email }} <br/>
              Телефон: {{ user.phone }} <br><br/>
              <strong>Паспорт РФ</strong> <br/>
              <span v-if="user.passportNumber">
                Серия и номер: {{ user.passportNumber }} <br/>
                  выдан {{ new Date(user.passportDate).toLocaleDateString('ru-Ru') }} <br/>
             </span>
              <span v-if="user.passportIssued">{{ user.passportIssued }} <br/></span>
              <span v-if="user.passportCode">Код подразделения: {{ user.passportCode }}<br/></span>
              <span v-if="user.passportAddress">Адрес регистрации: {{ user.passportAddress }}<br/></span>
              <span v-if="user.inn">ИНН: {{ user.inn }}<br/></span>
              <span v-if="user.snils">СНИЛС: {{ user.snils }}<br/></span>
            </p>
            <p class="fw-bold">Подписано путём ввода одноразового пароля</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";

export default {
  data() {
    return {
      date: new Date().toLocaleDateString('ru-Ru'),
      errorMessage: '',
      successMessage: '',
      amount: 0,
      user: {},
      userRecipient: {},

    };
  },
  components: {
    AlertSuccess,
    AlertError
  },
  mounted() {
    this.amount = JSON.parse(localStorage.getItem('amount'));
    this.user = JSON.parse(localStorage.getItem('userSender'));
    this.userRecipient = JSON.parse(localStorage.getItem('userRecipient'));
  },
};
</script>