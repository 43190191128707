<template>
  <div>
    <Breadcrumbs title="Учредители"/>
    <div class="card wrapper-founders">
      <alert-error v-if="isErrorAlert" :text="isErrorAlertMessage" />
      <div class="card-header card-no-border d-flex align-items-center justify-content-between">
        <div class="row h-100 w-100">
          <div class="col-12 col-md-12 col-xl-12 col-xxl-12 col-xxxl-8 d-flex">
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12 col-xxl-4 d-flex align-items-center justify-content-start">
                <h5>Cтатистика продаж</h5>
              </div>
              <div class="col-12 col-md-12 col-xl-12 col-xxl-8 d-flex flex-column align-items-start justify-content-center my-3">
                <div class="date-input-container d-flex align-items-center justify-content-center">
                  <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
                    <li class="profile-nav dropdown w-100 pe-0 py-0 d-flex align-items-center justify-content-center me-0 me-md-3 ms-0 ms-md-3">

                      <label for="datetime" class="drop-down-btn placeholder-label m-0 w-100">
                        {{ selectedDateStart ? selectedDateStart : 'Начало периода' }}
                        <img src="@/assets/images/network/Arrow.svg" alt="">
                      </label>
                      <ul class="profile-dropdown show-div p-0 w-100">
                        <input
                            id="datetime"
                            class="form-control"
                            type="date"
                            v-model="selectedDateStart"
                            @focus="showPickerStart = true"
                            @blur="showPickerStart = false"
                        />
                      </ul>
                    </li>
                    <li class="profile-nav dropdown w-100 pe-0 py-0 d-flex align-items-center justify-content-center me-0 me-md-3 mt-3 mt-md-0">

                      <label for="datetime" class="drop-down-btn placeholder-label m-0 w-100">
                        {{ selectedDateEnd ? selectedDateEnd : 'Окончание периода' }}
                        <img src="@/assets/images/network/Arrow.svg" alt="">
                      </label>
                      <ul class="profile-dropdown show-div p-0 w-100">
                        <input
                            id="datetime"
                            class="form-control"
                            type="date"
                            v-model="selectedDateEnd"
                            @focus="showPickerEnd = true"
                            @blur="showPickerEnd = false"
                        />
                      </ul>
                    </li>
                  </div>
                  <div class="w-100 h-100 d-flex align-items-end justify-content-center">
                    <div class="btn btn-primary mx-3 mx-md-0 fs-6" @click="dateSearch">
                      Сравнить
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--          <div class="dropdown" @click="toggleDropdown">-->
            <!--            <div class="dropdown-buttons">-->
            <!--              <div type="button" class="btn btn-outline-primary">Действие</div>-->
            <!--            </div>-->
            <!--            <ul v-if="isOpen" class="dropdown-menu">-->
            <!--              <li v-for="item in items" :key="item" @click="selectItem(item)">-->
            <!--                {{ item }}-->
            <!--              </li>-->
            <!--            </ul>-->
            <!--          </div>-->
          </div>
          <ul class="col-12 col-md-12 col-xl-12 col-xxl-12 col-xxxl-4 balance-data position-relative d-flex align-items-center justify-content-end">
            <li>
                        <span class="circle bg-secondary"></span
                        ><span class="f-light ms-1">Номер 1</span>
            </li>
            <li>
                        <span class="circle bg-primary"> </span
                        ><span class="f-light ms-1">Номер 2</span>
            </li>
            <li>
                        <span class="circle bg-success"> </span
                        ><span class="f-light ms-1">Номер 3</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="row m-0 overview-card">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 p-0 my-3">
            <div v-if="!isLoading && !isError" class="chart-right">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card-body p-0">
                    <div class="current-sale-container order-container">
                      <div class="overview-wrapper">
                        <apexchart
                            :options="ecomDashboard.options"
                            :series="ecomDashboard.series"
                            height="265"
                            type="line"
                        ></apexchart>
                      </div>
                      <div class="back-bar-container">
                        <apexchart
                            :options="ecomDashboard.options1"
                            :series="ecomDashboard.series1"
                            height="195"
                            type="bar"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isError" class="d-flex justify-content-center align-items-center w-100 h-100">
              Что-то пошло не так...
            </div>
            <div v-else class="d-flex justify-content-center align-items-center w-100 h-100">
              <div class="loader-cover"></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 p-0 my-3">
            <div class="row g-sm-3 g-2 table-wrapper">
              <div class="col-md-12">
                <table class="table table-hover table-container">
                  <thead>
                  <tr>
                    <th scope="col">Продажа</th>
                    <th scope="col">Внутренний счет</th>
                    <th scope="col">Внешний счет</th>
                    <th scope="col">Сумма</th>
                  </tr>
                  </thead>
                  <tbody v-if="!isLoading && !isError">
                    <tr class="my-3">
                      <td class="light-container">
                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column w-100">
                          <span>
                            <b>Абонентская Плата</b>
                          </span>
                          <span>Номер 1</span>
                        </div>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentSubscribe.amountAccount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentSubscribe.amount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentSubscribe.total} ₽` : "Данных нет") }}</b>
                      </td>
                    </tr>
                    <tr class="">
                      <td class="light-container">
                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column w-100">
                          <span>
                            <b>Покупка Курсов</b>
                          </span>
                          <span>Номер 2</span>
                        </div>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCourse.amountAccount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCourse.amount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCourse.total} ₽` : "Данных нет") }}</b>
                      </td>
                    </tr>
                    <tr class="">
                      <td class="light-container">
                        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column w-100">
                          <span>
                            <b>Сертификаты</b>
                          </span>
                          <span>Номер 3</span>
                        </div>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCertificate.amountAccount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCertificate.amount} ₽` : "Данных нет") }}</b>
                      </td>
                      <td class="light-container">
                        <b>{{ format(dataTable ? `${dataTable.paymentCertificate.total} ₽` : "Данных нет") }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row g-sm-3 g-2 mt-0 mt-md-2">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 items-payment">
                <div class="wrapper-item-graph light-container p-3 d-flex justify-content-between">
                  <div class="wrapper-text">
                    <div class="title-sum title-table">
                      <b>
                        Сумма всех продаж
                      </b>
                    </div>
                    <div class="price-sum">
                      <h4>
                        {{ format(dataTable ? `${parseInt(dataTable.allPaymentsSum).toFixed(0)} ₽` : "Данных нет") }}
                      </h4>
                    </div>
                  </div>
                  <div class="wrapper-icon">
                    <img src="@/assets/images/founders/frame528.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 items-payment">
                <div class="wrapper-item-graph light-container p-3 d-flex justify-content-between">
                  <div class="wrapper-text">
                    <div class="title-sum title-table">
                      <b>
                        Вывод
                      </b>
                    </div>
                    <div class="price-sum">
                      <h4>
                        {{ format(dataTable ? `${parseInt(dataTable.allWithdrawalRequestsSum).toFixed(0)} ₽` : "Данных нет") }}
                      </h4>
                    </div>
                  </div>
                  <div class="wrapper-icon">
                    <img src="@/assets/images/founders/frame529.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 items-payment">
                <div class="wrapper-item-graph light-container p-3 d-flex justify-content-between">
                  <div class="wrapper-text">
                    <div class="title-sum title-table">
                      <b>
                        Общий итог
                      </b>
                    </div>
                    <div class="price-sum">
                      <h4>
                        {{ format(dataTable ? `${parseInt(dataTable.total).toFixed(0)} ₽` : "Данных нет") }}
                      </h4>
                    </div>
                  </div>
                  <div class="wrapper-icon">
                    <img src="@/assets/images/founders/frame530.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !isError" class="card card-user-statistics">
      <OverallBalanceCard :overallbalance="overallbalance" :overallbalanceTotal="overallbalanceTotal"/>
    </div>
    <div v-else-if="isError" class="card card-user-statistics p-5 d-flex justify-content-center align-items-center w-100 h-100">
      <div>
        Что-то пошло не так...
      </div>
    </div>
    <div v-else class="card card-user-statistics d-flex p-5 justify-content-center align-items-center w-100 h-100">
      <div class="loader-cover"></div>
    </div>
  </div>
</template>
<script>

import OverallBalanceCard from "@/pages/founders/overallBalanceCard.vue";
import { fetchOverAllBalance, overallbalance, overallbalanceTotal } from "@/data/settings"
import { ecomDashboard, fetchData } from "@/data/faunders"
import alertError from "@/components/alert-error.vue";


export default {
  data() {
    return {
      ecomDashboard: ecomDashboard,
      dataTable: null,
      selectedDateStart: null,
      selectedDateEnd: null,
      showPickerStart: false,
      showPickerEnd: false,
      isLoading: ecomDashboard.isLoading || null,
      isError: ecomDashboard.isError || null,
      isErrorAlert: false,
      isErrorAlertMessage: '',
      overallbalance: overallbalance,
      overallbalanceTotal: overallbalanceTotal,
    };
  },
  components: {
    OverallBalanceCard,
    alertError,
  },

  mounted() {
    this.loadData();

  },

  methods: {

    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),

    async dateSearch() {
      if (!this.selectedDateStart || !this.selectedDateEnd){

        this.isErrorAlertMessage = 'Пожалуйста, выберите даты начала и окончания периода.'

        return this.isErrorAlert = true;

      } else {

        this.isErrorAlertMessage = ''

        this.isErrorAlert = false;

        this.loadData()

      }
    },

    async loadData() {

      this.isLoading = true;
      this.isError = false;

      const currentDate = new Date();
      const selectedDateStart = new Date(currentDate);
      selectedDateStart.setMonth(currentDate.getMonth() - 1);

      const formattedCurrentDate = currentDate.toISOString();
      const formattedSelectedDateStart = selectedDateStart.toISOString();

      try {

        if (!this.selectedDateStart && !this.selectedDateEnd){

          await fetchData(formattedSelectedDateStart, formattedCurrentDate);

          await fetchOverAllBalance();

        } else {

          const startDate = new Date(this.selectedDateStart).toISOString();
          const endDate = new Date(this.selectedDateEnd).toISOString();

          await fetchData(startDate, endDate);

          await fetchOverAllBalance();

        }

        this.dataTable = ecomDashboard.payments

      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        this.isError = true;
      } finally {

        this.isLoading = ecomDashboard.isLoading || false;
        this.isError = ecomDashboard.isError || false;
      }
    },

  },
  watch: {
    ecomDashboard: {
      handler(newVal) {
        this.isLoading = newVal.isLoading || false;
        this.isError = newVal.isError || false;
      },
      deep: true
    },
    overallbalance: {
      handler(newVal) {
        this.isLoading = newVal.isLoading || false;
        this.isError = newVal.isError || false;
      },
      deep: true
    },
    overallbalanceTotal: {
      handler(newVal) {
        this.isLoading = newVal.isLoading || false;
        this.isError = newVal.isError || false;
      },
      deep: true
    }
  }
};
</script>
<style scoped>

.svg-box{
 background: #FFA132;
}
.svg-icon-all-users{
  background: #54BA4A;
}
.title-table {
  color: #52526CBF;
}

.balance-data {
  right: 0;
  top: 0;
}

tr, .table thead th, .table > :not(caption) > * > * {
  border: none;
}

.table-container {
  border-spacing: 0 10px; /* Добавляем пространство между строками */
  border-collapse: separate;
}

.table-hover tbody tr {
  transition: background-color 0.2s ease;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.light-container {
  background: #F6F7F9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #3498db;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 999;
}

.dropdown-menu li {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.card-user-statistics{
  max-width: 1300px;
}


</style>
