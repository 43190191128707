import http from "../http-common";

export default {
    get() {
        return http.get('/subscription');
    },

    activate() {
        return http.post('/subscription/activate');
    },
}

