<template>
  <div class="wrapper-carousel-item">
    <img :src="require(`@/assets/images/carousel/Group${item_data.id}.png`)">
  </div>
</template>
<script>

export default {
  name: "carousel",
  props: {
    item_data: {
      type: Object,
      default: () => {}
    }
  },
  data() {

  }
}

</script>