<template>

    <div v-if="isCode">
        <div class="row">
            <div class="col-12 col-md-6">
                <input type="text" class="form-control" id="isCode" placeholder="Код" v-model="validate.code"
                    maxlength="4" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 4)">
            </div>
            <div class="col-12 col-md-6">
                <button class="btn btn btn-primary" type="button" @click="codeValidate"
                        :disabled="isBtnDisabled">
                  Подтвердить операцию
                </button>
            </div>
        </div>
        <p>Для подтверждения операции введите код. Он направлен на Вашу почту.</p>
    </div>

    <Teleport to="body">
        <StatusModal :type="modal.type" :title="modal.title" :description="modal.description" :open-event="modal.openEvent" />
    </Teleport>
</template>


<script>
import OperationsDataService from "@/services/OperationsDataService";
import MoneyDataService from "@/services/MoneyDataService";
import StatusModal from "@/components/status-modal.vue";
export default {
    props: {
        isActiveModal: {
          type: Boolean
        },
        validate: {
            id: Number,
            code: '',
        },
        isCode: {
            type: Boolean,
            required: true
        },
        isAmountAccount: {
            type: Boolean,
            required: true
        },
        idSystemPayment: {
            type: Number,
            required: true
        },
        paymentData: {
            type: Object,
            required: true
        },
        typePayment: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            modal: {
                type: 'success',
                title: '',
                description: '',
                openEvent: false,
            },
            isBtnDisabled: false
        }
    },
    methods: {
        codeValidate() {
            this.isBtnDisabled = true
            this.modal.type = 'waiting'
            this.modal.title = 'Операция в процессе выполнения'
            this.modal.description = 'Пожалуйста, подождите...'
            this.modal.openEvent = !this.modal.openEvent
            this.$emit('update:isActiveModal', !this.isActiveModal);
            OperationsDataService
                .codeValidate(this.validate)
                .then(response => {
                    const isValidate = response.data;
                    if (isValidate) this.choiceAcquiring();
                    else {
                        this.modal.type = 'error'
                        this.modal.title = 'Ошибка'
                        this.modal.description = 'Введён неверный код'
                        this.modal.openEvent = !this.modal.openEvent
                        this.isBtnDisabled = false
                    }
                })
                .catch((error) => {
                    let details;
                    if (typeof error.response.data.message == "string")
                        details = error.response.data.message
                    else if (typeof error.response.data.message == "object")
                        details = _.join(error.response.data.message, ', ');

                    this.modal.type = 'error'
                    this.modal.title = 'Ошибка при выполнении запроса'
                    this.modal.description = `
                    <details>
                        <summary>
                            Дополнительная информация об ошибке:
                            <br />
                        </summary>
                        <div
                            class="div p-3 mt-2"
                            style="background-color: #1c1c1c"
                        >
                            <code style="color: white">
                                ${details}<br />
                            </code>
                        </div>
                    </details>
                    `
                    this.modal.openEvent = !this.modal.openEvent
                })
        },
        makePaymentRedirect() {
            MoneyDataService.payment({
                ...this.paymentData,
                selectPaymentSystem: this.idSystemPayment,
                isAmountAccount: this.isAmountAccount,
            })
                .then((response) => {
                    if (this.isAmountAccount) {
                        this.modal.type = 'success'
                        this.modal.title = 'Операция выполнена успешно'
                        this.modal.description = 'Страница обновится через 3 секунды...'
                        this.modal.active = true
                        setTimeout(() => {
                            location.reload()
                        }, 3000)
                    }
                    else {
                        window.location.href = response.data.url;
                    }
                })
                .catch((error) => {
                    let details;
                    if (typeof error.response.data.message == "string")
                        details = error.response.data.message
                    else if (typeof error.response.data.message == "object")
                        details = _.join(error.response.data.message, ', ');

                    this.modal.type = 'error'
                    this.modal.title = 'Ошибка приобретения'
                    this.modal.description = `
                    <details>
                        <summary>
                            Дополнительная информация об ошибке:
                            <br />
                        </summary>
                        <div
                            class="div p-3 mt-2"
                            style="background-color: #1c1c1c"
                        >
                            <code style="color: white">
                                ${details}<br />
                            </code>
                        </div>
                    </details>
                    `
                    this.modal.openEvent = !this.modal.openEvent
                    this.isBtnDisabled = false
                    console.log(error);
                });
        },
        choiceAcquiring() {
            localStorage.setItem("paymentData", JSON.stringify(this.paymentData));

            if (this.isAmountAccount) this.makePaymentRedirect();
            if (this.idSystemPayment === 0 && !this.isAmountAccount)
                window.location.href = "/my/payments/cloudPayments";
            if (this.idSystemPayment === 1 && !this.isAmountAccount)
                this.makePaymentRedirect();
        },
    }
}

</script>