<template>
  <Breadcrumbs title="УСЛУГИ МУНИЦИПАЛИТЕТАМ"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <p> Услуги муниципалитетам  — один из уникальных продуктов Компании по расчёту капиталов городов для представителей муниципалитетов.
              <br/>
              Описание услуги:
              <a href="https://rentier.world/citycapital.html"
                 target="_blank">https://rentier.world/citycapital.html</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
  },

};
</script>