import http from "../http-common"

export default {
    create() {
        return http.post('/chatSupport')
    },

    getChat() {
        return http.get('/chatSupport/chat')
    },

    sendMessage(message) {
        return http.post('/chatSupport/sendMessage', {
            message
        })
    },

    markAsCompleted() {
        return http.put('/chatSupport/completeQuestion')
    }
}