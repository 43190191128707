<template>
  <div>
    <Breadcrumbs title="РЕКОМЕНДОВАТЬ" />
    <h3>Все рекомендации клиентов</h3>
    <div class="admin_form row">
      <div class="col-xl-3 xl-30 box-col-12">
        <div class="email-sidebar md-sidebar shadow-lg">
          <div
            class="email-left-aside md-sidebar-aside"
            :class="filtered ? 'open' : ''"
          >
            <div class="card">
              <div class="card-body">
                <div class="email-app-sidebar left-bookmark custom-scrollbar">
                  <ul class="nav main-menu">
                    <li
                      v-for="(item, index) in dataApi"
                      :key="index"
                      class="nav-item"
                      :class="{ 'nav-item-open': openIndex === index }"
                      @click="toggleOpen(index)"
                    >
                      <a
                        @click="getServicesFromAdmin(item.id)"
                        href="javascript:void(0)"
                      >
                        <span class="iconbg badge-light-primary"></span>
                        <span class="title">{{ item.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-9 xl-70 box-col-12">
        <div class="card shadow-lg card-container">
          <div class="card-body">
            <div class="todo">
              <div class="todo-list-wrapper">
                <div class="todo-list-container">
                  <div class="todo-list-cont">
                    <ul id="todo-list">
                      <li
                          v-if="statements.length > 0"
                        v-for="(item, index) in statements"
                        :key="index"
                        class="todo-item"
                      >
                        <router-link :to="'/itService/more/' + item.id">
                          <span class="title">{{ item.user.email }}</span>
                          <div>
                            <span class="name">{{ item.status.name }}</span>
                            <span class="time">
                              {{
                                new Date(item.updated_at).toLocaleDateString(
                                  "ru-Ru"
                                )
                              }}
                            </span>
                          </div>
                        </router-link>
                      </li>
                      <div class="text-center" v-else>
                        Пусто
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <ul
                v-if="pages > 1"
                class="pagination pagination-primary d-flex justify-content-center align-items-center my-3 cursor-pointer"
            >
              <li class="page-item">
                <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Previous"
                    @click="
                    getServicesFromAdmin( this.status,activePage - 1)
                  "
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li v-for="pageIndex in pages" :key="pageIndex" class="page-item">
                <a
                    class="page-link"
                    :class="{ 'active': pageIndex === activePage}"
                    @click="
                    getServicesFromAdmin(this.status,pageIndex)
                  "
                >{{ pageIndex }}</a
                >
              </li>

              <li class="page-item">
                <a
                    class="page-link"
                    href="javascript:void(0)"
                    aria-label="Next"
                    @click="
                    getServicesFromAdmin( this.status,activePage + 1)
                  "
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only"></span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceITDataService from "@/services/ServiceITDataService";

export default {
  data() {
    return {
      activePage: 1,
      pages: null,
      pageSize: 10,
      status: 1,
      dataApi: [],
      statements: [],
      openIndex: null,
    };
  },
  mounted() {
    this.getServices();
    this.getServicesFromAdmin();
  },
  methods: {
    getServices() {
      ServiceITDataService.getServices()
        .then(({ data }) => {
          this.dataApi = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getServicesFromAdmin(id, activePage) {

      if ((activePage > 0 && activePage <= this.pages) || activePage === undefined) {

        if (id) this.status = id;

        if (activePage) this.activePage = activePage;

        ServiceITDataService.getServicesFromAdmin(this.pageSize, this.status, activePage)
            .then(({ data }) => {

              this.statements = data.recommendationStatements;
              this.pages = data.countRecommendationStatements;

            })
            .catch((e) => {
              if (e.response.status === 404){
                this.statements = []
                this.pages = 0
              } else {

                console.log(e);
              }
            });

      } else {
        console.log(activePage)
      }
    },
    toggleOpen(index) {
      if (this.openIndex === index) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
  },
};
</script>

<style scoped>
.page-link.active{
  background: #ffffff;
  border: 1px solid #7366ff;
}
h3 {
  padding: 30px;
}
.ctaservice_user {
  display: flex;
  justify-content: center;
}
.todolist {
  width: 100%;
  background: #7366ff;
  color: white;
}
.main-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
  border-top: 1px solid rgba(82, 82, 108, 0.8);
}
.nav-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0;
}
.time {
  margin-left: 10px;
}
.nav-item::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 35px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #7366ff;
  border-radius: 5px 0px 0px 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.nav-item-open::after {
  opacity: 1;
  visibility: visible;
}
.nav-item .title {
  color: rgba(82, 82, 108, 0.8);
}
.todo-item {
  padding: 10px auto;
  width: 100%;
}
.todo-item a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.todo-item a {
  color: rgba(82, 82, 108, 0.8);
}
.nav-item-open .title {
  color: #7366ff;
}
.user-wrapper {
  margin-bottom: 10px;
}
.pagination-primary {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>