import http from "../http-common";


export default {
    getThread() {
        return http.get('/forums/types/thread');
    },
    getRequest() {
        return http.get('/forums/types/request');
    },
    getForums(pageSize, page) {
        return http.get('/forums', {
            params: {
                pageSize: pageSize,
                page: page
            }
        });
    },
    getForumById(id) {
        return http.get(`/forums/${id}`);
    },
    postForum(forums) {
        return http.post('/forums', forums);
    },
    postSendMessagesForum(id, data) {
        return http.post(`/forums/${id}/user/send`, data)
      },
    postAdminSendMessagesForum(id, data) {
        return http.post(`/forums/${id}/support/send`, data)
    },
}
