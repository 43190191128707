<template>
  <a href="https://rentier.world/">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logos.svg"
      alt=""
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/logos_dark.svg"
      alt=""
    />
  </a>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
