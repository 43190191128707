<template>
    <div>
        <Breadcrumbs title="ОБРАЩЕНИЕ ЗА ПОМОЩЬЮ" />
        <div class="wrapper-table col-md-12" >
            <div class="card">
                <div class="card-header card-header">
                    <h4 class="card-title mb-0">Все обращения</h4>
                    <router-link class="btn btn-primary" to='/forum/user/list/add'>
                        Создать обращение
                    </router-link>
                </div>
                <div class="table-responsive add-project" >
                    <table class="table table-desktop card-table table-vcenter text-nowrap" >
                        <thead>
                            <tr>
                                <th>Тема</th>
                                <th>Последнее сообщение</th>
                                <th>Статус</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-if="Forums.forums && Forums.forums.length > 0" v-for="(item, index) in Forums.forums" :key="item.id" @click="openChat(item.id)">
                          <td>{{ item.title }}</td>
                          <td>{{ item.messages[0].message }}</td>
                          <td>{{ item.status.name }}</td>
                        </tr>
                        <tr v-else>
                          <td colspan="3">У вас нет обращений</td>
                        </tr>
                        </tbody>
                    </table>

                  <div class="accordion accordion-flush accordion-mobile" id="accordionFlushExample">
                    <div class="accordion-item" v-for="(item, index) in Forums.forums" :key="item.id">
                      <h2 class="accordion-header" :id="'flush-heading' + item.id">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse' + item.id" aria-expanded="false" :aria-controls="'flush-collapse' + item.id">
                          {{item.title}}
                        </button>
                      </h2>
                      <div :id="'flush-collapse' + item.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + item.id" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <div>Последнее сообщение: {{ item.messages[0].message }}</div>
                          <div>Статус: {{ item.status.name }}</div>
                          <router-link :to="'/forum/chat/' + item.id" class="btn btn-primary">Открыть</router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body pagination-padding d-flex justify-content-around">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination pagination-primary">
                        <li class="page-item">
                          <a class="page-link" @click="prevPage">Назад</a>
                        </li>
                        <li class="page-item" v-for="pageNumber in countPage" :key="pageNumber">
                          <a class="page-link" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
                        </li>
                        <li class="page-item" >
                          <a class="page-link" @click="nextPage">Вперед</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ForumDataService from "@/services/ForumDataService"
import moment from "moment";
import "moment/locale/ru";
export default {

    data() {
        return {
            typesThread: [],
            typesRequest: [],
            requestType: [],
            Forums: {},
            page: 0,
            pageSize: 15,
            countPage: Number,
            countPagination: Number,
            title: "",
            countForums: Number,
            forum: {
                requestType: Number,
                threadType: Number,
                title: "",
                message: "",
            }
        };
    },
    mounted() {
      this.getForums()
      this.$nextTick(() => {
        const accordionItems = this.$el.querySelectorAll('.accordion-item');
        accordionItems.forEach((item) => {
          const button = item.querySelector('.accordion-button');
          const collapse = item.querySelector('.accordion-collapse');
          button.addEventListener('click', () => {
            collapse.classList.toggle('show');
          });
        });
      });
    },

    methods: {

      async getForums() {
        try {
          const response = await ForumDataService.getForums(this.pageSize, this.page);
          this.Forums = response.data;
          this.countForums = this.Forums.countForums;
          this.countPagination = this.countForums / this.pageSize - 1;
          if (this.countForums > 0) {
            this.countPagination = Math.ceil(this.countForums / this.pageSize) - 1;
            this.countPage = Math.ceil(this.countForums / this.pageSize);
          } else {
            this.countPagination = 0;
            this.countPage = 1;
          }
        } catch (error) {
        }
      },
      prevPage() {
        if (this.page >= 1) {
          this.page -= 1;
          this.getForums();
        }
      },
      nextPage() {
        if (this.page < this.countPage - 1) {
          this.page += 1;
          this.getForums();
        }
      },
      goToPage(pageNumber) {
        this.page = pageNumber - 1;
        this.getForums();
      },
      openChat(id){
        this.$router.push('/forum/chat/' + id);
      },
    },
}


</script>

<style scoped>
.accordion-body{
  display:flex;
  align-items: center;
  justify-content: space-around;
}
.accordion-mobile{
  display:none;
}
@media screen and (max-width: 1200px){
  .table-desktop{
    display:none;
  }
  .accordion-mobile{
    display:block;
  }
}

.page-item{
  cursor: pointer;
}
.pagination-padding{
  max-width: 100%;
}

.card-header {
    display: flex;
    justify-content: space-between;
}
</style>