<template>
  <Breadcrumbs title="foobar" />

  <div class="row">
    <div class="col-lg-5">
      <swiper-container navigation="true" pagination="true" @swiperinit="onSwiperInit">
        <swiper-slide class="ratio ratio-1x1">
          <img
            :src="`${backendURL}/${content.project?.preview}`"
            class="img-fluid"
            alt="..."
            style="object-fit: cover"
          />
        </swiper-slide>
        <swiper-slide class="ratio ratio-1x1" lazy="true" v-for="image of content.project?.images">
          <img
            :src="`${backendURL}/${image.path}`"
            class="img-fluid"
            alt="..."
            style="object-fit: cover"
            loading="lazy"
          />
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="col-lg-7">
      <div class="card">
        <div class="card-body">
          <h1>{{ content.project?.name }}</h1>
          <div class="product-price">
            <!-- <span class="text-muted"
              >Собрано {{ content.project?.fundraisingVolume }} /
            </span> -->
            {{ content.project?.price }} &#8381;
          </div>
          <table class="table">
            <tr v-if="content.project?.author">
              <th scope="col">Автор</th>
              <td>{{ content.project?.author }}</td>
            </tr>
            <tr>
              <th scope="col">Город</th>
              <td>{{ content.project?.city }}</td>
            </tr>
            <tr>
              <th scope="col">Вид проекта</th>
              <td>{{ content.project?.category.name }}</td>
            </tr>
          </table>
          <div class="row g-1 g-sm-2 g-lg-5 pt-3 px-2">
            <div class="col-lg-2">
              <div class="d-flex justify-content-start">
                <div class="align-self-center">
                  <strong>Рейтинг</strong>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="d-flex">
                <button
                  class="btn flex-fill"
                  :class="
                    !content.infoAboutUserVoice?.perProject
                      ? 'btn-primary'
                      : 'btn-tertiary'
                  "
                  :disabled="content.infoAboutUserVoice != null"
                  @click="onPositiveVote()"
                >
                  <i class="bi bi-hand-thumbs-up-fill me-1"></i>
                  {{
                    content.voicesProject
                      ? content.voicesProject.percentPerProject
                      : 0
                  }}% За
                </button>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="d-flex">
                <button
                  class="btn flex-fill"
                  :class="
                    !content.infoAboutUserVoice?.againstProject
                      ? 'btn-danger'
                      : 'btn-tertiary'
                  "
                  :disabled="content.infoAboutUserVoice != null"
                  @click="onNegativeVote()"
                >
                  <i class="bi bi-hand-thumbs-down-fill me-1"></i>
                  {{
                    content.voicesProject
                      ? content.voicesProject.percentAgainstProject
                      : 0
                  }}% Против
                </button>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="d-flex">
                <button
                  class="btn flex-fill"
                  :class="
                    !content.infoAboutUserVoice?.supportFinancially
                      ? 'btn-success'
                      : 'btn-tertiary'
                  "
                  :disabled="content.infoAboutUserVoice != null"
                  @click="onSupportVote()"
                >
                  <i class="bi bi-cash-stack me-1"></i>
                  {{
                    content.voicesProject
                      ? content.voicesProject.percentSupportFinancially
                      : 0
                  }}% Готовы поддержать
                </button>
              </div>
            </div>
          </div>
          <div class="accordion p-0 mt-4">
            <div
              class="accordion-item"
              v-if="content.project?.documents.length != 0"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  :class="!documentsAccordion.isShow ? 'collapsed' : ''"
                  @click="documentsAccordion.obj.toggle()"
                  type="button"
                >
                  Документы
                </button>
              </h2>
              <div
                class="accordion-collapse collapse"
                :ref="
                  (el) => {
                    documentsAccordion.ref = el;
                  }
                "
              >
                <div class="accordion-body">
                  <template v-if="content.project?.documents.length != 0">
                    <template
                      v-for="(item, index) in content.project?.documents"
                    >
                      <div class="card border my-2 p-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-auto">
                              <i
                                class="bi bi-file-earmark"
                                style="font-size: xx-large"
                              ></i>
                            </div>
                            <div class="col">
                              <div class="d-flex h-100">
                                <div class="align-self-center">
                                  <a :href="`${backendURL}/${item.path}`">
                                    {{ item.rusNameFile }}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else> Документов нет </template>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  :class="!expertiseAccordion.isShow ? 'collapsed' : ''"
                  @click="expertiseAccordion.obj.toggle()"
                  type="button"
                >
                  Экспертиза
                </button>
              </h2>
              <div
                class="accordion-collapse collapse"
                :ref="
                  (el) => {
                    expertiseAccordion.ref = el;
                  }
                "
              >
                <div
                  class="accordion-body"
                  v-html="content.project?.expertise"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <p class="mt-2" v-html="content.project?.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import _ from "lodash";
import { reactive } from "vue";
import { onMounted, ref, computed } from "vue";
import { Collapse } from "bootstrap";
import ProjectsDataService from "@/services/ProjectsDataService";
import { useRoute } from "vue-router";
// Swiper
import { register } from 'swiper/element/bundle';
register();
//#endregion

// VERY DIRTY HACK, GET YOUR CHILDREN AWAY FROM COMPUTER
const urls = ref([
  "/my/files/projects/file1.pdf",
  "/my/files/projects/file2.mp4",
  "/my/files/projects/file3.pdf",
  "/my/files/projects/file4.pdf",
  "/my/files/projects/file5.pdf",
  "/my/files/projects/file6.pdf",
  "/my/files/projects/file7.pptx",
  "/my/files/projects/file8.pptx",
  "/my/files/projects/file9.pdf",
  "/my/files/projects/file10.pdf",
]);
// You can bring your children back

//#region Types
/**
 * @typedef {Object} ProjectFile
 * @property {Number} id
 * @property {String} path
 * @property {String} rusNameFile
 */

/**
 * @typedef {Object} Image
 * @property {Number} id
 * @property {String} path
 */

/**
 * @typedef {Object} Project
 * @property {Object} project
 * @property {Number} project.id
 * @property {String} project.preview
 * @property {String} project.name
 * @property {Object} project.category
 * @property {Number} project.category.id
 * @property {String} project.category.name
 * @property {String} project.description
 * @property {String} project.shortDescription
 * @property {Number} project.fundraisingVolume
 * @property {Number} project.price
 * @property {String} project.fundraisingStartDate
 * @property {String} project.fundraisingEndDate
 * @property {String} project.author
 * @property {String} project.city
 * @property {String} project.expertise
 * @property {Array<Image>} project.images
 * @property {Array<ProjectFile>} project.documents
 * @property {Array<String>} project.names
 * @property {Object} infoAboutUserVoice
 * @property {Number} infoAboutUserVoice.id
 * @property {Number} infoAboutUserVoice.perProject
 * @property {Number} infoAboutUserVoice.againstProject
 * @property {Number} infoAboutUserVoice.supportFinancially
 * @property {Object} voicesProject
 * @property {Number} voicesProject.percentPerProject
 * @property {Number} voicesProject.percentAgainstProject
 * @property {Number} voicesProject.percentSupportFinancially
 * @property {Object} project.status
 * @property {Number} project.status.id
 * @property {String} project.status.name
 * @property {String} project.status.engName
 */
//#endregion

//#region Client
/**
 * Content
 * @type {import('vue').Ref<Project>}
 */
const content = ref({
  id: 1,
  preview: "",
  name: "",
  shortDescription: "",
  description: "",
  fundraisingVolume: 1,
  price: 1,
  fundraisingStartDate: "",
  fundraisingEndDate: "",
  author: "",
  city: "",
  expertise: "",
  category: {
    id: 1,
    name: "",
  },
  images: [],
  documents: [],
  names: [],
  rating: {
    id: 1,
    perProject: 0,
    againstProject: 0,
    supportFinancially: 2,
  },
  status: {
    id: 1,
    name: "",
  },
});

const route = useRoute();

const backendURL = computed(() => process.env.VUE_APP_BACKEND_API_BASE);
//#endregion

//#region Accordion
const documentsAccordion = reactive({
  ref: ref(),
  obj: ref(),
  isShow: false,
});

const expertiseAccordion = reactive({
  ref: ref(),
  obj: ref(),
  isShow: false,
});

onMounted(() => {
  documentsAccordion.obj = new Collapse(documentsAccordion.ref, {
    toggle: false,
  });
  documentsAccordion.ref.addEventListener("hide.bs.collapse", () => {
    documentsAccordion.isShow = false;
  });
  documentsAccordion.ref.addEventListener("show.bs.collapse", () => {
    documentsAccordion.isShow = true;
  });

  expertiseAccordion.obj = new Collapse(expertiseAccordion.ref, {
    toggle: false,
  });
  expertiseAccordion.ref.addEventListener("hide.bs.collapse", () => {
    expertiseAccordion.isShow = false;
  });
  expertiseAccordion.ref.addEventListener("show.bs.collapse", () => {
    expertiseAccordion.isShow = true;
  });
});
//#endregion

//#region Swiper
const swiper = ref();

function onSwiperInit(event) {
  console.log(event)
  swiper.value = event.detail[0];
}

onMounted(() => {
  setTimeout(() => {
    swiper.value.update()
  }, 3000)
})
//#endregion

//#region Network
async function onPositiveVote() {
  await ProjectsDataService.voteForProject(route.params.id, 1, 0, 0);
  location.reload();
}
async function onNegativeVote() {
  await ProjectsDataService.voteForProject(route.params.id, 0, 1, 0);
  location.reload();
}
async function onSupportVote() {
  await ProjectsDataService.voteForProject(route.params.id, 0, 0, 1);
  location.reload();
}
//#endregion

//#region Hooks
onMounted(async () => {
  ProjectsDataService.getById(useRoute().params.id)
    .then((res) => {
      content.value = res.data;
    })
    .catch((err) => {
      console.error(err);
    });
});
//#endregion
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
</style>
