import http from "../http-common";

export default {
    postCreatureTest(body) {
        return http.post('/tests/administrators',body);
    },
    postCompleteTest(id, body){
        return http.post(`/tests/${id}/complete`, {
            questions: body
        })
    },
    getAllTests() {
        return http.get('/tests/administrators', {
        params: {
            column: "numberOfQuestions",
            orderBy: "DESC"
        }
        })
    },
    getShortDescrioptionCurrentTest(id) {
        return http.get(`/tests/${id}`);
    },
    getQuestionsForTest(id) {
        return http.get(`/tests/${id}/questions`)
    },
    getFullTest(id){
        return http.get(`/tests/${id}/administrators`)
    },
    getResultTest(id){
        return http.get(`/tests/${id}/results`)
    },
    putUpdateTest(body, id){
        return http.put(`/tests/${id}/administrators`, body)
    }
}

