<template>
  <div class="ctaservice_wrapper">
    <userService v-if="userRole.id===1"/>
    <adminService v-else/>
  </div>
</template>
<script>
import adminService from "./adminService.vue";
import userService from "./userService.vue";
import UserDataService from "@/services/UserDataService";
import ServiceITDataService from "@/services/ServiceITDataService.js";
export default {
    data(){
        return{
            userRole: {}
        }
    },  
  mounted() {
    this.getServices();
    this.getUser();
  },
  methods: {
    getUser() {
      UserDataService.get()
        .then(({ data }) => {
          this.userRole = data.user.role;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getServices() {
      ServiceITDataService.getServices()
        .then((response) => {
          const dataServ = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  components: {
    userService,
    adminService,
  },
};
</script>
<style scoped>
.ctaservice_wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>