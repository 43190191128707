<template>
  <div>
    <Breadcrumbs title="КУРСЫ"/>
    <div class="wrapper-courses">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5 class="card-header-upper">ДОСТУПНЫЕ КУРСЫ</h5>
              <div>
                <li class="onhover-dropdowns pe-0 py-0">
                  <vue-feather
                               class="status_toggle middle sidebar-toggle" type="help-circle"></vue-feather>
                  <ul class="onhover-show-divs">
                    <li class="onhover-header p-3">
                      Справка
                    </li>
                    <li class="p-3">
                      Каждый Пользователь после оплаты выбранного Курса получает доступ к приобретённым обучающим материалам.
                      <br class="my-2">
                      Если Пользователь имеет намерение получать вознаграждения за продвижение продуктов Компании, ему необходимо подписать (акцептовать) Партнёрский Договор (непубличную оферету).
                      <br class="my-2">
                      Компания производит начисление бонусов Партнёру на основании автоматически сформированного Акта оказанных услуг/выполненных работ.
                    </li>
                  </ul>
                </li>
              </div>

            </div>
            <div class="wrapper-courses">
              <div class="card-body-custom d-flex flex-column justify-content-between px-3 pt-3">
                <div v-if="!isLoading" class="d-flex flex-wrap justify-content-between px-1 pt-3 pb-5">
                  <div v-for="(item, index) in ranks" :key="index"
                       class="card-custom d-flex flex-column p-5 mx-2"
                       style="flex: 1 1 calc(33.333% - 1rem); display: flex;">
                    <h3 class="text-center">
                      {{ item.name }}
                    </h3>
                    <h4 class="text-purple text-center">
                      {{ formatPrice(item.price) }} ₽
                    </h4>
                    <div class="svg-container d-flex justify-content-center align-items-center my-3">
                      <svg>
                        <use class="w-100" :xlink:href="require('@/assets/scss/components/ranks/icon-ranks.svg') + `#${item.id}`"></use>
                      </svg>
                    </div>
                    <p :class="{ 'text-purple' : index !== 0 }" class="text-center text-center text-wrapper">
                      {{ description[index].text }}
                    </p>
                    <div class="wrapper-list flex-grow-1">
                      <p v-if="description[index].list">
                        <div v-for="(element, index) in description[index].list" :key="index" class="list-item d-flex my-3">
                          <div class="me-2">
                            <svg style="height: 24px; width: 24px;">
                              <use class="w-100" :xlink:href="require('@/assets/scss/components/ranks/icon-ranks.svg') + `#check-mark`"></use>
                            </svg>
                          </div>
                          <div>
                            <span>
                              {{ element }}
                            </span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div v-if="item.id <= rankActive" class="btn btn-disable m-auto">
                      Куплено
                    </div>

                    <div v-else-if="item.id === rankActive + 1" class="dropdown-wrapper position-relative m-auto" @click="toggleDropdown">
                      <div class="btn btn-primary text-center" >
                        Купить
                      </div>
                      <transition name="fade">
                        <div class="dropdown-content" v-if="isOpen">
                          <div class="btn" @click="switchModal(1)" >
                            Оплата за рубли
                          </div>
                          <div class="btn" @click="switchModal(2)" >
                            Оплата за 50% баллами
                          </div>
                        </div>
                      </transition>
                    </div>
                    <div v-else-if="item.id >= rankActive + 1" class="btn btn-disable m-auto">
                      Не доступна
                    </div>
                  </div>
                </div>

                <div v-else class="wrapper-loader w-100 d-flex justify-content-center align-items-center m-5">
                  <div class="loader-cover"></div>
                </div>
              </div>

            </div>
<!--            <alert-error v-if="errorMessage" :text="errorMessage"/>-->
<!--            <alert-success v-if="successMessage" :text="successMessage"/>-->

            <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
              <div class="modal-overlay" @click="switchModal"></div>
              <div v-if="isLoadingSend" class="modal-content d-flex justify-content-center p-5" >
                <div class="loader-cover m-auto"></div>
                </div>
              <div v-else-if="errorMessage" class="modal-content" >
                <div
                    class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
                  <img src="@/assets/svg/info-circle.svg" alt="">
                  <h4 class="title my-3" style="color: #444444">
                    Ошибка!
                  </h4>
                  <div class="subtitle text-center mb-3" style="color: #52526CBF">
                    {{ errorMessage }}
                  </div>
                  <div class="btn-wrapper text-end w-100">
                    <div class="btn btn-primary m-auto" @click="switchModal">
                      Ок
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="successMessage" class="modal-content" >
                <div
                    class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
                  <img src="@/assets/svg/Vector.svg" alt="">
                  <h4 class="title my-3" style="color: #444444">
                    Успех!
                  </h4>
                  <div class="subtitle text-center mb-3" style="color: #52526CBF">
                    {{ successMessage }}
                  </div>
                  <div class="btn-wrapper text-end w-100">
                    <div class="btn btn-primary m-auto" @click="switchModal">
                      Ок
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="hrefVerify" class="modal-content d-flex justify-content-center p-0" >
                <div class="modal-message">
                  <div class="modal-message-title modal-header d-flex justify-content-between align-items-center">
                    <h3 class="modal-title-text m-0">Подтверждение</h3>
                    <vue-feather @click="switchModal" type="x" class="cursor-pointer" ></vue-feather>
                  </div>
                </div>
                <div class="modal-body px-3 py-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <input type="text" class="form-control" placeholder="Код" v-model="tokenTelegram">
                    </div>
                    <div class="col-12 col-md-6">
                      <button class="btn btn btn-primary" type="button" @click="checkToken"
                              :disabled="isButtonDisabled">
                        Подтвердить операцию
                      </button>
                    </div>
                  </div>
                  <p class="mt-3">Для прохождения верификации введите код. Он направлен на Ваш Telegram.</p>
                </div>
              </div>
              <div v-else-if="this.isActivate && idSystemPayment < 3 && nextRank.name" class="modal-content" >
                <div class="modal-message">
                  <div class="modal-message-title modal-header d-flex justify-content-between align-items-center">
                    <h3 class="modal-title-text m-0">Подтверждение</h3>
                    <vue-feather @click="switchModal" type="x" class="cursor-pointer" ></vue-feather>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto m-auto my-3">
                    <div class="form-body">
                      <div class="form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="isAmountAccount"
                            v-model="isAmountAccount"
                            @change="checkBox(1)"
                            :disabled="isCode"
                        />
                        <label class="form-check-label" for="isAmountAccount"
                        >Зачесть в погашение 100% стоимости средства со
                          внутреннего счета.
                          <a
                              href="../documents/internal-credit"
                              target="_blank"
                              v-if="isAmountAccount"
                          >Заявление на зачет.</a
                          ></label
                        >
                      </div>
                      <div class="mt-2 form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="promo"
                            @change="checkBox(2)"
                            :disabled="isCode"
                            v-model="isPromo"
                        />
                        <label class="form-check-label" for="promo"
                        >У меня есть промокод</label
                        >
                      </div>
                      <div class="" style="min-width: 200px">
                        <input
                            type="text"
                            class="form-control"
                            @input="checkPromo"
                            v-if="isPromo"
                        />
                      </div>
                      <p
                          v-if="isPromo && isPromoCodeActivate === null"
                          class="mt-2 font-success"
                      >
                        Введите промокод. Проверьте чтобы в нем не было пробелов.
                      </p>
                      <p
                          class="alert alert-danger mt-2"
                          v-if="isPromo && isPromoCodeActivate === false"
                      >
                        Ваш промокод не действителен. Проверьте срок действия, и
                        что он действительно подходит для покупки данного курса.
                      </p>
                      <p
                          class="alert alert-success mt-2"
                          v-if="isPromo && isPromoCodeActivate"
                      >
                        Ваш промокод действителен. И предоставляет скидку 50% при
                        оплате текущего курса. Промокод активируется сразу при
                        нажатии на кнопку 'Купить курс'.
                      </p>
                    </div>
                    <div class="m-2">
                      <button
                          class="btn btn btn-primary m-auto w-100"
                          type="button"
                          v-if="!isCode"
                          @click="clickPayment"
                      >
                        Купить курс - {{ nextRank.name }} за
                        {{ nextRank.price }} рублей.
                      </button>
                    </div>
                    <div class="form-inline row mt-2" v-if="isCode">
                      <div class="row mobile-code d-flex">
                        <div class="col-5 mt-2">
                          <input
                              type="text"
                              class="form-control"
                              id="isCode"
                              placeholder="Код"
                              v-model="validate.code"
                          />
                        </div>
                        <button
                            class="btn btn btn-primary col mt-2"
                            type="button"
                            @click="codeValidate"
                        >
                          Подтвердить операцию
                        </button>
                      </div>
                      <p class="mt-3">
                        Для подтверждения операции введите код. Он направлен на
                        Вашу почту.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RanksDataService from "@/services/RanksDataService";
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import SubscriptionDataService from "@/services/SubscriptionDataService";
import UserDataService from "@/services/UserDataService";
import PromoCodeDataService from "@/services/PromoCodeDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      isOpen: false,
      userId: 0,
      hrefVerify: false,
      isActiveModal: false,
      isLoadingSend: false,
      rankActive: 1,
      isLoading: true,
      ranks: [],
      isActivate: false,
      nextRank: {id: 1},
      errorMessage: "",
      successMessage: "",
      user: {isGeneratePromoCode: false},
      promoCodes: [],
      promoCodesFilter: [],
      filterRank: "",
      isPromo: false,
      isAmountAccount: false,
      idSystemPayment: 0,
      isPromoCodeActivate: null,
      isCode: false,
      showCopiedMessage: [],
      tokenTelegram: '',
      description: [
        {
          text: 'Бонусы отсутствуют',
          list: [
              'Доступ к базовой информации по финансовой грамотности',
              'Введение в партнёрскую программу',
              'Возможность получить скидки',
              'Базовый доступ к учебным материалам для ознакомления'
          ]
        },
        {
          text: 'Курс обучения бизнесу',
          list: [
              'Доступ к первому модулю по финансовой грамотности',
              'Курс по бизнесу от Артема Николаева',
              'Доступ к 2-3 уровням партнерской программы',
              '4 накопительные программы: электроника, медицина/путешествия, автомобиль, недвижимость'
          ]
        },
        {
          text: '100 флаеров (приглашение в «Рантье» с QR-кодом пользователя)',
          list: [
            '100 флаеров с приглашением в бизнес за счёт компании',
            'Доступ к модулю "Профессионал" по фин. грамотности',
            'Доступ к 4-5 уровням партнёрской программы',
            'Оплата второго карьерного курса'
          ]
        },
        {
          text: 'Генератор промокодов (50% скидка на оплату курсов обучения)',
          list: [
            'Генератор промокодов на пакеты со скидкой 50% для подарков и монетизации',
            'Доступ к 6-7 уровням партнерской программы',
            'Курс по финансовой грамотности для консультантов'
          ]
        },
        {
          text: 'Участие в лидерском совете и проведение мероприятий с А. Николаевым',
          list: [
            'Право проводить мероприятие с соучредителями компании',
            'Участие в совете лидеров',
            'Бесплатное посещение ежегодных корпоративных мероприятий',
            'Доступ к 8-9 уровням карьеры',
            'Курс по финансовой грамотности "Эксперт"'
          ]
        },
        {
          text: 'Максимальный уровень ранга',
          list: [
            'Приобретая этот ранг - вы получается возможность всех бонусов Рантье',

          ]
        },
      ],
      validate: {
        id: Number,
        code: "",
      },
      paymentData: {
        name: "Оплата курса - ",
        summa: 0,
        typePayment: 2,
        isPromoCode: false,
        promoCode: "",
        isAmountAccount: false,
      },
      status: {
        ok: '<i class="font-success d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-success"></span>',
        current:
            '<i class="font-roboto d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="alert-circle" data-tags="warning,alert,danger" data-type="alert-circle" class="vue-feather vue-feather--alert-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle vue-feather__content"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></i></i><span class="font-roboto">Текущая</span>',
        next: '<i class="font-primary" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-primary">Доступна</span>',
        no: '<i class="font-danger" data-feather="alert-triangle"><i data-name="alert-triangle" data-tags="warning,alert,danger" data-type="alert-triangle" class="vue-feather vue-feather--alert-triangle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle vue-feather__content"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg></i></i><span class="font-danger">Не доступна</span>',
      },
    };
  },
  components: {
    AlertSuccess,
    alertError,
  },
  mounted() {
    this.getData();
  //   const scrollContainer = this.$refs.scrollContainer;
  //   console.debug(scrollContainer)
  //
  //   scrollContainer.addEventListener('wheel', this.handleWheelScroll);
  // },
  // beforeDestroy() {
  //   const scrollContainer = this.$refs.scrollContainer;
  //
  //   scrollContainer.removeEventListener('wheel', this.handleWheelScroll);
  },
  methods: {
    switchModal(idOperation){
      this.isActiveModal = !this.isActiveModal;

      if (!this.isActiveModal) {
        setTimeout(( ) => {
          this.errorMessage = ''
          this.successMessage = ''
          this.hrefVerify = false
          this.isLoadingSend = false
        }, 500)
      } else {
        this.errorMessage = ''
        this.successMessage = ''
        this.hrefVerify = false
        this.isLoadingSend = false
      }

      if (this.isActiveModal) {
        if (idOperation === 1) {

        }
        if (idOperation === 2) {

          this.isLoadingSend = true

          OperationsDataService.getPayRank()
              .then(() => {
                this.successMessage = 'Транзакция успешна! \nСтраница обновится через 3 секунды...'
                setTimeout(() => {
                  window.location.reload();
                }, 3000)
              })
              .catch((e) => {

                if (e.response.status === 403) {

                  this.errorMessage = e.response.data.message + ` Подождите 3 секунды ...`

                  setTimeout(( ) => {
                    this.sendTokenTelegram()
                    this.hrefVerify = true
                    this.errorMessage = ''
                  }, 3000)

                  return

                }

                this.errorMessage = e.response.data.message

                console.log(e)
              })
              .finally(() => {
                this.isLoadingSend = false;
              })
        }
      }
    },
    async sendTokenTelegram() {
      OperationsDataService.sendTokenTelegram()
          .then(() => {})
          .catch((e) => {
            this.errorMessage = e.response.data.message
            this.successMessage = ''

            console.log(e)
          })
          .finally (() => {
            this.isLoadingSend = false
          })
    },
    async checkToken() {
      this.isLoadingSend = true

      OperationsDataService.getCheckedToken(this.tokenTelegram)
          .then((response) => {

            this.successMessage = 'Вы успешно прошли верификацию!'

          })
          .catch((e) => {
            this.errorMessage = e.response.data.message
            this.successMessage = ''
            console.log(e)
          })
          .finally (() => {
            this.isLoadingSend = false
          })
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    format(value) {
      if (value === null || value === undefined) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    // handleWheelScroll(event) {
    //
    //   event.preventDefault();
    //
    //   this.$refs.scrollContainer.scrollLeft += event.deltaY;
    // },
    formatPrice: (val) => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
    clickPayment() {
      this.isCode = true;

      OperationsDataService.get({typeOperation: 2}).then((response) => {
        this.validate.id = response.data;

      });
    },
    clickCopyM(text, index) {
      const input = document.createElement("input");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopiedMessage[index] = true;
      setTimeout(() => {
        this.showCopiedMessage[index] = false;
      }, 3000);
    },
    formattedDate(date) {
      moment.locale("ru");
      return moment(date).format("D MMMM HH:mm");
      // if (date === this.event.meetingDateStart) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
      // if (date === this.event.meetingDateEnd) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
    },
    codeValidate() {
      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.choiceAcquiring();
        else
          this.errorMessage =
              "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
      });
    },
    choiceAcquiring() {
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount)
        window.location.href = "/my/payments/cloudpayments";
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    payment() {

      this.isLoadingSend = true

      MoneyDataService.payment({
        typePayment: 2,
        selectPaymentSystem: 1,
        summa: 0,
        promoCode: this.paymentData.promoCode,
        isAmountAccount: this.isAmountAccount,
      })
          .then((response) => {
            if (this.isAmountAccount)
              this.successMessage = "Благодарим за покупку.";
            else {
              window.location.href = response.data.url;
              this.successMessage = "Перейдите на сайт банка для оплаты ранга.";
            }
            setTimeout(() => {
              this.isLoadingSend = false
            }, 1000)
          })
          .catch((e) => {
            this.errorMessage = "Ошибка оплаты. " + e.response.data.message;
            console.log(e);
            setTimeout(() => {
              this.isLoadingSend = false
            }, 1000)
          });
    },
    getData() {

      MoneyDataService.getIdPaymentService()
          .then((response) => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
          })
          .catch((e) => {
            console.log(e);
          });

      UserDataService.get()
          .then((response) => {
            this.user = response.data.user;
            this.userId = this.user.id

            this.rankActive = response.data.user.rank.id;
          })
          .catch((e) => {
            console.log(e);
          });

      RanksDataService.get()
          .then((response) => {

            this.ranks = response.data;

            if (this.userId === this.IND_USER_ID) {

              return this.ranks = _.filter(this.ranks, function (item) {
                return item.id !== 2
              })
            }

          })
          .catch((e) => {
            this.errorMessage = "Ошибка получения данных";
            console.log(e);
          });

      SubscriptionDataService.activate()
          .then((response) => {
            this.isActivate = response.data;

            if (!this.isActivate)
              this.errorMessage =
                  "Чтобы получить доступ к курсам, оплатите в начале подписку.";
          })
          .then(() => {
            if (this.isActivate)
              RanksDataService.getNextRank()
                  .then((response) => {
                    if (!response.data && response.data !== null)
                      this.nextRank.id = this.ranks.length + 1;
                    else this.nextRank = response.data;

                    this.paymentData.summa = this.nextRank.price;
                    this.paymentData.name += this.nextRank.name;
                    localStorage.setItem(
                        "paymentData",
                        JSON.stringify(this.paymentData)
                    );
                  })
                  .catch((e) => {
                    this.errorMessage = "Ошибка получения данных";
                    console.log(e);
                  });
          })
          .catch((e) => {
            console.log(e);
          });


      PromoCodeDataService.get()
          .then((response) => {
            this.promoCodes = response.data;

            this.promoCodesFilter = this.promoCodes;
          })
          .catch((e) => {
            console.log(e);
          });

      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    },
    filter(event) {
      const id = event.target.value;
      let promoCodes = [];

      for (const promoCode of this.promoCodes)
        if (promoCode.rank.id === Number(id)) promoCodes.push(promoCode);

      this.promoCodesFilter = promoCodes;
    },
    generate() {
      PromoCodeDataService.generate()
          .then((response) => {
            this.promoCodes = response.data;
            this.promoCodesFilter = this.promoCodes;
            this.successMessage = "Промокоды успешно сгенерированы.";
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = "Ошибка генерации промокодов";
          });
    },
    checkPromo(event) {
      const promoCode = (this.name = event.target.value.toUpperCase());
      if (promoCode.length === 36)
        PromoCodeDataService.isPromoCode(promoCode)
            .then((response) => {
              this.isPromoCodeActivate = response.data;
              if (this.isPromoCodeActivate) {
                this.paymentData.isPromoCode = true;
                this.paymentData.promoCode = promoCode;
              }
              localStorage.setItem(
                  "paymentData",
                  JSON.stringify(this.paymentData)
              );
            })
            .catch(() => {
              this.errorMessage = "Ошибка проверки промокода";
            });
    },
    checkBox(type) {
      if (type === 1) {
        this.isPromo = false;
      }
      if (type === 2) {
        this.isAmountAccount = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-wrapper{
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all .3s;
  .modal-content {
    background-color: white;
    max-width: 523px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    transform: translateY(-30px);
    transition: all .3s;
    .modal-header{
      padding: 10px 15px;
      border-bottom: 1px solid #DEE2E6FF;
    }
  }
  .modal-footer{
    padding: 10px 15px;
    border-top: 1px solid #DEE2E6FF;
  }
}
.modal-wrapper.active{
  visibility: visible;
  opacity: 1;
  transition: all .3s;
  .modal-content {
    transform: translateY(0px);
    transition: all .3s;
  }
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.card-header-upper {
  text-decoration: uppercase;
}

.center {
  display: flex;
  align-items: center;
  justify-content: right;
}

.card-header-upper {
  text-decoration: uppercase;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.tables {
  display: block;
}

.default-according {
  display: none;
}

.copied {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copied p {
  background-color: rgba(84, 186, 74, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .default-according {
    display: block;
  }

  .tables {
    display: none;
  }
}
.btn {
  width: min(100%, 230px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-wrapper{
  width: min(100%, 230px);
}
.btn svg {
  margin-left: 8px;
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0  0 15px 15px;
  left: 50%;
  transform: translate(-50%,0);
}

.dropdown-content .btn {
  padding: 12px;
  text-decoration: none;
  display: block;
  color: #7366FF;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: .5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: #ededed;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
