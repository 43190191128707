<template>
  <div class="h-100">
    <div class="card h-100" style="padding: 20px 30px">
      <div class="card-head card-no-border">
        <div class="header-top-switch d-flex align-items-center w-100">
          <h5 class="me-auto">МОИ АКТИВЫ</h5>
          <div class="wrapper-switch d-flex">
            <input type="radio" class="btn-check" v-model="selectedOption" :value="1" name="options-base" id="option1" autocomplete="off" checked>
            <label class="btn-switch m-0 p-2 cursor-pointer" for="option1">Активы</label>

            <input type="radio" class="btn-check" v-model="selectedOption" :value="2" name="options-base" id="option2" autocomplete="off">
            <label class="btn-switch m-0 p-2 cursor-pointer" for="option2">Динамика</label>
          </div>
        </div>
      </div>
      <div class="wrapper-slider h-100 d-flex mt-5">
        <div :class="{ 'active': selectedOption === 1 }" class="slide one h-100 w-100 py-2">
          <div class="item-slide cursor-pointer d-flex mb-4 p-1">
            <div class="item-slide-description d-flex me-auto">
              <div class="item-slide-description-image">
                <img :src="require('@/assets/images/profile-my-active/quantity.svg')" alt="">
              </div>
              <div class="item-slide-description-text d-flex flex-column justify-content-center ms-2">
                <span>
                  Количество
                </span>
                <h5>
                  {{ arrayActiveString[0].visible ? format( statisticsActivites.countOfCertificates ? statisticsActivites.countOfCertificates.toFixed(0) : 0) : '****'}}
                </h5>
              </div>
            </div>
            <div class="item-slide-value d-flex justify-content-center align-items-center">
              <div class="show-hides z-3" @click.prevent="show(arrayActiveString[0].visible, 0)">
                <img v-if="arrayActiveString[0].visible" class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
              </div>
            </div>
          </div>
          <div class="item-slide d-flex cursor-pointer mb-4 p-1">
            <div class="item-slide-description d-flex me-auto">
              <div class="item-slide-description-image">
                <img :src="require('@/assets/images/profile-my-active/price.svg')" alt="">
              </div>
              <div class="item-slide-description-text d-flex flex-column justify-content-center ms-2">
                <span>
                  Стоимость покупки
                </span>
                <h5>
                  {{ arrayActiveString[1].visible ? format( statisticsActivites.amountOfLastBuy ? statisticsActivites.amountOfLastBuy.toFixed(3) : 0 ) : '****' }} ₽
                </h5>
              </div>
            </div>
            <div class="item-slide-value d-flex justify-content-center align-items-center">
              <div class="show-hides z-3" @click.prevent="show(arrayActiveString[1].visible, 1)">
                <img v-if="arrayActiveString[1].visible" class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
              </div>
            </div>
          </div>
          <div class="item-slide d-flex cursor-pointer mb-4 p-1">
            <div class="item-slide-description d-flex me-auto">
              <div class="item-slide-description-image">
                <img :src="require('@/assets/images/profile-my-active/averagecost.svg')" alt="">
              </div>
              <div class="item-slide-description-text d-flex flex-column justify-content-center ms-2">
                <span>
                  Средняя стоимость
                </span>
                <h5>
                  {{ arrayActiveString[2].visible ? format( statisticsActivites.avarageCostOfCertificate ? statisticsActivites.avarageCostOfCertificate.toFixed(3) : 0 ) : '****' }} ₽
                </h5>
              </div>
            </div>
            <div class="item-slide-value d-flex justify-content-center align-items-center">
              <div class="show-hides z-3" @click.prevent="show(arrayActiveString[2].visible, 2)">
                <img v-if="arrayActiveString[2].visible" class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
              </div>
            </div>
          </div>
          <div class="item-slide d-flex cursor-pointer mb-4 p-1">
            <div class="item-slide-description d-flex me-auto">
              <div class="item-slide-description-image">
                <img :src="require('@/assets/images/profile-my-active/correntcost.svg')" alt="">
              </div>
              <div class="item-slide-description-text d-flex flex-column justify-content-center ms-2">
                <span>
                  Текущая стоимость
                </span>
                <h5>
                  {{ arrayActiveString[3].visible ? format( statisticsActivites.countOfAmountOfCertificates ? statisticsActivites.countOfAmountOfCertificates.toFixed(3) : 0 ) : '****' }} ₽
                </h5>
              </div>
            </div>
            <div class="item-slide-value d-flex justify-content-center align-items-center">
              <div class="show-hides z-3" @click.prevent="show(arrayActiveString[3].visible, 3)">
                <img v-if="arrayActiveString[3].visible" class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'active': selectedOption === 2 }" class="slide two h-100 w-100">
          <MaketGraph/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MaketGraph from "./OrderthismonthCard/MarketGraphCard.vue"

export default({
  name: "MyActive",
  props: {
    statisticsActivites: {
      type: Array
    },
  },
  data() {
    return {
      pathImg: '../../../../assets/images/profile-my-active/',
      selectedOption: 1,
      arrayActiveString: [
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
        {
          visible: false,
        },
      ]
    };
  },
  components: {
    MaketGraph,
  },
  mounted(){

  },
  methods: {

    format: val => `${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '\$1 ').replace(/(\.\d+)\s+/g, '\$1'),

    get_image() {
      const data = ref("@/assets/images/profile-my-active/quantity.svg")

      const getImg = computed(() => {
        return data.value
      })
      return "https://picsum.photos/id/237/111/111";
    },

    show(item, index) {
      this.arrayActiveString[index].visible = !item
    }
  }
})
</script>

<style scoped>

</style>