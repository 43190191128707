<template>
  <Breadcrumbs title="УПРАВЛЕНИЕ ПРОЕКТАМИ" />

  <div class="container-fluid mb-3">
    <div class="row">
      <div class="col-auto">
        <router-link to="/vote/create">
          <button type="button" class="btn btn-outline-primary">
            Добавить проект
          </button>
        </router-link>
      </div>
      <div class="col-auto d-flex justify-content-center align-items-center">
        <p>Отобразить новости</p>
      </div>
      <div class="col-auto">
        <select
          name="currentTabSelect"
          id="currentTabSelect"
          class="form-control"
          v-model="currentTab"
        >
          <option value="visible">Активные</option>
          <option value="hidden">Удаленные</option>
          <option value="all">Все</option>
        </select>
      </div>
    </div>
  </div>

  <div class="container-fluid p-2">
    <template v-if="lists[currentTab].length != 0">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4">
        <template v-for="item in lists[currentTab]">
          <div class="col">
            <div class="card">
              <div class="product-box">
                <div class="product-img">
                  <div class="ratio ratio-1x1">
                    <img
                      class="img-fluid"
                      :src="`${backendURL}/${item.preview}`"
                      style="object-fit: cover"
                    />
                  </div>
                </div>
                <div class="product-details">
                  <p class="text-muted">
                    {{ item.category.name }}
                  </p>
                  <h4>
                    {{ item.name }}
                  </h4>
                  <p>
                    {{ item.shortDescription }}
                  </p>
                  <div class="product-price">
                    <!-- <span>Собрано {{ item.fundraisingVolume }} / </span> -->
                    {{ item.price }} &#8381;
                  </div>
                  <div class="row g-2">
                    <div class="col-6">
                      <div class="d-flex mt-2">
                        <button
                          class="btn btn-primary flex-fill"
                          @click="router.push(`/vote/${item.id}`)"
                        >
                          Подробнее
                        </button>
                      </div>
                    </div>
                    <div class="col" v-if="!item.isDeleted">
                      <div class="d-flex mt-2">
                        <button
                          class="btn btn-secondary flex-fill"
                          @click="router.push(`/vote/edit/${item.id}`)"
                        >
                          <i class="bi bi-pencil-fill"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col">
                      <div class="d-flex mt-2">
                        <button
                          class="btn btn-danger flex-fill"
                          v-if="!item.isDeleted"
                          @click="removeProject(item.id)"
                        >
                          <i class="bi bi-trash-fill"></i>
                        </button>
                        <button
                          class="btn btn-success flex-fill"
                          v-else
                          @click="restoreProject(item.id)"
                        >
                          <i class="bi bi-arrow-counterclockwise"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <div class="d-flex justify-content-center align-items-center">
        <div class="align-self-center" style="font-size: x-large">
          Проектов нет
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
//#region Imports
import _ from "lodash";
import { onMounted, ref, reactive, inject } from "vue";
import { useRouter } from "vue-router";
import ProjectsAPI from "@/services/ProjectsDataService";
import { computed } from "vue";
//#endregion

//#region Types
/**
 * @typedef Project
 * @property {Number} id
 * @property {String} name
 * @property {Object} category
 * @property {String} category.name
 * @property {String} shortDescription
 * @property {Number} fundraisingVolume
 * @property {Number} price
 * @property {String} preview
 */
//#endregion

//#region Client state
const lists = reactive({
  visible: [],
  hidden: [],
  all: computed(() => _.concat(lists.visible, lists.hidden)),
});
const currentTab = ref("visible");
const backendURL = inject("backendURL");
const router = useRouter();
//#endregion

//#region Network
async function removeProject(id) {
  await ProjectsAPI.adminSoftRemove([id]);
  await loadProjects(0, 100);
}

async function restoreProject(id) {
  await ProjectsAPI.adminRestore([id]);
  await loadProjects(0, 100);
}

async function loadProjects(page, pageSize) {
  lists.visible = (await ProjectsAPI.adminGet(page, pageSize)).data;
  lists.visible.forEach((element, index, array) => {
    element.isDeleted = false;
  });
  lists.hidden = (await ProjectsAPI.adminGetSoftRemoved()).data;
  lists.hidden.forEach((element, index, array) => {
    element.isDeleted = true;
  });
}

onMounted(async () => {
  await loadProjects(0, 100);
});
//#endregion
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
</style>
