<template>
    <Breadcrumbs :title="record.title" />

    <div class="px-3">
        <div class="ratio" style="--bs-aspect-ratio: 20%;">
            <img :src="`${backendURL}/${record.image}`" class="img-fluid" alt="..." style="object-fit: cover;">
        </div>
    </div>

    <div class="container-fluid px-5 mt-4">
        <div class="row">
            <div class="col">
                <h1 v-text="record.title"></h1>
                <p class="text-body-secondary">{{ moment(record.created_at).format("DD.MM.YYYY") }}</p>
            </div>
        </div>
    </div>

    <div class="container-fluid px-5 my-4">
        <div class="row">
            <div class="col">
                <p v-html="record.text"></p>
            </div>
        </div>
    </div>
</template>

<script setup>
//#region Imports
import moment from 'moment';
import { computed } from 'vue';
import { onMounted, ref } from 'vue';
import NewsAPI from '@/services/NewsDataService';
import { useRoute } from 'vue-router';
//#endregion

//#region Client
/**
 * Post list
 * @description Contains post list
 * @type {import('vue').Ref<import('@/services/NewsDataService').Post>}
 */
const record = ref({});
const backendURL = computed(() => process.env.VUE_APP_BACKEND_API_BASE)
//#endregion

//#region Hooks
onMounted(async () => {
    record.value = (await NewsAPI.getById(useRoute().params.id)).data
})
//#endregion
</script>