import http from "../http-common";

export default {
    get() {
        return http.get('/money');
    },
    getHistory(){
      return http.get('/money/history');
    },
    getProfit() {
        return http.get('/money/profit');
    },
    getUserMoney(){
        return http.get('/money/getUserMoney')
    },
    getIdPaymentService() {
        return http.get('/payment/selectTypePayment');
    },

    getPriceHistoryOfCertificate(){
        return http.get('/profile/certificate-price-history')
    },

    moneyTransfer(body) {
        return http.post('/money/transfer', body);
    },

    withdraw(body) {
        return http.post('/money/withdrawals', body);
    },

    payment(body) {
        return http.post('/payment', body);
    },

    history() {
        return http.get('/money/history');
    },

    getCreditTinkoff() {
        return http.get('/payment/credit-tinkoff');
    },
    getProfitForEveryMounth(){
        return http.get('profile/profit-by-month')
    },
}

