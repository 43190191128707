<template>
  <div class="wrapper-application-page mt-5 mt-md-0">
    <div class="item-wrappers-application d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="download-text text-center text-md-start mb-5 mb-md-0">
        <h1 class="download-text-title mb-4 text-center text-md-start mt-5 mt-md-0 text-uppercase">
          Скачайте приложение
        </h1>
        <p class="download-text-description-text text-center text-md-start mb-5">
          Ваш надежный партнер в мире финансов и инвестиций.
        </p>
        <div>
        <a class="btn btn-primary" href="https://disk.yandex.ru/d/8EF9NfBYVVNMgQ" download="rantie.apk">Скачать приложение</a>
      </div>
      </div>
      <div class="download-img mt-5 mt-md-0 ms-0 ms-md-5">
        <img class="w-100" src="@/assets/images/documentation/app-page/main-phone.png" alt="">
      </div>
    </div>
    <div class="item-wrappers-application">
      <div class="download-text text-center text-md-start mb-5 mb-md-0">
        <h1 class="app-title mb-4 text-center text-md-start mt-5 mt-md-0 text-uppercase">
          Почему "РАНТЬЕ"?
        </h1>
      </div>
      <div class="app-wrapper-content mt-5 mt-md-0">
        <div class="row g-3">
          <div class="col-12 col-lg-6 col-xl-4">
            <div class="card-app-content px-4 py-5 h-100">
              <div class="card-app-content-img">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="80" height="80" rx="40" fill="white"/>
                  <path d="M45 36.6667L38.3333 43.3333L35 40M40 55C31.7157 55 25 48.2843 25 40C25 31.7157 31.7157 25 40 25C48.2843 25 55 31.7157 55 40C55 48.2843 48.2843 55 40 55Z" stroke="#7366FF" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="app-wrapper-content-title text-uppercase">
                Доступность инвестиций
              </div>
              <div class="app-wrapper-content-description">
                Приложение "РАНТЬЕ" позволяет управлять своими активами и инвестициями прямо со смартфона. Вам не нужно ждать доступа к компьютеру — все функции всегда под рукой, что делает управление финансами более гибким и удобным.
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4">
            <div class="card-app-content px-4 py-5 h-100">
              <div class="card-app-content-img">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="80" height="80" rx="40" fill="white"/>
                  <path d="M28.0086 25.9258L54.3572 34.033C55.7943 34.4752 55.9576 36.4439 54.6128 37.1163L43.4455 42.6998C43.123 42.8611 42.8613 43.1227 42.7 43.4453L37.1167 54.6119C36.4443 55.9568 34.4759 55.7937 34.0337 54.3566L25.9257 28.0081C25.5323 26.7293 26.7298 25.5323 28.0086 25.9258Z" stroke="#7366FF" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="app-wrapper-content-title text-uppercase">
                удобная навигация
              </div>
              <div class="app-wrapper-content-description">
                В приложении реализован интуитивно понятный интерфейс, где каждый пользователь может легко находить нужные функции: управление активами, проверка статуса партнёрской сети, доступ к обучению и многое другое.
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4">
            <div class="card-app-content px-4 py-5 h-100">
              <div class="card-app-content-img">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="80" height="80" rx="40" fill="white"/>
                  <path d="M45.0003 48.3337V50.0003C45.0003 52.7618 42.7617 55.0003 40.0003 55.0003C37.2389 55.0003 35.0003 52.7618 35.0003 50.0003V48.3337M45.0003 48.3337H35.0003M45.0003 48.3337H50.9845C51.622 48.3337 51.9423 48.3337 52.2005 48.2466C52.6936 48.0803 53.0794 47.6931 53.2458 47.2C53.3332 46.9408 53.3332 46.6195 53.3332 45.9769C53.3332 45.6957 53.3329 45.5551 53.3109 45.4211C53.2693 45.1677 53.171 44.9276 53.0213 44.719C52.9421 44.6088 52.8416 44.5083 52.6434 44.31L51.9941 43.6608C51.7847 43.4513 51.667 43.1672 51.667 42.871V36.667C51.667 30.2237 46.4437 25.0003 40.0003 25.0003C33.557 25.0003 28.3337 30.2237 28.3337 36.667V42.871C28.3337 43.1673 28.2157 43.4513 28.0063 43.6608L27.3571 44.31C27.1583 44.5088 27.0587 44.6087 26.9795 44.7191C26.8298 44.9276 26.7306 45.1677 26.689 45.4211C26.667 45.5551 26.667 45.6957 26.667 45.9769C26.667 46.6195 26.667 46.9407 26.7544 47.1999C26.9207 47.693 27.3083 48.0803 27.8014 48.2466C28.0596 48.3337 28.3787 48.3337 29.0163 48.3337H35.0003M50.0312 23.3564C52.3299 25.0886 54.1409 27.3866 55.2877 30.0266M29.9703 23.3564C27.6716 25.0886 25.8607 27.3866 24.7139 30.0266" stroke="#7366FF" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="app-wrapper-content-title text-uppercase">
                Удобные уведомления
              </div>
              <div class="app-wrapper-content-description">
                Приложение отправляет уведомления о новых возможностях, обновлениях курса и напоминания о действиях, которые помогут вам оставаться на шаг впереди.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-wrappers-application my-5">
      <div class="download-text text-center text-md-start mb-5">
        <h1 class="app-title mb-4 text-center text-md-start mt-5 mt-md-0 text-uppercase">
          Почему "РАНТЬЕ"?
        </h1>
      </div>
      <div class="app-wrapper-content-bg mt-5 mt-md-0">
        <div class="row justify-content-start g-0">
          <div class="col-12 col-xl-4">
            <div class="card-bg orange-bg px-4 py-5">
              <div class="card-bg-top d-flex mb-2">

                <div class="card-bg-top-icon">
                  1
                </div>
                <div class="card-bg-top-title">
                  Скачайте приложение
                </div>
              </div>
              <div class="card-bg-subtitle">
                Установите "РАНТЬЕ" на ваш смартфон из Google Play
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end g-0 my-5 my-xl-0">
          <div class="col-12 col-xl-4">
            <div class="card-bg pink-bg px-4 py-5">
              <div class="card-bg-top d-flex mb-2">

                <div class="card-bg-top-icon">
                  2
                </div>
                <div class="card-bg-top-title">
                  Создайте профиль
                </div>
              </div>
              <div class="card-bg-subtitle">
                Зарегистрируйтесь или войдите в свой профиль за пару минут
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start mb-5 g-0">
          <div class="col-12 col-xl-4">
            <div class="card-bg green-bg px-4 py-5">
              <div class="card-bg-top d-flex mb-2">
                <div class="card-bg-top-icon">
                  3
                </div>
                <div class="card-bg-top-title">
                  Начните инвестировать
                </div>
              </div>
              <div class="card-bg-subtitle">
                Просматривайте активы, обучайтесь и зарабатывайте прямо с телефона
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-wrappers-application card-purple">
      <div class="col-12 col-xl-7 text-center text-xl-start">
        <h2 class="text-uppercase text-white text-center text-xl-start">
          Не упустите возможность!
        </h2>
        <span class="last-text text-white d-block mt-4 text-center text-xl-start">
          Откройте для себя все возможности "РАНТЬЕ" в мобильном приложении — ваш путь к эффективному инвестированию теперь всегда под рукой.
        </span>
        <a class="btn btn-primary mt-5 text-center" href="https://disk.yandex.ru/d/8EF9NfBYVVNMgQ" download="rantie.apk" >
          Скачать приложение
        </a>
      </div>
    </div>
  </div>
</template>
<script>
</script>