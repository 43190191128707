<template>
  <div>
    <Breadcrumbs title="ОБРАЩЕНИЕ" />
    <div class="col call-chat-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col call-chat-body call-chat-body-chat">
            <div class="card">
              <div class="card-body p-0">
                <div class="row chat-box chat-box-mobile">
                  <div class="col pe-0 chat-right-aside">
                    <div class="chat">
                      <div class="chat-header clearfix">
                        <div class="about">
                          <div class="name">ОБРАЩЕНИЕ</div>
                        </div>
                        <ul class="list-inline float-start float-sm-end chat-menu-icons"></ul>
                      </div>
                      <div class="chat-history chat-msg-box custom-scrollbar" ref="chatHistory">
                        <ul ref="chatHistory">
                          <li v-for="message in messages" :key="message.id" class="clearfix">
                            <div :class="message.user?.id === ProfileUserId || message.user === ProfileUserId ? 'message my-message' : 'message my-message pull-right'">
                              <div class="message-data text-end" v-if="message.user?.id === ProfileUserId || message.user === ProfileUserId">Вы</div>
                              <div class="message-data text-end" v-else>Техподдержка</div>{{ message?.message }}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="chat-message clearfix">
                        <div class="row">
                          <div class="col-xl-12 d-flex">
                            <div class="input-group text-box">
                              <input
                                  class="form-control input-txt-bx"
                                  id="message-to-send"
                                  type="text"
                                  v-model="messageToSend.message"
                                  placeholder="Напишите сообщение"
                                  @keydown.enter="sendMessage"
                              />
                              <button class="btn btn-primary" type="button" @click="sendMessage">Отправить</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col call-chat-sidebar col-sm-12 scroll-del">
            <div class="card scroll-del">
              <div class="card-body chat-body scroll-del">
                <div class="chat-box scroll-del">
                  <div class="chat-left-aside scroll-del">
                    <div class="people-list" id="people-list">
                      <div class="search">
                        <form class="theme-form">
                          <div class="form-group my-3">
                            <div class="input-group">Еще обращения:</div>
                          </div>
                        </form>
                      </div>
                      <ul class="list custom-scrollbar">
                        <li class="clearfix cursor-p" v-for="forums in Forums.forums" :key="forums.id" @click="openChat(forums.id)">
                          <!--                          <img class="rounded-circle user-image"-->
                          <!--                               src="#"-->
                          <!--                               alt="">-->
                          <!--                          <div class="status-circle away"></div>-->
                          <div class="about">
                            <div class="name">{{ forums.title }}</div>
                            <div class="status">{{ forums.messages[0].message }}</div>
                          </div>
                        </li>
                        <li ref="bottom"></li> <!-- Placeholder element to observe for scroll end -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

.chat-right-aside {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .call-chat-sidebar, .scroll-del {
    max-width: 265px;
  }
}
.chat-box {
  overflow: auto;
  max-height: 700px !important;
  height: 100% !important;
}
.cursor-p{
  cursor: pointer;
}
</style>


<script>
import ForumDataService from "@/services/ForumDataService";
import UserDataService from "@/services/UserDataService";
import io from 'socket.io-client';

export default {
  data() {
    return {
      forumId: "",
      userId: "",
      Profile: [],
      ForumUserId: "",
      ProfileUserId: null,
      messageToSend: {
        message: "",
      },
      Forum: [],
      pageSize: 8,
      page: 1,
      Forums: [],
      newMessage: '',
      lastMessage: null,
      messages: [],
      socket: io(process.env.VUE_APP_BACKEND_API_BASE),
    }
  },
  updated() {
  },
  async mounted() {
    await this.getForum();
    await this.loadForums();
    ForumDataService.getForums(this.pageSize, 0)
        .then(response => {
          this.Forums = response.data;
        })
    this.forumId = this.$route.params.id;
    this.authToken = localStorage.getItem('authToken');
    this.socket = io(process.env.VUE_APP_BACKEND_API_BASE, {
      extraHeaders: {
        Authorization: `Bearer ${this.authToken}`,
      },
    });
    this.setupObserver();
    this.setupSocketListeners();
    this.scrollToBottom();
    this.setupObserver();
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    scrollToBottom() {
      this.$refs.chatHistory.scrollTop = this.$refs.chatHistory.scrollHeight;
    },
    openChat(id){
      this.$router.push('/forum/chat/admin/' + id);
    },
    async sendMessage() {

      if (this.messageToSend.message.trim() !== '') {
        let id = this.$route.params.id;
        await ForumDataService.getForumById(id)
            .then((response) => {
              this.Forum = response.data;
              this.forumId = this.Forum.id;
            })
            .catch(error => {
              console.error(error);
            });
        await UserDataService.get()
            .then((response) =>{
              this.Profile = response.data;

            })
        await ForumDataService.postAdminSendMessagesForum(id, { message: this.messageToSend.message })
            .then(response => {
              console.log('Отправлено сообщение:', this.messageToSend.message);
              const newMessage = this.messageToSend.message;
              this.messageToSend.message = '';
              this.socket.emit('message', {message: newMessage, userId: this.Profile.id, forumId: this.forumId});
              this.scrollToBottom();
            })
            .catch(error => {
              console.error('Ошибка отправки сообщения:', error.response.data);
            });
      } else {
        console.log('Пожалуйста, введите сообщение');
      }
    },
    async getForum() {
    let id = this.$route.params.id;
    await ForumDataService.getForumById(id)
        .then((response) => {
            this.Forum = response.data;
            this.ForumUserId = this.Forum.user.id;
            this.userId = this.Forum.user.id;
            this.messages = this.Forum.messages || [];
        })
        .catch(error => {
            console.error(error);
        });
    },

    async loadForums() {
      if (this.loading || this.allLoaded) return;
      this.loading = true;
      try {
        const response = await ForumDataService.getForums(this.pageSize, this.page);
        if (response.data.forums.length > 0) {
          this.Forums.forums = [...this.Forums.forums, ...response.data.forums];
          this.page += 1;
        } else {
          this.allLoaded = true;
        }
      } catch (error) {
        console.error('Error loading forums:', error);
      } finally {
        this.loading = false;
      }
    },
    setupObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      this.observer = new IntersectionObserver(this.handleIntersect, options);
      if (this.$refs.bottom) {
        this.observer.observe(this.$refs.bottom);
      }
    },
    handleIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadForums();
        }
      });
    },
    async setupSocketListeners() {
      let id = this.$route.params.id;
      await ForumDataService.getForumById(id)
          .then((response) => {
            this.Forum = response.data;
            this.forumId = this.Forum.id;
          })
          .catch(error => {
            console.error(error);
          });
      await UserDataService.get()
          .then((response) => {
            this.Profile = response.data;
            this.ProfileUserId = this.Profile.user.id
          })

      this.socket.emit('join_room', {userId: this.Profile.id, forumId: this.forumId});
      this.socket.on('message', (message) => {
        this.messages.push(message);
        console.log('New message:', message);
      });
    }
  }
}
</script>


<style scoped>
.chat-msg-box-mobile {
  max-height: 700px;
}
</style>
