<template>
    <div class="card widget-hover">
        <div class="card-body p-4">
            <div class="me-auto">
                <h5>
                    ДОСТУП К ПЛАТФОРМЕ “РАНТЬЕ”
                </h5>
                <div>
                    <p class="m-0" v-if="dateStart != 0">Начало использования {{ dateStart }}</p>
                    <p class="m-0" v-else>Начало использования - </p>
                    <p class="m-0" v-if="dateEnd != 0">Подписка действует до {{ dateEnd }}</p>
                    <p class="m-0" v-else>Подписка действует до - </p>
                </div>
                <router-link v-if="dateStart === 0 && dateEnd === 0" to="/users/activate" class="btn btn-primary btn-outline-black mt-4">Продлить</router-link>
            </div>
            <img src="@/assets/images/image-platform.png" alt="" style="max-width: 100%; height: auto;">
        </div>
    </div>
</template> 
<script>
import SubscriptionDataService from '@/services/SubscriptionDataService';
import UserDataService from '@/services/UserDataService';

export default {
  name: "platform",
  data() {
    return {
      subscribe: [],
      dateStart: 0,
      dateEnd: 0,
      isEndingSoon: false,
    };
  },
  mounted() {
    this.getSubscription()
  },
  methods: {

    getSubscription(){
        SubscriptionDataService.get()
        .then((response) => {
          this.subscribe = response.data

          if (this.subscribe) {

            const dateStart = new Date(this.subscribe.startDate);
            const dateEnd = new Date(this.subscribe.endDate);

            const formatWithLeadingZeros = (date) => {
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              return `${day}.${month}.${year}`;
            };


            this.dateStart = formatWithLeadingZeros(dateStart);
            this.dateEnd = formatWithLeadingZeros(dateEnd);

            this.checkIfEndingSoon(dateEnd);
          }

        })
        .catch((e) => {
            console.log(e);
        })
    },
    checkIfEndingSoon(endDate) {
      const now = new Date();
      const timeDifference = new Date(endDate) - now;

      const daysUntilEnd = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      this.isEndingSoon = daysUntilEnd <= 30 && daysUntilEnd >= 0;
    },
  },
};
</script>
<style scoped>
    .card-body {
        display: flex;
        align-items: center;
    }
    button {
        margin-top: 20px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 28.8px;
      text-align: left;

    }
</style>