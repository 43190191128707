export var menuItems = {
  data: [
    {
      title: "Мой капитал",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      indUserId: true,
      children: [
        {
          path: "/",
          title: "Мои активы",
          type: "link",
          indUserId: true,
          active: false,
        },
        {
          path: "/users/profileNews",
          title: "Профиль",
          type: "link",
          indUserId: true,
          active: false,
        },
        {
          path: "/users/edit",
          title: "Редактировать",
          type: "link",
          indUserId: true,
          active: false,
        },
        {
          path: "/users/settings",
          title: "Настройки",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Подписка",
      badgeType: "light-primary",
      icon: "case",
      iconf: "fill-home",
      type: "link",
      active: false,
      path: "/users/activate",
    },
    {
      title: "Чаты",
      badgeType: "light-primary",
      icon: "stroke-email",
      iconf: "stroke-email",
      type: "link",
      active: false,
      indUserId: true,
      path: "/chat/index",
    },
    {
      title: "Курсы, карьера",
      icon: "stroke-project",
      iconf: "fill-project",
      type: "sub",
      active: false,
      indUserId: true,
      children: [
        {
          path: "/career/courses",
          title: "Курсы",
          type: "link",
          active: false,
        },
        {
          path: "/career/my",
          title: "Мои курсы",
          type: "link",
          active: false,
          indUserId: true,
        },
      ],
    },

    {
      title: "Сертификаты",
      icon: "stroke-animation",
      iconf: "fill-animation",
      type: "sub",
      badgeType: "light-secondary",
      active: false,
      children: [
        {
          path: "/investment/one",
          title: "Мои сертификаты",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Проекты",
      icon: "stroke-briefcase",
      iconf: "fill-briefcase",
      type: "sub",
      badgeType: "light-secondary",
      active: false,
      indUserId: true,
      children: [
        {
          path: "/investment/projects",
          title: "Рантье",
          type: "link",
          active: false,
          indUserId: true,
        },
        {
          title: "Оценка",
          type: "link",
          active: false,
          path: "/vote",
          indUserId: true,
        },
      ],
    },
    {
      title: "Моя сеть",
      path: "/networks/my",
      icon: "stroke-learning",
      iconf: "fill-learning",
      type: "link",
      active: false,
    },
      // {
      //     title: "Обращение",
      //     path: "/forum/user/list",
      //     icon: "stroke-learning",
      //     iconf: "fill-learning",
      //     type: "link",
      //     active: false
      // },
    {
      title: "Финансы",
      icon: "stroke-animation",
      iconf: "fill-charts",
      type: "sub",
      badgeType: "light-secondary",
      active: false,
      children: [
        {
          path: "/finance/my",
          title: "Мои финансы",
          type: "link",
          active: false,
        },
        {
          path: "/finance/history",
          title: "История",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Cashback",
      icon: "stroke-maps",
      iconf: "fill-maps",
      type: "sub",
      badgeType: "light-secondary",
      active: false,
      children: [
        {
          path: "/municapal/feedback",
          title: "Передать клиента",
          type: "link",
          active: false,
        },
        {
          path: "/municapal/strategic-partners",
          title: "Стратегические партнеры",
          type: "link",
          active: false,
        },
        {
          path: "/municapal/partners",
          title: "Партнеры",
          type: "link",
          active: false,
        },
        {
          path: "/municapal/become",
          title: "Стать партнером",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Мероприятия",
      icon: "stroke-bookmark",
      iconf: "fill-bookmark",
      type: "sub",
      active: false,
      children: [
        {
          path: "/events/list",
          title: "Список",
          type: "link",
          active: false,
        },
        {
          path: "/events/tickets",
          title: "Мои билеты",
          type: "link",
          active: false,
        },
      ],
    },
    {
      path: "/news",
      title: "Новости",
      icon: "stroke-button",
      iconf: "fill-button",
      badgeType: "light-secondary",
      badgeValue: "!",
      type: "link",
      active: false,
    },
    {
      title: "Инструменты",
      icon: "stroke-bookmark",
      iconf: "fill-maps",
      type: "sub",
      active: false,
      indUserId: true,
      children: [
        {
          path: "/vocabulary/information",
          title: "СЛОВАРЬ",
          icon: "stroke-button",
          iconf: "fill-button",
          badgeType: "light-secondary",
          type: "link",
          active: false,
          indUserId: true,
        },
        {
          path: "/business/tools",
          title: "Для бизнеса",
          type: "link",
          active: false,
        },
          ]
    },
    {
      title: "Проверяющему",
      icon: "stroke-others",
      iconf: "fill-others",
      type: "sub",
      active: false,
      roleRestriction: [8],
      children: [
          {
              path: "/verification/my",
              title: "Верификация",
              type: "link",
              active: false,
          },
          {
            path: "/vote/manage",
            title: "Проекты",
            type: "link",
            active: false,
          }
          ]
    },
    {
      title: "администрация",
      icon: "stroke-others",
      iconf: "fill-others",
      type: "sub",
      active: false,
      roleRestriction: [9],
      children: [
        {
          path: "/founders/list",
          title: "Учредители",
          type: "link",
          active: false,
        },
        {
          path: "/founders/users",
          title: "Пользователи",
          type: "link",
          active: false,
        },
      ]
    },
    {
      title: "Администратору",
      icon: "stroke-others",
      iconf: "fill-others",
      type: "sub",
      active: false,
      roleRestriction: [2],
      children: [
        {
          path: "/news/manage",
          title: "Новости",
          type: "link",
          active: false,
        },
          // {
          //     path: "/forum/admin/list",
          //     title: "Обращения",
          //     type: "link",
          //     active: false,
          // },
        {
          path: "/events/list",
          title: "Мероприятия",
          type: "link",
          active: false,
        },
        {
          path: "/itService/feedback",
          title: "Рекомендовать",
          type: "link",
          active: false,
        },
        {
          path: "/verification/my",
          title: "Верификация",
        },
        {
          path: "/tests/admin/list",
          title: "Тесты",
          type: "link",
          active: false,
        },
      ],
    },
  ],
};

