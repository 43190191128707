import http from "../http-common";

export default {
    getCheckEmail() {
        return http.get('/coupons/grand/api/email');
    },
    getLink() {
        return http.get('/coupons/grand/api/link');
    },
    getInfo() {
        return http.get('/coupons/grand/api/info/isPurchase');
    },
}

