<template>
  <div class="h-100">
    <div class="card d-flex flex-column justify-content-center furure-in-hands h-100" style="padding: 20px 30px">
      <div class="wrapper-furure-in-hands my-5 z-2">
        <img src="@/assets/images/background-city.svg" class="d-block d-md-none" alt="">
        <div class="my-5 py-5 d-block d-md-none"></div>
        <div>
          <h4 class="mb-3">
            БУДУЩЕЕ В ВАШИХ РУКАХ
          </h4>
          <p>
            Информация о самых перспективных проектах
            и возможности инвестирования в инновационные решения.
          </p>
          <router-link class="btn btn-primary btn-outline-black mt-4" to="/vote">
            Перейти в проекты
          </router-link>
        </div>
      </div>
      <div class="background-city d-none d-md-block text-end">
        <img class="h-100" src="@/assets/images/background-city.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default({
  name: "Events",
  props: {

  },
  data() {
    return {

    };
  },
  mounted(){

  },
  methods: {


  }
})
</script>

<style scoped>

</style>