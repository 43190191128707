<template>
  <div>
    <Breadcrumbs title="ИНСТРУМЕНТЫ ДЛЯ БИЗНЕСА" main="МОЙ КАПИТАЛ" />
    <div class="container-fluid row">
      <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-3">
        <div class="card card-body w-100">
          <div class="card-header card-no-border text-left mt-3">
            <h5>Инструменты для бизнеса</h5>
          </div>
          <div class="card-body pt-0">
            <div class="embed-responsive embed-responsive-16by9">
              <video
                class="p-3"
                controls
                controlsList="nodownload"
                style="height: 100%; width: 100%"
                preload="none"
                poster="../../assets/images/video/de7efef480b19794da214f40b0f7f359.png"
              >
                <source src="../../assets/video/video.mp4" data-fluid-hd="" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in itemsBusinessTool"
        :key="index"
        class="col-12 col-md-6 col-xl-4 col-xxl-3 mt-3 h-100"
      >
        <div
          class="card card-body w-100 d-flex flex-column justify-content-center aling-items-center"
        >
          <img :src="item.image" alt="" style="height: auto; width: 100%" />
          <div class="wrapper-text text-center my-3">
            <b>
              {{ item.title }}
            </b>
          </div>
          <div class="wrapper-btn">
            <a
              :href="item.pathPDF"
              target="_blank"
              class="btn btn-primary pointer-cursor mt-2 w-100"
            >
              Скачать
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import docImage from '@/assets/images/BusinessTool/doc.png'
import rantieUpImage from '@/assets/images/BusinessTool/rantieUp.png'
import loopmeetImage from '@/assets/images/BusinessTool/loopmeet.png'

export default {
  data() {
    return {
      baseUrl: "/my/img/",
      itemsBusinessTool: [
        {
          image: docImage,
          title: "РАНТЬЕ. ЦИФРОВАЯ ПЛАТФОРМА",
          pathPDF: "../../../my/files/profile/listovka.pdf",
        },
        {
          image: rantieUpImage,
          title: "РАНТЬЕ. ПЛАТФОРМА ФИНАНСИРОВАНИЯ",
          pathPDF: "../../../my/files/profile/Презентация.pptx",
        },
        {
          image: loopmeetImage,
          title: "РАНТЬЕ. ЦИКЛ ВСТРЕЧ C УЧРЕДИТЕЛЯМИ",
          pathPDF:
            "../../../my/files/profile/Цикл_учредителей_Прилепин_Казань.pdf",
        },
      ],
    };
  },

  methods: {
    getImageUrl(imagePath) {
      return `${this.baseUrl}${imagePath}`;
    }
  }
};
</script>

<style scoped>
.wrapper-btn-video {
  display: flex;
  justify-content: space-around;
}
</style>
