<template>
  <Breadcrumbs main="ПРОЕКТЫ" title="СПИСОК ПРОЕКТОВ"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>ПРОЕКТЫ</h5>
          </div>
          <div class="card-body">
            <p> В настоящее время платформа “Рантье” в сотрудничестве с Союзом российских городов (СРГ) готовит список проектов, одобренных муниципалитетами, входящими в СРГ. Библиотека СРГ насчитывает более 10 000 проектов: по инфраструктуре, благоустройству и др. В ближайшее время отобранные проекты будут опубликованы.</p>
            <p>Сейчас Вы можете участвовать в поддержке разработки Платформы "Рантье" через приобретение Сертификатов.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row project-cards">
      <topMenu/>
    </div>
  </div>
</template>

<script>
import topMenu from '../projectlist/topMenu.vue'

export default {
  name: 'indexList',
  components: {
    topMenu
  },

}

</script>
