import http from "../http-common";

export default {
    get() {
        return http.get('/network');
    },
    getById(id) {
        return http.get(`/network/${id}`);
    },
    getGraphicsCourses(year){
        return http.get(`/network/graphics/course`,{
            params: {
                year: year
            }
        })
    },
    getGraphicsCertificates(year){
        return http.get(`/network/graphics/certificate`,{
            params: {
                year: year
            }
        })
    },
    getGraphicsUsers(year){
        return http.get(`/network/graphics/user`,{
            params: {
                year: year
            }
        })
    }
}

