<template>
    <div class="card-alert">
        <div v-if="!statusVerification" class="card profile-box">
            <div class="card-body">
                <div class="media d-flex flex-column h-100">
                    <div>
                        <h4 class=" mb-0">Верификация</h4>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1 flex-basis-auto mt-3" style="flex: 1 1 auto;">
                        <p>
                            Вам доступны не все возможности платформы, так как ваш аккаунт не прошел верификацию.
                        </p>
                    </div>
                    <div class="form-data">
                        <verifications :btn="btn"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="statusVerification === 1" class="card profile-box">
            <div class="card-body">
                <div class="media d-flex flex-column h-100">
                    <div>
                        <h4 class=" mb-0">Верификация</h4>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1 flex-basis-auto mt-3" style="flex: 1 1 auto;">
                        <p>
                            Верификация в процессе проверки,
                        <br>
                            пожалуйста, ожидайте 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="statusVerification === 2" class="card profile-box profile-box-danger h-100">
            <div class="card-body">
                <div class="media d-flex flex-column h-100">
                    <div>
                        <h4 class=" mb-0">Верификация</h4>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1 flex-basis-auto mt-3" style="flex: 1 1 auto;">
                        <p>
                            Ваша верификация отклонена.
                        <br>
                            Пожалуйста, пройдите процесс верификации заново.
                        </p>
                    </div>
                  <div class= " mb-3 comment-verification" style="flex: 1 1 auto;">

                    {{commentVerification}}
                  </div>
                  <div class="form-data">
                    <verifications :btn="btn"/>
                  </div>
                </div>
            </div>
        </div>
        <div v-if="statusVerification === 3" class="card profile-box">
            <div class="card-body">
                <div class="media d-flex flex-column h-100">
                    <div>
                        <h4 class=" mb-0">Добро пожаловать!</h4>
                    </div>
                    <div class="flex-grow-1 flex-shrink-1 flex-basis-auto mt-3" style="flex: 1 1 auto;">
                        <p>
                            Добро Пожаловать, Уважаемый Пользователь! 
                        <br>
                            Мы рады видеть Вас здесь!
                        </p>
                    </div>
                    <div >
                        <router-link :to="'/networks/my'" class="btn btn-castom">
                            Моя сеть
                        </router-link>
                    </div>
                    <!-- <div class="media-body">
                        <div class="greeting-user">
                            <h4 class="f-w-600 mb-0">Добро пожаловать!</h4>
                            <p>Добро Пожаловать, Уважаемый Пользователь! <br>
                                Мы рады видеть Вас здесь!</p>
                            <div class="whatsnew-btn">
                                <a class="btn btn-outline-white">Редактировать профиль</a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VerificarionsDataService from "@/services/VerificarionsDataService";
import verifications from "@/pages/users/edit/verifications.vue";

export default {
    components: {
        verifications,
    },
    props: {
    commentVerification: {
         type: String,
          required: false,
    },
    statusVerification: {
      type: Number,
      required: true,
    },
    idappeall: {
        type: Number,
        required: true,
    },
    btn: {
        type: Number,
        required: false,
    }
  },
  mounted() {
    
  },
  methods: {
    deleteAppeal(id) {
      VerificarionsDataService.deleteAppeal(id)
        .then(({ data }) => {
          this.messageSuccess = data.message;
          location.reload();
        })
        .catch((e) => {
          console.log(e);
          this.messageError = e.message;
        });
    },
  }
}
</script>
<style scoped>
    h4 {
        font-size: 20px;
    }
    .comment-verification{
      max-width: 272px;
    }
    p {
        font-size: 14px;
    }
    .card-alert, .profile-box{
        height: 100%;
    }
    .flexs {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .profile-box-danger{
        border: 2px solid #F76056;
        color: #fff;
    }
    .btn-castom{
        background: #fff;
        color: #5885F8;
    }
    .danger{
        color: #F76056;
    }
</style>