<template>
    <div>
        <form @submit.prevent="sendImage" action="">
            <input type="file" @change="handleFileChange">
            <button type="submit">Отправить</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    handleFileChange(event) {
    const imageFile = event.target.files[0];
    },
    async sendImage() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post('http://localhost:3000/files/upload/profile/backgroundImage', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          }
        });
        console.log(response);
      } catch (error) { 
        console.error(error);
      }
    }
  }
};
</script>