import http from "../http-common"

export default {
    getUserContacts() {
        return http.get('/profile/settings/user-contacts', {
            signal: AbortSignal.timeout(10000)
        })
    },

    getOperationType() {
        return http.get(`/profile/settings/opetaion-type`)
    },

    putOperationType(idOperation) {

        return http.put(`/profile/settings/opetaion-type`, {} ,{
            params: {
                operation_type_id: idOperation
            }
        })
    },

}