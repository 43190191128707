<template>
  <Breadcrumbs title="СОЗДАТЬ ПРОЕКТ" />

  <div class="container-fluid" v-if="statusMessage.show">
    <alert-success v-if="!statusMessage.isError" :text="statusMessage.text" />
    <alert-error v-if="statusMessage.isError" :text="statusMessage.text" />
  </div>

  <div class="container-fluid p-3 pt-1">
    <form action="#" @submit.prevent="onSubmitForm">
      <div class="mb-3">
        <label for="projectNameField" class="form-label">Название</label>
        <input
          type="text"
          class="form-control"
          id="projectNameField"
          v-model="formState.name"
        />
      </div>
      <div class="mb-3">
        <label for="projectCategoryField" class="form-label">Вид</label>
        <select
          id="projectCategoryField"
          class="form-select"
          v-model="formState.categoryId"
        >
          <template v-for="category in categories">
            <option :value="category.id" selected>{{ category.name }}</option>
          </template>
        </select>
      </div>
      <div class="mb-3">
        <label for="projectDescriptionField" class="form-label"
          >Краткое описание</label
        >
        <input
          type="text"
          class="form-control"
          id="projectDescriptionField"
          v-model="formState.shortDescription"
        />
      </div>
      <div class="mb-3">
        <label for="projectAuthorField" class="form-label">Автор</label>
        <input
          type="text"
          class="form-control"
          id="projectAuthorField"
          v-model="formState.author"
        />
      </div>
      <div class="mb-3">
        <label for="projectCityField" class="form-label">Город</label>
        <input
          type="text"
          class="form-control"
          id="projectCityField"
          v-model="formState.city"
        />
      </div>
      <div class="mb-3">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div class="col">
            <div class="mb-3">
              <label for="postHeaderField" class="form-label">Собрано</label>
              <input
                type="number"
                class="form-control"
                id="postHeaderField"
                v-model="formState.fundraisingVolume"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="postHeaderField" class="form-label"
                >Объём сборов</label
              >
              <input
                type="number"
                class="form-control"
                id="postHeaderField"
                v-model="formState.price"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="postHeaderField" class="form-label"
                >Дата начала сборов</label
              >
              <input
                type="date"
                class="form-control"
                id="postHeaderField"
                v-model="formState.fundraisingStartDate"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="postHeaderField" class="form-label"
                >Дата окончания сборов</label
              >
              <input
                type="date"
                class="form-control"
                id="postHeaderField"
                v-model="formState.fundraisingEndDate"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Подробное описание</label>
        <quill-editor
          v-model:value="formState.description.content"
          :options="formState.description.editorOption"
          @change="
            ({ quill, html, text }) => {
              formState.description._content = html;
            }
          "
        />
      </div>
      <div class="mb-3">
        <label class="form-label">Экспертиза</label>
        <quill-editor
          v-model:value="formState.expertise.content"
          :options="formState.expertise.editorOption"
          @change="
            ({ quill, html, text }) => {
              formState.expertise._content = html;
            }
          "
        />
      </div>
      <div class="mb-3">
        <label class="form-label d-block pb-1">Основное фото продукта</label>
        <input
          type="file"
          class="d-none"
          accept="image/*"
          id="postPreviewImageField"
          @change="onPreviewImageAdd"
        />
        <label class="btn btn-primary" for="postPreviewImageField">
          <i class="bi bi-upload"></i>
          Загрузить
        </label>
        <div
          class="accordion pt-2"
          :class="!formState.preview.file ? 'd-none' : ''"
        >
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                :class="!previewAccordion.isShow ? 'collapsed' : ''"
                aria-expanded="false"
                @click.prevent="previewAccordion.obj.toggle()"
              >
                Предварительный просмотр
              </button>
            </h2>
            <div
              class="accordion-collapse collapse"
              :ref="
                (el) => {
                  previewAccordion.ref = el;
                }
              "
            >
              <div class="accordion-body">
                <img
                  :src="formState.preview.preview"
                  style="max-height: 300px; width: auto; object-fit: cover"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label d-block pb-1">Фотографии продукта</label>
        <input
          type="file"
          class="d-none"
          accept="image/*"
          id="postImageField"
          @change="onImageAdd"
          multiple
        />
        <label class="btn btn-primary" for="postImageField">
          <i class="bi bi-upload"></i>
          Загрузить
        </label>
        <div class="accordion pt-2">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                :class="!imageAccordion.isShow ? 'collapsed' : ''"
                aria-expanded="false"
                @click.prevent="imageAccordion.obj.toggle()"
              >
                Галерея
              </button>
            </h2>
            <div
              class="accordion-collapse collapse"
              :ref="
                (el) => {
                  imageAccordion.ref = el;
                }
              "
            >
              <div class="accordion-body">
                <template v-if="formState.images.length !== 0">
                  <div
                    class="row overflow-auto flex-nowrap"
                    @wheel.prevent="
                      (e) => (e.currentTarget.scrollLeft += e.deltaY)
                    "
                  >
                    <div
                      class="col-auto"
                      v-for="(item, index) in formState.images"
                    >
                      <div class="card border-subtle-dark mb-2">
                        <img
                          :src="item.preview"
                          class="card-image-top"
                          style="height: 200px; object-fit: cover"
                          alt=""
                        />
                        <div class="card-body py-2 px-3">
                          <div class="row">
                            <div class="col">
                              {{ item.file.name }}
                            </div>
                            <div class="col-auto">
                              <button
                                style="border: none; background-color: initial"
                                @click.prevent="onImageDelete(index)"
                              >
                                <i class="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex justify-content-center text-center p-5">
                    Изображений нет
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label d-block pb-1">Документы продукта</label>
        <input
          type="file"
          class="d-none"
          :accept="
            [
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/rtf',
              'application/pdf',
            ].join(',')
          "
          @change="onDocumentAdd"
          id="postDocumentField"
          multiple
        />
        <label class="btn btn-primary" for="postDocumentField">
          <i class="bi bi-upload"></i>
          Загрузить
        </label>
        <div class="accordion pt-2">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                :class="!documentsAccordion.isShow ? 'collapsed' : ''"
                @click.prevent="documentsAccordion.obj.toggle()"
                type="button"
              >
                Список документов
              </button>
            </h2>
            <div
              class="accordion-collapse collapse"
              :ref="
                (el) => {
                  documentsAccordion.ref = el;
                }
              "
            >
              <div class="accordion-body">
                <template v-if="formState.documents.length !== 0">
                  <template v-for="(item, index) in formState.documents">
                    <div class="card border my-2 p-0">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-auto">
                            <i
                              class="bi bi-file-earmark"
                              style="font-size: xx-large"
                            ></i>
                          </div>
                          <div class="col">
                            <div class="d-flex h-100">
                              <div class="align-self-center">
                                {{ item.name }}
                              </div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="d-flex justify-content-center h-100">
                              <button
                                style="border: none; background-color: initial"
                                class="align-self-center"
                                @click.prevent="onDocumentDelete(index)"
                              >
                                <i class="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="d-flex justify-content-center text-center p-5">
                    Документов нет
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <button class="btn btn-primary" type="submit">Создать проект</button>
      </div>
    </form>
  </div>
</template>

<script setup>
//#region Imports
import { Collapse } from "bootstrap";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import ProjectsAPI from "@/services/ProjectsDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertError from "@/components/alert-error.vue";
//#endregion

//#region Client state
const formState = reactive({
  preview: {
    file: null,
    preview: null,
  },
  name: "",
  images: [],
  categoryId: 1,
  shortDescription: "",
  description: {
    content: "",
    _content: "",
    editorOption: {
      placeholder: "В начале было слово...",
    },
    disabled: false,
  },
  fundraisingVolume: 0,
  price: 0,
  fundraisingStartDate: "",
  fundraisingEndDate: "",
  author: "",
  city: "",
  documents: [],
  expertise: {
    content: "",
    _content: "",
    editorOption: {
      placeholder: "В начале было слово...",
    },
    disabled: false,
  },
});

const statusMessage = reactive({
  show: false,
  isError: false,
  text: "",
});

const categories = ref([]);

const router = useRouter();
//#endregion

//#region Preview image field
const previewAccordion = reactive({
  ref: ref(),
  obj: ref(),
  isShow: false,
});

onMounted(() => {
  previewAccordion.obj = new Collapse(previewAccordion.ref, {
    toggle: false,
  });
  previewAccordion.ref.addEventListener("show.bs.collapse", () => {
    previewAccordion.isShow = true;
  });
  previewAccordion.ref.addEventListener("hide.bs.collapse", () => {
    previewAccordion.isShow = false;
  });
});

function onPreviewImageAdd(event) {
  if (formState.preview.preview) {
    URL.revokeObjectURL(formState.preview.preview);
  }
  formState.preview = {
    file: event.target.files[0],
    preview: URL.createObjectURL(event.target.files[0]),
  };
  event.target.value = null;
}
//#endregion

//#region Image field
const imageAccordion = reactive({
  ref: ref(),
  obj: ref(),
  isShow: false,
});

onMounted(() => {
  imageAccordion.obj = new Collapse(imageAccordion.ref, {
    toggle: false,
  });
  imageAccordion.ref.addEventListener("show.bs.collapse", () => {
    imageAccordion.isShow = true;
  });
  imageAccordion.ref.addEventListener("hide.bs.collapse", () => {
    imageAccordion.isShow = false;
  });
});

function onImageAdd(event) {
  for (let file of event.target.files) {
    formState.images.push({
      file,
      preview: URL.createObjectURL(file),
    });
  }
  event.target.value = null;
}

function onImageDelete(index) {
  URL.revokeObjectURL(formState.images[index].preview);
  formState.images.splice(index, 1);
}
//#endregion

//#region Documents field
const documentsAccordion = reactive({
  ref: ref(),
  obj: ref(),
  isShow: false,
});

onMounted(() => {
  documentsAccordion.obj = new Collapse(documentsAccordion.ref, {
    toggle: false,
  });
  documentsAccordion.ref.addEventListener("show.bs.collapse", () => {
    documentsAccordion.isShow = true;
  });
  documentsAccordion.ref.addEventListener("hide.bs.collapse", () => {
    documentsAccordion.isShow = false;
  });
});

function onDocumentAdd(event) {
  for (let file of event.target.files) {
    formState.documents.push(file);
    console.log(file);
  }
}

function onDocumentDelete(index) {
  formState.documents.splice(index, 1);
}
//#endregion

//#region Network
async function onSubmitForm() {
  try {
    const {
      name,
      categoryId,
      shortDescription,
      fundraisingVolume,
      price,
      fundraisingStartDate,
      fundraisingEndDate,
      author,
      city,
    } = formState;
    const description = formState.description.content;
    const expertise = formState.expertise.content;
    let preview;
    try {
      preview = (await ProjectsAPI.adminUploadPreview(formState.preview.file))
        .data.data.preview;
    } catch (error) {
      console.log(error);

      let details;
      if (typeof error.response.data.message == "string")
        details = error.response.data.message;
      else if (typeof error.response.data.message == "object")
        details = _.join(error.response.data.message, ", ");

      statusMessage.isError = true;
      statusMessage.text = `Ошибка при загрузке основного фото: ${
        error.response.data.error ?? details
      }<br />
        <details>
            <summary>
                Дополнительная информация об ошибке:
                <br />
            </summary>
            <div
                class="div p-3 mt-2"
                style="background-color: #1c1c1c"
            >
                <code style="color: white">
                    ${details}<br />
                </code>
            </div>
        </details>
        `;
      statusMessage.show = true;
      return;
    }

    let images;

    try {
      images = (
        await ProjectsAPI.adminUploadImages(
          (() => {
            const imageList = [];
            for (const image of formState.images) {
              imageList.push(image.file);
            }
            return imageList;
          })()
        )
      ).data.data.images;
    } catch (error) {
      console.log(error);

      let details;
      if (typeof error.response.data.message == "string")
        details = error.response.data.message;
      else if (typeof error.response.data.message == "object")
        details = _.join(error.response.data.message, ", ");

      statusMessage.isError = true;
      statusMessage.text = `Ошибка при загрузке изображений: ${
        error.response.data.error ?? details
      }<br />
        <details>
            <summary>
                Дополнительная информация об ошибке:
                <br />
            </summary>
            <div
                class="div p-3 mt-2"
                style="background-color: #1c1c1c"
            >
                <code style="color: white">
                    ${details}<br />
                </code>
            </div>
        </details>
        `;
      statusMessage.show = true;
      return;
    }

    let documents, names;

    try {
      const res = (await ProjectsAPI.adminUploadFiles(formState.documents)).data
        .data[0];
      documents = res.documents;
      names = res.names;
    } catch (error) {
      console.log(error);

      let details;
      if (typeof error.response.data.message == "string")
        details = error.response.data.message;
      else if (typeof error.response.data.message == "object")
        details = _.join(error.response.data.message, ", ");

      statusMessage.isError = true;
      statusMessage.text = `Ошибка при загрузке документов: ${
        error.response.data.error ?? details
      }<br />
        <details>
            <summary>
                Дополнительная информация об ошибке:
                <br />
            </summary>
            <div
                class="div p-3 mt-2"
                style="background-color: #1c1c1c"
            >
                <code style="color: white">
                    ${details}<br />
                </code>
            </div>
        </details>
        `;
      statusMessage.show = true;
    }

    await ProjectsAPI.adminCreateProject({
      name,
      categoryId,
      shortDescription,
      description,
      expertise,
      fundraisingVolume,
      price,
      fundraisingStartDate,
      fundraisingEndDate,
      author,
      city,
      preview,
      images,
      documents,
      names,
    });

    statusMessage.isError = false;
    statusMessage.text = `Проект успешно создан. Перенаправление к списку проектов...`;
    statusMessage.show = true;

    setTimeout(() => {
      router.push("/vote/manage");
    }, 1000);
  } catch (error) {
    console.log(error);

    let details;
    if (typeof error.response.data.message == "string")
      details = error.response.data.message;
    else if (typeof error.response.data.message == "object")
      details = _.join(error.response.data.message, ", ");

    statusMessage.isError = true;
    statusMessage.text = `Ошибка при выполнении запроса: ${
      error.response.data.error ?? details
    }<br />
        <details>
            <summary>
                Дополнительная информация об ошибке:
                <br />
            </summary>
            <div
                class="div p-3 mt-2"
                style="background-color: #1c1c1c"
            >
                <code style="color: white">
                    ${details}<br />
                </code>
            </div>
        </details>
        `;
    statusMessage.show = true;
  }
}

onMounted(async () => {
  categories.value = (await ProjectsAPI.adminGetCategories()).data;
});
//#endregion
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
</style>
