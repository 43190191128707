<template>
  <Breadcrumbs title="ДОГОВОР"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 m-auto">
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">Дополнительное соглашение</h4>
            <h6 class="text-center">к опционному соглашению</h6>
            <p class="text-end">{{ date }}</p>
            <p>
              Общество с ограниченной ответственностью «Рантье Финанс групп», именуемое в дальнейшем "Руководящий
              партнер" или "Компания", с одной стороны и
              {{ userSender.lastName }} {{ userSender.firstName }} {{ userSender.middleName }}, именуемый в дальнейшем
              "Держатель сертификата", с другой стороны, а совместно именуемые Стороны, заключили дополнительное
              соглашение к опционному соглашению, указанному в заголовке настоящего документа, о нижеследующем:
            </p>
            <ol>
              <li>Держатель сертификата уведомляет Компанию о принятом им решении передать иному лицу сертификаты в
                количестве {{ count }} шт. и заключении с этим лицом соответствующего договора цессии.
              </li>
              <li>
                Новому держателю сертификата передаются все права требования к Компании, предусмотренные опционным
                соглашением, за исключением права на совершение операции «Погашение» (возврат сертификата Компании с
                получением возмещения его стоимости), которое прекращается с даты заключения настоящего дополнительного
                соглашения.
              </li>
              <li>
                В отношении оставшегося количества сертификатов, заключить новое опционное соглашение между Держателем
                сертификата и Компанией.
              </li>
              <li>
                  Изначальное опционное соглашение прекращает свое действие с даты заключения настоящего
                дополнительного соглашения.
              </li>
            </ol>
            <hr/>
            <h6 class="text-center">Адреса и реквизиты Партнеров</h6>
            <div class="row">
              <div class="col">
                <p class="mt-3">Компания:</p>
                <p>
                  ИНН 7743427684 КПП 774301001
                  <br />
                  ОГРН 1237700657754
                  <br />
                  125438, г. Москва, ул. Автомоторная, д. 1/3, стр. 2, пом. 7Н/6
                  <br />
                  Расчетный счет 40702810910001507287
                  <br />
                  Банк АО "ТИНЬКОФФ БАНК"
                  <br />
                  ИНН банка 7710140679
                  <br />
                  БИК банка 044525974
                  <br />
                  Корр. счет 30101810145250000974
                </p>
                <img src="https://rentier.world/img/pehat.png" alt=""
                     style="position: absolute;  z-index: -1; margin-top: 10px" width="20%">
                <br />
                <p>Генеральный директор</p>
                <p>________________/М.Б. Хорошко/</p>
              </div>
              <div class="col">
                <p class="mt-3">Держатель сертификата:</p>
                <p>
                 <span class="fw-bold">
                    {{ userSender.profile?.lastName }} {{
                     userSender.profile?.firstName
                   }} {{ userSender.profile?.middleName }}
                 </span> <br/>
                  Эл. почта:{{ userSender.email }} <br/>
                </p>
                <p>_______________ / {{ userSender.profile?.lastName }} {{ userSender.profile?.firstName }}
                  {{ userSender.profile?.middleName }} </p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";

export default {
  data() {
    return {
      date: new Date().toLocaleDateString('ru-Ru'),
      errorMessage: '',
      successMessage: '',
      count: 0,
      userSender: {},
      userRecipient: {},
    };
  },
  components: {
    AlertSuccess,
    AlertError
  },
  mounted() {
    this.count = JSON.parse(localStorage.getItem('count'));
    this.userSender = JSON.parse(localStorage.getItem('userSender'));
    this.userRecipient = JSON.parse(localStorage.getItem('userRecipient'));
  },
};
</script>