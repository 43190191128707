import http from "../http-common";

export default {
    getAll() {
        return http.get(`/meeting/all`);
    },
    getAllFinished() {
        return http.get(`/meeting/all/finished`);
    },
    getAllNotStarted() {
        return http.get(`/meeting/all/notStarted`);
    },
    getById(id) {
        return http.get(`/meeting/one/${id}`);
    },
    putMeeting(data) {
        return http.put("/meeting/update", data);
    },
    postMeeting(data) {
        return http.post("/meeting/create", data);
    },

    getTickets(){
        return http.get(`/meeting/tickets`);
    },

    fileUploadsMeetingTitle(file) {
        return http.post("/files/upload/meeting/titleImage", file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },

    fileUploadsMeetingPreview(file) {
        return http.post("/files/upload/meeting/previewImage", file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};
