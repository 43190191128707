<template>
  <div class="userService_wrapper">
    <div class="form-send col-xl-8 ">
      <p class="alert alert-warning modal-windows" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="alert alert-success modal-windows" v-if="successMessage">{{ successMessage }}</p>
      <form v-if="!isLoading" id="form" class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Рекомендовать клиента</h4>
          <div class="card-options">
            <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i
                class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)"
              data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="mb-3">
                <label class="form-label">Фамилия</label>
                <input disabled required v-model="formData.lastName" class="form-control" type="text"
                  :placeholder="servicesId.lastName" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="mb-3">
                <label class="form-label">Имя</label>
                <input disabled required v-model="formData.firstName" class="form-control" type="text"
                  :placeholder="servicesId.firstName" />
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="mb-3">
                <label class="form-label">Отчество</label>
                <input disabled v-model="formData.middleName" class="form-control" type="text"
                  :placeholder="servicesId.middleName" />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <label class="form-label">Телефон</label>
                <input disabled required v-model="formData.phone" class="form-control" type="tel"
                  :placeholder="servicesId.phone" />
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="validationCustom04">Статус</label>
              <select  class="form-select" id="validationCustom04" v-bind:class="formSubmitted ? (stateError ? 'is-invalid' : 'is-valid') : ''
                  " v-model="formPutData.status" required="">
                
                <option v-for="(item, index) in dataApi" :value="item.id" :key="index"
                  :selected="item.id === servicesId.status">
                  {{ item.name }}
                </option>
              </select>
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="mb-3">
                <label class="form-label">Цена</label>
                <input class="form-control" type="text" v-model="formPutData.price" :placeholder="servicesId.price" />
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="validationCustom04">Категории</label>
              <select v-if="servicesId.category"  disabled class="form-select" id="validationCustom04" v-bind:class="formSubmitted ? (stateError ? 'is-invalid' : 'is-valid') : ''
                  ">

                <option v-if="servicesId && servicesId.category.id === 1" :value="1">
                  Юрист
                </option>
                <option v-if="servicesId && servicesId.category.id === 2" :value="2">
                  Бухгалтер
                </option>
                <option v-if="servicesId && servicesId.category.id === 3" :value="2">
                  IT-технологии
                </option>
              </select>
              <div class="text-danger" v-else>
                Ошибка получения котегории
              </div>
            </div>
            <div class="col-md-12">
              <div>
                <label class="form-label">Описание</label>
                <textarea disabled required class="form-control" rows="5" :placeholder="servicesId.comment"
                  v-model="formData.comment"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <router-link class="btn btn btn-primary" to="" @click="putItService">Отправить</router-link>
          <!-- <button class="btn btn-primary" @click="postSendService" type="submit">Отправить</button> -->
        </div>
      </form>
      <div v-else class="card p-5">
        <div class="loader-cover mx-auto my-5"></div>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceITDataService from "@/services/ServiceITDataService.js";
import UserDataService from "@/services/UserDataService";
import { number } from "@intlify/core-base";

export default {
  name: "adminDetail",
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
      status: "",
      price: "",
      dataApi: [],
      servicesId: [],
      userid: [],
      formPutData: {
        price: number,
        status: number,
      },
      formData: {
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        comment: "",
      },
    };
  },
  mounted() {
    this.getCurrentServiceFromAdmin(this.$route.params.id);
  },
  methods: {
    getServices() {
      ServiceITDataService.getServices()
        .then(({ data }) => {
          this.dataApi = data;
          setTimeout(()=>{
            this.isLoading = false
          }, 1000)
        })
        .catch((e) => {
          console.log(e);
          setTimeout(()=>{
            this.isLoading = false
          }, 1000)
        });
    },


    async putItService() {
      
      let price = parseInt(this.formPutData.price);

      if (isNaN(price) || price < 0) {

        this.errorMessage = 'Цена должна быть не отрицательной'

        setTimeout(()=> {
          this.errorMessage = ''
        }, 3000)

        return;

      }

      let updatedData = {
        price: price,
        status: this.formPutData.status
      };


      ServiceITDataService.putItService(this.$route.params.id, updatedData)
        .then(() => {
          this.successMessage = 'Заявление успешно добавленно.';
          setTimeout(()=> {
            this.successMessage = ''
          }, 3000)

        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          setTimeout(()=> {
            this.errorMessage = ''
          }, 3000)
          console.log(error);
        });
    },




    getCurrentServiceFromAdmin(serviceId) {
      file:///home/Xristenko/%D0%97%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B8/index.jsx

      this.isLoading = true

      ServiceITDataService.getCurrentServiceFromAdmin(serviceId)
        .then(({ data }) => {
          this.servicesId = data;
          this.formPutData.price = data.price;
          this.formPutData.status = data.status.id;
          this.status = data.status.name;
          this.getServices();
        })
        .catch((e) => {

          setTimeout(()=>{
            this.isLoading = false
          }, 1000)

          console.log(e);
        });
    },
    getUser() {
      UserDataService.get()
        .then(({ data }) => {
          this.userid = data.id;
          console.log("USER", userid);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.wrapper-table {
  margin: 30px 10px 50px 5px;
}

.knowledgebase-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 40px;
}

.userService_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.knowledgebase {
  display: flex;
  background: url(@/assets/images/knowledgebase/bg_1.jpg) center center/cover no-repeat;
}

@media (max-width: 768px) {
  .knowledgebase {
    display: flex;
    background: none;
  }
}

.form-send {
  margin: 20px auto;
}

.right_knowledgebase {
  height: 100%;
}

.user_form {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>