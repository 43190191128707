<template>
    <Breadcrumbs title="Поддержка" />

    <div class="px-2 pb-3">
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group me-2" role="group" aria-label="Панель сотрудника">
                <button type="button" class="btn btn-primary" @click="loadChatList()">
                    Обновить список чатов
                </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Панель чата" v-if="chat.id">
                <button type="button" class="btn btn-primary" @click="loadChat(chat.user.id)">
                    Обновить текущий чат
                </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Панель чата" v-if="chat.id">
                <button type="button" class="btn" :class="chat.questionCompletionTime ? 'btn-success' : 'btn-primary'"
                    @click.stop.prevent style="cursor: default;">
                    Статус: {{ !chat.questionCompletionTime ? 'Нерешенный' : 'Решенный' }}
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col call-chat-sidebar col-sm-12">
            <div class="card">
                <div class="card-body chat-body">
                    <div class="chat-box">
                        <div class="chat-left-aside">
                            <div class="p-2">
                                <h5>Список пользователей</h5>
                            </div>

                            <div class="p-2 pt-0">
                                <select class="form-select" aria-label="Выберите фильтр" v-model="filterState">
                                    <option value="all">Все чаты</option>
                                    <option value="active">Активные чаты</option>
                                    <option value="inactive">Неактивные чаты</option>
                                </select>
                            </div>

                            <div class="people-list" id="people-list">
                                <ul class="list custom-scrollbar">
                                    <li class="clearfix" v-for="(chat, index) in _.filter(chatList, (o) => {
                    switch (filterState) {
                        case 'active':
                            return !o.questionCompletionTime

                        case 'inactive':
                            return o.questionCompletionTime

                        case 'all':
                            return true
                    }

                })" :key="index" @click="loadChat(chat.user.id)">
                                        <!-- <img class="rounded-circle user-image" :src="getImageUrl(user.thumb)" alt="" /> -->
                                        <div class="about">
                                            <div class="name">{{ `${chat.user.profile.firstName}
                                                ${chat.user.profile.middleName} ${chat.user.profile.lastName}` }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col call-chat-body">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row chat-box">
                        <div class="col pe-0 chat-right-aside">
                            <div class="chat">
                                <div class="chat-header clearfix">
                                    <!-- Здесь аватарка -->
                                    <!-- <img class="rounded-circle" v-if="currentchat.thumb" :src="getImageUrl(currentchat.thumb)"
                                    alt="" /> -->
                                    <div class="about">
                                        <div class="name">

                                            <template v-if="chat.user">
                                                {{ chat.user.profile.firstName }}
                                                {{ chat.user.profile.middleName }}
                                                {{ chat.user.profile.lastName }}
                                            </template>
                                            <!-- <span class="font-primary f-12 ms-1">печатает...</span> -->
                                        </div>
                                        <!-- <div class="status">
                                            
                                        </div> -->
                                    </div>
                                    <ul class="list-inline float-start float-sm-end chat-menu-icons">
                                        <li class="list-inline-item toogle-bar"
                                            @click.prevent="chatmenutoogle = !chatmenutoogle">
                                            <!-- <a href="#"><i class="icon-menu"></i></a> -->
                                        </li>
                                    </ul>
                                </div>
                                <div class="chat-history chat-msg-box custom-scrollbar" id="chatInput" ref="chatInput">
                                    <div id="msg-list">
                                        <div v-for="( message, index ) in  chat.messages " :key="index"
                                            v-bind:class="{ clearfix: message.user.id != chat.user.id }">
                                            <div class="message" v-bind:class="{
                    'other-message pull-right': message.user.id != chat.user.id,
                    'my-message': message.user.id == chat.user.id,
                    'text-end': message.user.id != chat.user.id
                }">
                                                <!-- chat.user.id -->
                                                <!-- Здесь тоже аватарка -->
                                                <!-- <img class="rounded-circle float-start chat-user-img img-30 text-end" alt=""
                                                v-if="currentchat.thumb && chat.user.id != 0"
                                                v-bind:src="" /> -->
                                                <!-- <img class="rounded-circle float-end chat-user-img img-30" alt=""
                                                v-if="chat.user.id == 0" v-bind:src="getImageUrl('user/1.jpg')" /> -->

                                                {{ message.message }}
                                                <div class="message-data text-start"
                                                    v-bind:class="{ 'text-end': message.user.id != chat.user.id }">
                                                    <span class="message-data-time">
                                                        {{
                    moment(message.created_at).format('DD.MM.YYYY HH:mm') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal for notifications -->
    <div class="modal fade" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="infoModalLabel">{{ infoModalState.title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{ infoModalState.text }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" v-if="infoModalState.secondaryActionText"
                        @click="infoModalState.secondaryActionCallback">{{
                    infoModalState.secondaryActionText }}</button>
                    <button type="button" class="btn btn-primary" v-if="infoModalState.primaryActionText"
                        @click="infoModalState.primaryActionCallback">{{
                        infoModalState.primaryActionText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
//#region Imports
// Libraries
import _ from 'lodash';
import moment from 'moment';

// Frameworks
import { onMounted, reactive, ref } from 'vue';
import { Modal } from 'bootstrap';

// API bindings
import SupportAPI from '../../services/AdminSupportDataService';
//#endregion

//#region Client
// Modal window state
const infoModalState = reactive({
    obj: null,
    title: '',
    text: '',
    primaryActionText: '',
    primaryActionCallback: () => { },
    secondaryActionText: '',
    secondaryActionCallback: () => { }
});

const switchModalState = reactive({
    obj: null,
    switchToId: 0
});

const filterState = ref('all');
// Message box state
const messageBox = ref('');

// Trigger modal with certain content and action callbacks
function triggerModal(title, text, primaryActionText, primaryActionCallback, secondaryActionText, secondaryActionCallback) {
    infoModalState.title = title;
    infoModalState.text = text;
    infoModalState.primaryActionText = primaryActionText;
    infoModalState.primaryActionCallback = primaryActionCallback;
    infoModalState.secondaryActionText = secondaryActionText;
    infoModalState.secondaryActionCallback = secondaryActionCallback;
    infoModalState.obj.show();
}

// Scroll message list to bottom
function scrollToBottom() {
    const msgListEl = document.getElementById('chatInput');
    msgListEl.scrollTop = msgListEl.scrollHeight;
}

// Handler of message sending event
async function onSendMessage() {
    await SupportAPI.sendMessage(chat.value.user.id, messageBox.value);
    socket.emit('message', {
        userId: profile.value.user.id,
        chatId: chat.value.id,
        message: messageBox.value,
    });
    await loadChatWithoutSocket(chat.value.user.id);
    await loadChatList();
    messageBox.value = '';
}
//#endregion

//#region Network
const staffList = ref([]);
const profile = ref({});
const chatList = ref([]);
const chat = ref({});

async function loadChatList() {
    const res = await SupportAPI.getAllChats();
    chatList.value = res.data;
}

async function loadChat(id) {
    const res = await SupportAPI.getOneChat(id);
    chat.value = res.data;
    setTimeout(() => {
        scrollToBottom();
    }, 50);
}
//#endregion

onMounted(async () => {
    // Init info modal
    infoModalState.obj = new Modal(document.getElementById('infoModal'), {
        backdrop: true,
        focus: true,
        keyboard: true
    });

    await loadChatList();
});
</script>