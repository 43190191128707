import http from "../http-common";

export default {

    postPartnerForm(body) {
        return http.post(`/partners`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    getParents() {
        return http.get(`/partners`)
    }

}

