<template>
  <head>
    <title>Рантье</title>
  </head>
  <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
        <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix>
      </filter>
    </svg>
  </div>
  <router-view v-else></router-view>
  <support-badge />
</template>

<script>
import supportBadge from './components/support-badge.vue';

export default {
  name: 'App',
  data() {
    return {
      loading: true,
      frontUrl: process.env,
    }
  },
  provide: {
    backendURL: process.env.VUE_APP_BACKEND_API_BASE
  },
  components: {
    supportBadge
  },
  watch: {
    '$route'() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
      this.loading = !this.loading
    }
  },
  methods: {
    

  },
  mounted() {
    this.loading = false;

    (function (w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + (Date.now() / 60000 | 0);
      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn-ru.bitrix24.ru/b22833232/crm/site_button/loader_4_pacebw.js');

  }
}
</script>

