<template>
<div class="wrapper-count-unread-messages" v-if="countUnreadMessages>=1">
  {{ countUnreadMessages }}
</div>
</template>

<script>
export default {
  name: "countUnreadMessages",
  props:{
    countUnreadMessages:{
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>
.wrapper-count-unread-messages{
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 10.5px;
  color: rgba(84, 186, 74, 1);
  background: rgba(84, 186, 74, 0.25);
}
</style>