<template>
  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="project-box">
        <span class="badge bg-success">Доступен</span>
        <h6>Платформа "Рантье"</h6>
        <p>Сертификаты выпущены в ограниченном количестве</p>
        <div class="row details">
          <div class="col-6"><span>Выпущено </span></div>
          <div class="col-6 font-primary">{{ format(countCertificate) }}</div>
          <div class="col-6"><span>Доступно</span></div>
          <div class="col-6 font-primary">{{ format(countCertificateAvailable) }}</div>
          <div class="col-6"><span>Текущая стоимость</span></div>
          <div class="col-6 font-primary">{{ format(certificatePrice) }} &#8381;</div>
        </div>
        <div class="project-status mt-4 d-flex flex-column">
          <div class="d-flex mb-0">
            <p>{{ balance }}%</p>
            <div class="flex-grow-1 text-end"><span>Осталось</span></div>
          </div>
          <div class="progress" style="height: 5px">
            <div
              class="progress-bar-animated progress-bar-striped bg-success"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="'width:' + balance + '%;'"
            ></div>
          </div>
          <div class="d-flex">
            <router-link class="btn btn btn-primary mt-3" to="/investment/one">
              Купить
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CertificateDataService from "@/services/CertificateDataService";

export default {
  name: "allPage",
  data() {
    return {
      countCertificate: Number,
      countCertificateAvailable: Number,
      certificatePrice: Number,
      balance: Number,
    };
  },
  methods: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '\$1 ').replace(/(\.\d+)\s+/g, '\$1'),
  },
  mounted() {
    CertificateDataService.get()
      .then((response) => {
        const certificate = response.data;
        this.countCertificate = certificate.countCertificate.value;
        this.countCertificateAvailable =
          certificate.countCertificateAvailable.value;
        this.certificatePrice = certificate.certificatePrice.value;

        this.balance = Math.round(
          (this.countCertificateAvailable * 100) / this.countCertificate
        );
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>