<template>
  <div>
    <div>
      <div class="card-header card-no-border d-flex justify-content-between align-items-center">
        <h5>Статистика пользователей</h5>
        <div class="wrapper-switch d-flex">
          <input type="radio" class="btn-check" v-model="selectedOption" :value="1" name="options-base" id="option1" autocomplete="off" checked>
          <label class="btn-switch m-0 p-2 cursor-pointer" for="option1">Активы</label>

          <input type="radio" class="btn-check" v-model="selectedOption" :value="2" name="options-base" id="option2" autocomplete="off">
          <label class="btn-switch m-0 p-2 cursor-pointer" for="option2">Динамика</label>
        </div>
      </div>
      <div class="card-body pt-0">
        <div v-if="!isLoadingStatistics && !isError" class="row m-0 overall-card">
          <div class="col-xl-9 col-md-12 col-sm-7 p-0">
            <div class="chart-right">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card-body p-0">

                    <div class="current-sale-container">
                      <apexchart height="305" type="bar" :options="apexDashboard.options" :series="apexDashboard.series"></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-12 col-sm-5 p-0">
            <div class="row g-sm-4 g-2">
              <div class="col-xl-12 col-md-6">
                <div v-for="(item, index) in overallbalance" :key="item.iconName" class="item-slide w-100"
                     v-show="selectedOption === index + 1"
                     :class="{ 'active-slide': selectedOption === index + 1, 'disable-slide': selectedOption !== index + 1 }">
                  <div class="light-card balance-card widget-hover d-flex justify-content-center align-items-center p-4">
                    <div class="me-3" :class="'svg-box ' + 'svg-icon-' + item.iconName">
                      <img :src="getIconPath(item.iconName)" alt="icon" />
                    </div>
                    <div>
                      <span class="f-light">{{ item.title }}</span>
                      <h6 class="mt-1 mb-0 d-flex amount" v-html="item.amount"></h6>
                    </div>
                    <div class="ms-auto text-end">
                      <span :class="item.balanceClass">{{ item.balance }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-for="item in overallbalanceTotal" :key="item" class="col-xl-12 col-md-6 items">
                <div class="light-card balance-card widget-hover d-flex justify-content-center align-items-center w-100 p-4">
                  <div class="me-3" :class="'svg-box ' + 'svg-icon-' + item.iconName">
                    <img :src="getIconPath(item.iconName)" alt="icon" />
                  </div>
                  <div>
                    <span class="f-light">{{ item.title }}</span>
                    <h6 class="mt-1 mb-0 amount">{{ item.amount }}</h6>
                  </div>
                  <div class="ms-auto text-end">
                    <span :class="item.balanceClass">{{ item.balance }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isError" class="d-flex justify-content-center align-items-center w-100 h-100 py-5">
          <p>
            Что-то пошло не так...
          </p>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center w-100 h-100 py-5">
          <div class="loader-cover"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  overallbalance,
  overallbalanceTotal,
  fetchOverAllBalance,
  isLoading,
  isError
} from "@/data/settings"

import { apexDashboard, ecomDashboard } from "@/data/faunders"

export default {
  props:{
    overallbalance: {
      type: Object
    },
    overallbalanceTotal: {
      type: Object
    },
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      isLoadingStatistics: ecomDashboard.isLoading || null,
      isError: ecomDashboard.isError || null,
      selectedOption: parseInt(localStorage.getItem('statisticsParameters') || 1),
    };
  },
  watch: {
    selectedOption(newVal) {
      localStorage.setItem('statisticsParameters', newVal)
    },
  },
  methods: {
    getIconPath(iconName) {
      return require(`@/assets/svg/${iconName}.svg`);
    },

    async loadData(){

      // this.isLoadingStatistics = true;
      // this.isError = false;
      //
      // const currentDate = new Date();
      // const selectedDateStart = new Date(currentDate);
      // selectedDateStart.setMonth(currentDate.getMonth() - 1);
      //
      // try {
      //
      //   await fetchOverAllBalance(this.selectedDateStart, this.selectedDateEnd);
      //
      // } catch (error) {
      //   console.error("Ошибка при загрузке данных:", error);
      //   this.isError = true;
      // } finally {
      //
      //   this.isLoadingStatistics = isLoading || false;
      //   this.isError = isError || false;
      // }
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>


