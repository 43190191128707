<template>
    <Breadcrumbs title="ПРОЕКТЫ" />

    <div class="container-fluid p-2">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4">
            <template v-if="list.length != 0">
                <template v-for="item in list">
                    <div class="col">
                        <div class="card">
                            <div class="product-box">
                                <div class="product-img">
                                    <div class="ratio ratio-1x1">
                                        <img class="img-fluid" :src="`${backendURL}/${item.preview}`"
                                            style="object-fit: cover;" />
                                    </div>

                                </div>
                                <div class="product-details">
                                    <p class="text-muted">
                                        {{ item.category.name }}
                                    </p>
                                    <h4>
                                        {{ item.name }}

                                    </h4>
                                    <p>
                                        {{ item.shortDescription }}
                                    </p>
                                    <div class="product-price">
                                        <!-- <span>Собрано {{ item.fundraisingVolume }} / </span> -->
                                        {{ item.price }} &#8381;
                                    </div>
                                    <div class="d-flex mt-2">
                                        <button class="btn btn-primary p-1 flex-fill"
                                            @click="router.push(`/vote/${item.id}`)">Подробнее</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                Проектов нет
            </template>
        </div>
    </div>

</template>

<script setup>
//#region Imports
import _ from 'lodash';
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import ProjectsAPI from '@/services/ProjectsDataService';
//#endregion

//#region Types
/**
 * @typedef Project
 * @property {Number} id
 * @property {String} name
 * @property {Object} category
 * @property {String} category.name
 * @property {String} shortDescription
 * @property {Number} fundraisingVolume
 * @property {Number} price
 * @property {String} preview
 */
//#endregion

//#region Client state
/**
 * @type {import('vue').Ref<Array<Project>>}
 */
const list = ref([])
const backendURL = computed(() => process.env.VUE_APP_BACKEND_API_BASE)
const router = useRouter()
//#endregion

//#region Hooks
onMounted(async () => {
    list.value = (await ProjectsAPI.get(0, 100)).data
})
//#endregion
</script>