<template>
  <div v-if="user" class="contact card-wrapper">

    <div class="contact-title pb-4">
      <h6>Контакты</h6>
    </div>

    <div class="contact-item d-flex justify-content-between pb-3">
      <div class="contact-item-left gray">
        Телефон
      </div>
      <div class="contact-item-right purple">
        {{user.profile?.phone ? user.profile.phone : 'отсутствует'}}
      </div>
    </div>

    <div class="contact-item d-flex justify-content-between pb-3">
      <div class="contact-item-left gray">
        Почта
      </div>
      <div class="contact-item-right purple">
        {{user.email}}
      </div>
    </div>

    <div class="contact-item d-flex justify-content-between pb-3">
      <div class="contact-item-left gray">
        Телеграм
      </div>
      <div class="contact-item-right purple">
        {{ user.profile?.telegram ? user.profile.telegram : 'отсутствует'}}
      </div>
    </div>

    <div class="contact-item d-flex justify-content-between pb-3">
      <div class="contact-item-left gray">
        Курс
      </div>
      <div class="contact-item-right purple">
        {{ user.rank?.name ? user.rank.name : 'отсутствует'}}
      </div>
    </div>

    <div class="contact-item d-flex justify-content-between pb-3">
      <div class="contact-item-left gray">
        Пригласивший пользователь
      </div>
      <div class="contact-item-right purple">
        {{ user.parent?.profile?.fio ? user.parent.profile.fio : 'отсутствует'}}
      </div>
    </div>
    <div class="contact-item d-flex justify-content-between">
      <div class="contact-item-left gray">
        Роль
      </div>
      <div class="contact-item-right purple">
        {{ user.role?.name ? user.role.name : 'отсутствует'}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  methods: {

  }
}
</script>

<style scoped>
.gray{
  color: rgba(137, 137, 137, 1);
}
.purple{
  color: var(--theme-deafult) !important;
}
</style>