<template>
    <div>
        <Breadcrumbs title="ТЕСТЫ" />
        <div class="col-md-12 project-list">
            <div class="card">
                <div class="row">
                    <div class="col-md-6 d-flex">
                        <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
                            <li class="nav-item">
                                <h5>ТЕСТЫ</h5>
                            </li>


                        </ul>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-0 me-0"></div>
                        <router-link class="btn btn-success" to='/tests/admin/add'>
                            <vue-feather class="me-1" type="plus-square"></vue-feather>
                            Добавить
                        </router-link>
                    </div>

                </div>
            </div>
        </div>
        <div class="test-mobile">

            <div class="default-according mt-5" id="accordion">
                <div class="card" v-for="(item, index) of data" :key="index">
                    <div class="card-header" :id="'heading' + index">
                        <h5 class="mb-0">
                            <button class="btn btn-link wrapper" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse' + index" aria-expanded="true"
                                :aria-controls="'collapseOne' + index">
                                <div class="btn-inner">
                                    <div class="btn-inner-text">

                                        <h5>{{ item.course.name }}</h5>
                                        <span></span>

                                    </div>

                                    <vue-feather class="arrow-down" type="chevron-down"></vue-feather>
                                </div>

                            </button>
                        </h5>
                    </div>
                    <div class="collapse" :id="'collapse' + index" :aria-labelledby="'heading' + index"
                        data-bs-parent="#accordion">
                        <div class="card-body card-body-text">
                            <table class="table table-mobile table-hover d-flex justify-content-between align-item-center">
                                <div class="d-flex">
                                    <div class="table-inner">
                                        <span> кол-во вопросов </span>
                                        <span> {{ item.numberOfQuestions }} </span>
                                    </div>
                                    <div class="table-inner">
                                        <span>Кол-во правильных ответов </span>
                                        <span> {{ item.numberOfCorrectAnswers }} </span>
                                    </div>   
                                </div>
                                <div class="table-inner">
                                    <router-link class="btn" :to='"/tests/admin/edit/" + item.id'>
                                        <vue-feather class="me-1" type="edit"></vue-feather>
                                    </router-link>
                                </div>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <table class="table tables table-hover test-dekstop ">
            <thead class="table-thead">
                <tr class="table-col" >
                    <th scope="col" class="table-item">Название</th>
                    <th scope="col" class="table-item">Модуль</th>
                    <th scope="col" class="table-item">Кол-во вопросов</th>
                    <th scope="col" class="table-item">Кол-во правильных ответов</th>
                    <th scope="col" class="table-item"></th>
                </tr>
            </thead>
            <tbody style="display: table-row-group">
                <tr class="table-body" v-for="(item, index) of data" :key="index">
                    <td class="table-item">{{ item.id }}</td>
                    <td class="table-item">{{ item.course.name }}</td>
                    <td class="table-item">{{ item.numberOfQuestions }}</td>
                    <td class="table-item">{{ item.numberOfCorrectAnswers }} 
                    </td>
                    <td class="table-item">
                        <router-link class="btn" :to='"/tests/admin/edit/" + item.id'>
                            <vue-feather class="me-1" type="edit"></vue-feather>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

</template>


<script>
import TestingDataService from '@/services/TestingDataService';

export default {
    data() {
        return {
            data: null
        };
    },
    methods: {
        getAllTests(){
            TestingDataService.getAllTests()
            .then(( response ) => {

                this.data = response.data
                console.log(this.data);
            }
            ) .catch ((error) => {
                console.log(error);
            })
        }
    },
    mounted() {
        this.getAllTests()
    }
}
</script>


<style scoped>
@media (max-width: 992px) {
    .test-dekstop {
        display: none !important;
    }

    .default-according {
        display: block;
    }

    .test-mobile {
        display: block;
    }
}

@media (min-width: 992px) {
    .test-mobile {
        display: none;
    }
}

.btn-inner {
    display: flex;
    justify-content: space-between;
}

.btn-inner-text {
    display: flex;
    gap: 30px;
    align-items: center;
}

.table-inner {
    display: flex;
    flex-direction: column;

}

.table-mobile{
    gap: 50px;
    display: flex; 
}

.table-item{

    width:1%;
}
.tables {
  display: block;
}

</style>