import http from "../http-common";

export default {
    get() {
        return http.get('/ranks');
    },

    getNextRank() {
        return http.get('/ranks/next');
    },

}

