<template>
  <div class="container-fluid">
    <div class="">
      <div class="blog-single">
        <alert-error v-if="errorMessage" :text="errorMessage" />
        <alert-success v-if="successMessage" :text="successMessage" />

        <div class="blog-box blog-details">
          <router-link :to="'/events/list/' + event.id">
            <div class="detailEventImg">
              <img
                class="img-fluid sm-100-w"
                :src="this.FRONT_URL + event.previewImagePath"
                alt=""
              />
            </div>
          </router-link>

          <div class="blog-details">
            <ul class="blog-social">
              <li>
                <div v-if="event.meetingDateEnd">
                  {{ formattedDate(event.meetingDateStart) }} -
                  {{ formattedDate(event.meetingDateEnd) }}
                </div>
                <div v-else>
                  {{ formattedDate(event.meetingDateStart) }}
                </div>
              </li>
              <li>
                <i class="icofont icofont-user"></i
                ><span>{{ event.speaker }} </span>
              </li>
              <li>
                <span>{{ event.status?.name }}</span>
              </li>
              <li>
                <span v-if="event.price">Цена: {{ event.price }} ₽</span>
                <span v-else>Бесплатное участие</span>
              </li>
            </ul>
            <hr />
            <div class="mt-3" v-if="event.isOnline">
              <a
                v-if="event.meetingRecordUrl"
                :href="event.meetingRecordUrl"
                target="_blank"
                class="btn btn-light"
                >Запись мероприятия</a
              >
              <a
                v-else
                v-if="event.meetingPlace"
                :href="event.meetingPlace"
                target="_blank"
                class="btn btn-light"
                >Подключиться</a
              >
            </div>
            <div v-else class="mt-3">
              <p class="mt-0">{{ event.meetingPlace }}</p>
              <a
                v-if="event.meetingRecordUrl"
                :href="event.meetingRecordUrl"
                class="btn btn-light"
                target="_blank"
                >Запись мероприятия</a
              >
            </div>
            <h4 class="m-20 event_topic">{{ event.topic }}</h4>
            <div v-if="event.price" class="border p-3">
              <div class="form-inline">
                <div class="m-2">
                  <button
                    class="btn btn btn-primary"
                    type="button"
                    v-if="!isCode"
                    @click="clickPayment"
                  >
                    Купить билет
                  </button>
                </div>
                <div class="mt-2 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="isAmountAccount"
                    :disabled="isCode"
                    v-model="isAmountAccount"
                  />
                  <label class="form-check-label" for="isAmountAccount"
                    >Зачесть в погашение 100% стоимости средства со внутреннего
                    счета.
                    <a
                      href="../documents/internal-credit"
                      target="_blank"
                      v-if="isAmountAccount"
                      >Заявление на зачет.</a
                    ></label
                  >
                </div>
              </div>
              <div class="form-inline row mt-2" v-if="isCode">
                <div class="row">
                  <div class="col-3">
                    <input
                      type="text"
                      class="form-control"
                      id="isCode"
                      placeholder="Код"
                      v-model="validate.code"
                    />
                  </div>
                  <button
                    class="btn btn btn-primary col-3"
                    type="button"
                    @click="codeValidate"
                  >
                    Подтвердить операцию
                  </button>
                </div>
                <p>
                  Для подтверждения операции введите код. Он направлен на Вашу
                  почту.
                </p>
              </div>
            </div>
            <div
              class="single-blog-content-top p-20 event_topic"
              v-html="event.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingDataService from "@/services/MeetingDataService";
import moment from "moment";
import "moment/locale/ru";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      idSystemPayment: 0,
      isCode: false,
      validate: {
        id: Number,
        code: "",
      },
      paymentData: {
        name: "Покупка билета",
        summa: 0,
        typePayment: 4,
        idNodeBuy: 0,
        isAmountAccount: false,
      },
      isAmountAccount: false,
      event: {},
    };
  },
  components: { AlertSuccess, alertError },
  mounted() {
    this.getById();

    MoneyDataService.getIdPaymentService()
      .then((response) => {
        this.idSystemPayment = Number(response.data.idSystemPayment);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    getById() {
      MeetingDataService.getById(this.$route.params.id)
        .then(({ data }) => {
          this.event = data;
          this.paymentData.summa = this.event.price;
          this.paymentData.idNodeBuy = this.event.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickPayment() {
      this.isCode = true;
      OperationsDataService.get({ typeOperation: 4 }).then((response) => {
        this.validate.id = response.data;
      });
    },
    codeValidate() {
      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.choiceAcquiring();
        else
          this.errorMessage =
            "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
      });
    },
    choiceAcquiring() {
      localStorage.setItem("paymentData", JSON.stringify(this.paymentData));

      if (this.isAmountAccount) this.makePaymentRedirect();
      if (this.idSystemPayment === 0 && !this.isAmountAccount)
        window.location.href = "/my/payments/cloudPayments";
      if (this.idSystemPayment === 1 && !this.isAmountAccount)
        this.makePaymentRedirect();
    },
    makePaymentRedirect() {
      MoneyDataService.payment({
        idNodeBuy: this.paymentData.idNodeBuy,
        typePayment: 4,
        selectPaymentSystem: 1,
        isAmountAccount: this.isAmountAccount,
        summa: 0,
      })
        .then((response) => {
          if (this.isAmountAccount)
            this.successMessage = "Благодарим за покупку.";
          else {
            window.location.href = response.data.url;
            this.successMessage = "Оплатите на сайте банка.";
          }
        })
        .catch((e) => {
          this.errorMessage = "Ошибка приобретения. " + e.response.data.message;
          console.log(e);
        });
    },

    formattedDate(date) {
      moment.locale("ru");

      if (date === this.event.meetingDateStart) {
        return moment(date).format("D MMMM HH:mm");
      } else {
        return moment(date).format("D MMMM HH:mm");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event_topic {
  width: 100%;
  word-break: break-all;
}

.detailEventImg {
  width: 100%;
}

.detailEventImg img {
}
</style>
