<template>
  <div class="alert alert-warning" role="alert">
    <span v-html="text"></span>
  </div>
</template>

<script>
export default {
  name: 'alert-warning',
  props: {
    text: {
      type: String,
      required: true,
    },
  }
}
</script>