<template>
  <div>
    <Breadcrumbs title="МОЯ СЕТЬ"/>
    <!-- Индикатор загрузки -->
    <div v-if="isLoading" class="loading-indicator">
      <p>Загрузка...</p>
      <div class="spinner"></div> <!-- Можете использовать CSS для стилизации спиннера -->
    </div>
    <div v-else class="container-fluid wrapper-network">
        <div class="container">
          <div class="modal" v-if="isOpenModal"  >
            <div class="modal-overlay" @click="closeModal"></div>
            <div class="modal-content" :class="{ 'slide-enter-top': isOpenModal}">
              <div class="close">
                <img src="../../assets/images/network/Close.svg" alt="Close" @click="closeModal">
              </div>
              <modal @close="closeModal"
                     :fio="this.networkUser.profile.fio"
                     :email="this.networkUser.email"
                     :phone="this.networkUser.profile.phone"
                     :path="this.networkUser.profile.mediumAvatar"
                     :rank="this.networkUser.rank && this.networkUser.rank.name ? this.networkUser.rank.name : 'Не указано'"
                     :rankId="this.networkUser.rank && this.networkUser.rank.id && this.networkUser.rank.id ? this.networkUser.rank.id : null"
                     :id="networkUser.id" />
<!--              :backgroundImage="this.networkUser.profile.backgroundImage"-->
            </div>
          </div>
          <div class="row">
            <div  class="item-card-lending col-xxl-6 col-xl-6 col-md-12">
              <div class="card-item d-flex justify-content-between">
                <div class="left">
                  <div class="title">
                    Личный кабинет, регистрация
                  </div>
                  <div class="desription">
                    Ваша персональная ссылка для регистрации нового пользователя
                    <div>
                      <a href="javascript:{}" @click.stop="copyLink">
                        <code>
                          https://rentier.world/my/auth/register?start={{ referralCode }}</code><br/>
                        <br/>
                        Копировать <i class="icofont icofont-copy-alt"></i><br/>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="prev">
                    Перейти
                  </div>
                </div>
              </div>
            </div>
            <div  class="item-card-lending col-xxl-6 col-xl-6 col-md-12">
              <div class="card-item d-flex justify-content-between">
                <div class="left">
                  <div class="title">
                    Лендинг
                  </div>
                  <div class="desription">
                    Ваша персональная ссылка для регистрации нового пользователя
                    <div>
                      <a href="javascript:{}" @click.stop="copyLink2">
                        <code>
                          https://rentier.city/?start={{ referralCode }}</code><br/>
                        <br/>
                        Копировать <i class="icofont icofont-copy-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="prev">
                    Перейти
                  </div>
                </div>
              </div>
            </div>
           </div>

            <div >
              <IncomeChart />
            </div>


          <div  class="col-12 mt-4 mb-5">
              <div class="card-item-network">
                <div class="left">
                  <div class="title d-flex justify-content-between">
                    <div class="title-left">
                      Моя сеть
                    </div>
                    <div class="title-right">
                      <vue-feather
                          @mouseover="showPopover=true" @mouseleave="showPopover=false"
                          class="status_toggle middle sidebar-toggle"
                          type="help-circle"
                      ></vue-feather>
                      <div v-show="showPopover" class="popover">
                        <div class="title-popover">
                          СПРАВКА
                        </div>
                        <div class="description-popover">
                          Если поле <span class="activateUser">"Имя"</span> подсвечено зеленым цветом, то пользователь является активным, если черным - не активным. Цифра, которая находится рядом с именем пользователя отображает количество всей сети конкретного пользователя.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-wrapper pt-3">
                  <div class="title-accordion d-flex">`
                    <div class="title-accordion-item item-accordion-one">Имя</div>
                    <div class="title-accordion-item mobile">Приватность</div>
                    <div class="title-accordion-item">Кол-во партнеров</div>
                  </div>

                  <div v-for="(item, index) in network.children" :key="item.id">
                    <div :class="item.id === this.currentIndex ? 'wrapper-item-accordion-gray' : ''" class="wrapper-item-accordion d-flex align-items-center pb-2">
                    <div class="item-accordion item-accordion-one d-flex" @click="OpenModal(item.id, item.profileSettings[0].isEnable)">
                      <div class="item-accordion-images d-flex">
                        <div v-if=" item.profileSettings[0].isEnable || item.profile.mediumAvatar === null" class="avatar-circle">
                          {{ getFirstLetter(item.profile.fio) }}
                        </div>
                        <img :src="this.FRONT_URL + item.profile.mediumAvatar" class="avatar-circle" v-else-if="item.profile.mediumAvatar && !item.profileSettings[0].isEnable" alt="">
                        <img v-if="item.rank === null" src="" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 1" src="../../assets/images/network/lvlUser0.svg" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 2" src="../../assets/images/network/lvlUser1.svg" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 3" src="../../assets/images/network/lvlUser2.svg" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 4" src="../../assets/images/network/lvlUser3.svg" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 5" src="../../assets/images/network/lvlUser4.svg" class="item-accordion-images-two mobile" alt="">
                        <img v-else-if="item.rank.id === 6" src="../../assets/images/network/lvlUser5.svg" class="item-accordion-images-two mobile" alt="">
                      </div>
                      <div class="item-accordion-description">
                        <div :class="item.isActivate ? 'fio activateUser' : 'fio'">
                          {{item.profile.fio}}
                        </div>
                        <div class="email" v-if="item.profileSettings[0].isEnable === false">
                          {{item.email}}
                        </div>
                        <div class="email" v-else-if="item.profileSettings[0].isEnable === true">
                          Почта скрыта
                        </div>
                      </div>
                    </div>
                    <div class="item-accordion mobile" @click="OpenChildren(item.id)" >
                      <img src="../../assets/images/network/unlock.svg" alt="" v-if="item.profileSettings[0].isEnable === false">
                      <img src="../../assets/images/network/lock.svg" alt="" v-else-if="item.profileSettings[0].isEnable === true">
                    </div>
                    <div class="item-accordion item-accordion-count" @click="OpenChildren(item.id)">
                      {{item.countChildren}}
                    </div>
                      <div class="item-accordion-arrow d-flex" @click="OpenChildren(item.id)">
                        <vue-feather class="arrow-down" type="chevron-down" :class="{ rotated: item.id === rotateIndex }"></vue-feather>
                      </div>
                    </div>
                    <recursive
                        v-if="isDropdownOpen && currentIndex === item.id"
                        :index="currentIndex"
                        :children="currentChildren"
                        :openModal="OpenModal"
                    ></recursive>
                 </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";
import networksDataService from "@/services/NetworksDataService";
import recursive from "@/pages/networks/recursiveChildren";
import modal from "@/pages/networks/modal.vue";
import IncomeChart from "@/pages/networks/IncomeChart.vue";


export default {
  data() {
    return {
      isLoading: true,
      isOpenModal: false,
      showPopover: false,
      isDropdownOpen: false,
      isCollapsed: {},
      currentIndex: null,
      currentChildren: [],
      isOpenChildren: false,
      referralCode: '',
      errorMessage: '',
      successMessage: '',
      rotateIndex: null,
      network: [],
      networkUser: [],
      openDropdowns: {},
    };
  },
  components: {
    IncomeChart,
    modal,
    recursive,
    AlertSuccess,
    AlertError
  },
  async mounted() {
    await UserDataService.get()
        .then(response => {
          const user = response.data;
          this.referralCode = user.user.referralCode;
        })
        .catch(e => {
          console.log(e);
        });

    networksDataService.get()
        .then(response => {
          this.network = response.data;
          this.isLoading = false;
        })
        .catch(e => {
          console.log(e);
        });
  },

  methods: {
    showDropdown() {
      this.isVisibleModal = true;
    },
    hideDropdown() {
      this.isVisibleModal = false;
    },
    OpenChildren(id) {
      if (this.currentIndex === id) {
        this.currentIndex = null;
        this.isDropdownOpen = false;
        this.rotateIndex = null;
      } else {
        this.currentIndex = id;
        this.rotateIndex = id;
        this.currentChildren = this.network.children.find(item => item.id === id).children;
        this.isDropdownOpen = true;
      }
    },
    OpenModal(id, enabled) {
      if (enabled === false) {
        networksDataService.getById(id).then(response => {
          this.networkUser = response.data;
          console.log("networkUser", this.networkUser)
          if (this.networkUser){
            this.isOpenModal = true
          }
        })
      }
    },
    getFirstLetter(fullName) {
      return fullName ? fullName.charAt(0).toUpperCase() : '';
    },
    closeModal() {
      this.isOpenModal = false;
    },
    copyLink() {
      navigator.clipboard.writeText(`https://rentier.world/my/auth/register?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    },
    copyLink2() {
      navigator.clipboard.writeText(`https://rentier.city?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    }
  }
};


</script>

<style scoped>

.accordion-wrapper{
  padding: 16px;
}

.wrapper-item-accordion-gray{
  background-color: #DEE2E650;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Занимает всю высоту экрана */
  background-color: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
}

.spinner {
  border: 8px solid #f3f3f3; /* Цвет фона */
  border-top: 8px solid #3498db; /* Цвет спиннера */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Анимация спиннера */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.description-popover{
  padding: 10px;
}
.title-popover{
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  padding: 9px 0;
  padding-left: 16px;
  background-color: #EAE8FF;
  color: #7366FF;
}
.title{
  position: relative;
}
.popover {
  position: absolute;
  top: 15px;
  right: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin: 10px;
  z-index: 999;
  opacity: 1;
  transition: all .5s;
}

.close{
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.avatar-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #7366FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.arrow-down {
  transition: transform 0.3s ease;
}
.wrapper-item-accordion{
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(222, 226, 230, 1)
}
.rotated {
  transform: rotate(90deg);
}
.email{
  color: rgba(82, 82, 108, 0.75);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.item-accordion-count{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.fio{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.activateUser{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #54BA4A;
}

.item-accordion-description{
  margin-left: 8px;
}

.item-accordion-images-two{
  margin-left: 8px;
}

.wrapper-network{
  max-width: 1000px;
  margin: 0 auto;
}

.item-accordion{
  width: 21%;
}

.title-accordion-item{
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 25%;
}

.item-accordion-one{
  width: 60%;
}

code{
  padding: 0 !important;
  margin: 0 !important;
}

.left{
  width: 100%;
}

.card-item {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 33px 25px 33px;
  box-shadow: 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 15px;
}

.card-item-network {
  flex: 0 0 auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 33px 25px 33px;
  box-shadow: 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 15px;
}

.prev{
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.title{
  color: rgba(47, 47, 59, 1);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.desription{
  margin-top:8px;
  margin-bottom:12px;
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.isVisible{
  opacity: 0;
}

.slide-enter-left {
  animation: slide-enter-left 0.8s ease-out;
}

.slide-enter-top {
  animation: slide-enter-top 0.8s ease-out;
}

.slide-enter-right {
  animation: slide-enter-right 0.8s ease-out;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  background: white;
  border-radius: 15px;
  z-index: 1001;
  max-width: 498px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1200px) {
  .mobile{
    display: none;
  }
  .item-card-lending{
    margin-top: 25px;
  }
  .prev{
    display:none;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slide-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-enter-top {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-enter-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>