import http from "../http-common";

export default {
    get() {
        return http.get('/promo-codes');
    },

    generate() {
        return http.post('/promo-codes/generate');
    },

    isPromoCode(promoCode) {
        return http.get(`/promo-codes/check/${promoCode}`);
    }
}

