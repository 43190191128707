<template>
  <div class="wrapper">
    <div class="card" v-if="!finishedTest">
      <div class="card-body">
        <div class="alert alert-danger" v-if="message"> {{ message }} </div>
        <div class="email-app-sidebar left-bookmark custom-scrollbar">
          <nav aria-label="Page navigation example">
            <ul
              class="pagination pagination-primary d-flex justify-content-center align-item-center"
            >
              <li class="page-item" v-if="currentQuestion > 2">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Previous"
                  @click="prevPage"
                >
                  <span aria-hidden="true">...</span>
                  <span class="sr-only">-</span>
                </a>
              </li>
              <li
                v-for="(page, index) in questions"
                :key="index"
                class="page-item cursor-pointer"
              >
                <a v-if="mutationPagination(index)"
                  class="page-link"
                  :class="{
                    active: currentQuestion === index,
                  }"
                  @click="currentQuestion = index, this.message = ''"
                  
                >
                  <p class="text-page-link">{{ index + 1 }}</p>
                </a>
              </li>
              <li class="page-item" v-if="currentQuestion + 1 < questions.length - 2">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Next"
                  @click="nextPage"
                >
                  <span aria-hidden="true">...</span>
                  <span class="sr-only">=</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div
          v-for="(item, index) in questions"
          :key="index"
          class="card-body"
          :class="{ 'd-none': index !== this.currentQuestion }"
        >
          <div class="row">
            <div class="col-sm-12">
              <h5>{{ item.question }}</h5>
            </div>
            <div class="col">
              <div class="card-body animate-chk">
                <form class="row">
                  <div
                    v-for="(answer, indexChildren) in item.answers"
                    :key="indexChildren"
                    class="radio radio-primary d-flex align-items-center border-bottom my-2 py-2"
                  >
                    <input
                      :id="'radio' + answer.id + indexChildren"
                      type="radio"
                      name="radio1"
                      :value="answer.id"
                      v-model="item.answer_id"
                    />
                    <label :for="'radio' + answer.id + indexChildren"
                      ><span class="d-flex align-items-center">
                        {{ answer.answer }}</span
                      ></label
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-12">
              <div
                class="btn btn-primary"
                @click="moveQuestion(1, this.currentQuestion - 1)"
              >
                Предыдущий
              </div>
              <div
                class="btn btn-light ms-3"
                @click="moveQuestion(2, this.currentQuestion + 1)"
                v-if="this.currentQuestion + 1 !== this.questions.length"
              >
                Cледующий
              </div>
              <div class="btn btn-success ms-3" v-else @click="endTest">Завершить тест</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="wrapper-finished">
      <div class="card-finished d-flex flex-column justify-content-center align-items-center" v-if="resultTest.isPassed">
        <img class="test-img" src="../../assets/images/seccus-test.jpg" alt="">
        <p class="text-center fs-3 px-5">
          Поздравляем! <br>
          Тест успешно пройден
        </p>
        <p class=" text-secondary text-center fs-5">
          {{ resultTest.result }} из {{resultTest.numberOfQuestions}}
        </p>
        <div class="button-wrapper">
          <router-link to="/career/my" class=" btn btn-primary">
            ОК
          </router-link>
        </div>
      </div>
      <div class="card-finished d-flex flex-column justify-content-center align-items-center" v-else>
        <img class="test-img" src="../../assets/images/danger-test.png" alt="">
        <p class="text-center fs-3 px-5">
          Провален! <br>
        </p>
        <p class=" text-secondary text-center fs-5">
          {{ resultTest.result }} из {{resultTest.numberOfQuestions}}
        </p>
        <div class="button-wrapper">
          <div @click="retest" class="btn btn-success me-2">
              Пройти еще раз
            </div>
          <router-link to="/career/my" class=" btn btn-primary">
            ОК
          </router-link>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import TestingDataService from "@/services/TestingDataService";

export default {
  data() {
    return {
      questions: [],
      currentQuestion: 0,
      questionsPerPage: 5,
      finishedTest:false,
      resultTest: [],
      message: '',
    };
  },
  methods: {
    showQuestion(index) {
      this.currentQuestion = index;
    },
    async getQuestionsForTest(id) {
      try {
        const response = await TestingDataService.getQuestionsForTest(id);
        for (let question of response.data) {
          this.questions.push({ ...question, answer_id: null });
        }
        this.currentQuestion = 0;
      } catch (error) {
        console.log(error);
      }
    },
    async moveQuestion(direction) {
      this.message = ''
      if (direction === 1 && this.currentQuestion > 0) {
        this.currentQuestion--;
      } else if (
        direction === 2 &&
        this.currentQuestion < this.questions.length - 1
      ) {
        this.currentQuestion++;
      }
    },
    retest(){
      location.reload()
    },
    mutationPagination(index) {   
      if (
        index >= this.currentQuestion - 2 &&
        index <= this.currentQuestion + 2
      ) {
        return true;
      }
    },
    async endTest() {
      const output = [];
      for (let item of this.questions) {
        const {id, answer_id: answerId} = item;
        output.push({id, answerId});
      }
      const array = await TestingDataService.postCompleteTest(this.$route.params.id, output)
        .then(({data}) => {
          this.finishedTest = true
          this.resultTest = data
          console.log(this.resultTest.isPassed);
            return data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.message = 'Вы ответили не на все вопросы!'
              console.log("Ошибка 400:", error.response.data);
            }
            if (error.response && error.response.status === 403) {
              this.message = 'Вы не можете пройти тест еще раз'
            }
            console.log(error);
          });
          console.log(array);
    }
  },
  computed: {
    // totalQuestions() {
    //   return this.resp.length;
    // },
    // totalPages() {
    //   return Math.ceil(this.totalQuestions / this.questionsPerPage);
    // },
    // displayedPages() {
    //   const start = Math.max(0, this.currentQuestion);
    //   const end = Math.min(this.resp.length);
    //   console.log("LOXLOXLOXLOXLOXLOX", Array.from({ length: end - start }, (_, i) => start + i + 1))
    //   return Array.from({ length: end - start }, (_, i) => start + i + 1);
    // },
  },
  mounted() {
    this.getQuestionsForTest(this.$route.params.id);
  },
};
</script>

<style scoped>
.button-wrapper{
  width: 100%;
  text-align: end;
}
.test-img{
  margin-bottom: 30px;
  width: 150px;
}
.wrapper{
  margin-top: 100px;
}
.wrapper-finished {
  height: 100vh;
  position: relative;
}
.card-finished{
  position: absolute;
  top: 10%;
  left: 50%;
  min-width: 550px;
  transform: translate(-50%, 0);
  padding: 20px;
  background-color: white;

}
.page-link.active {
  background: var(--theme-deafult) !important;
  border: none;
}
.active .text-page-link {
  color: white !important;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 600px) {
  .card-finished{
  position: absolute;
  top: 10%;
  left: 50%;
  min-width: 350px;
  transform: translate(-50%, 0);
  padding: 20px;
  background-color: white;

}
}
</style>
