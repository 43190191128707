<template>
  <div>
    <Breadcrumbs title="Создание мероприятия"/>
    <div class="row">
      <div class="col-sm-12">
        <div class="card event">
          <!-- <input-->
          <!--          ref="fileInput"-->
          <!--          type="file"-->
          <!--          hidden-->
          <!--          @change="handleFileUpload($event)"-->
          <!--          accept="image/*"-->
          <!--          capture-->
          <!--      />-->
          <!--        <div-->
          <!--            @click="triggerFileInput"-->
          <!--            :style="{-->
          <!--            backgroundImage: `url(${-->
          <!--              file ? imageUrl : imageEditing-->
          <!--            })`,-->
          <!--            height: file ? '25vw' : '10vw',-->
          <!--          }"-->
          <!--            :class="{ 'event__image': true, 'no-image': !file }"-->
          <!--        ></div> -->
          <div class="card-body">
            <form class="row">
              <div class="col-sm-12 col-md-12">
                <label class="form-label">Тема *</label>
                <input
                    v-model="formData.topic"
                    class="form-control"
                    :class="{ 'is-invalid': !formData.topic && isTouched3 }"
                    @input="isTouched3 = true"
                    type="text"
                    required
                />
                <div
                    v-if="!formData.topic && isTouched3"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>

              <div class="col-sm-12 col-md-12 mt-3">
                <label class="form-label">Организатор *</label>
                <input
                    v-model="formData.fromWhom"
                    class="form-control"
                    :class="{ 'is-invalid': !formData.fromWhom && isTouched1 }"
                    @input="isTouched1 = true"
                    type="text"
                    required
                />
                <div
                    v-if="!formData.fromWhom && isTouched1"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>

              <div class="col-sm-12 col-md-12 mt-3">
                <label class="form-label"
                >Анонс (отображается в списке событий) *</label
                >
                <textarea
                    v-model="formData.shortDescription"
                    class="form-textarea form-control"
                    :class="{ 'is-invalid': !formData.shortDescription && isTouched2 }"
                    @input="isTouched2 = true"
                    required
                ></textarea>
                <div
                    v-if="!formData.shortDescription && isTouched2"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>


              <div class="col-sm-12 col-md-12 mt-3">
                <label class="form-label"
                >Полное описание (отображается в карточке) *</label
                >
                <div>
                  <quill-editor v-model:value="stateDescription.content"
                                :options="stateDescription.editorOption"
                                @change="onEditorChange($event)"/>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Ведущий *</label>
                <input
                    v-model="formData.speaker"
                    class="form-control"
                    :class="{ 'is-invalid': !formData.speaker && isTouched4 }"
                    type="text"
                    @input="isTouched4 = true"
                    required
                />
                <div
                    v-if="!formData.speaker && isTouched4"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>

              <div class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Онлайн</label>
                <select
                    v-model="formData.isOnline"
                    class="form-control"
                    required
                >
                  <option :value="true">Да</option>
                  <option :value="false">Нет</option>
                </select>
              </div>

              <div class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Дата начала *</label>
                <input
                    v-model="formData.meetingDateStart"
                    class="form-control"
                    :class="{
                    'is-invalid': !formData.meetingDateStart && isTouched5,
                  }"
                    type="datetime-local"
                    @input="isTouched5 = true"
                    required
                />
                <div
                    v-if="!formData.meetingDateStart && isTouched5"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>

              <div class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Дата конца</label>
                <input
                    v-model="formData.meetingDateEnd"
                    class="form-control"
                    type="datetime-local"
                />
              </div>
              <div class="col-sm-6 col-md-6 mt-3" v-if="formData.isOnline">
                <label class="form-label">Ссылка встречи</label>
                <input
                    v-model="formData.meetingPlace"
                    class="form-control"
                    type="text"
                />
              </div>
              <div v-else class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Место проведения</label>
                <input
                    v-model="formData.meetingPlace"
                    class="form-control"
                    :class="{ 'is-invalid': !formData.meetingPlace }"
                    type="text"
                    required
                />
                <div class="invalid-feedback">Поле должно быть заполнено</div>
              </div>

              <div class="col-sm-6 col-md-6 mt-3">
                <label class="form-label">Цена</label>
                <input
                    v-model="formData.price"
                    class="form-control"
                    type="number"
                />
              </div>

              <div class="col-sm-12 col-md-12 mt-3">
                <div class="mb-3 row">
                  <label class="col-sm-3 col-form-label"
                  >Изображение анонс *</label
                  >
                  <div class="col-sm-9">
                    <input
                        class="form-control"
                        :class="{ 'is-invalid': !isFileSelected && isTouched6 }"
                        ref="fileInput"
                        type="file"
                        @change="handleFileUpload($event, true)"
                        accept="image/*"
                        @input="isTouched6 = true"
                        required
                    />
                    <div
                        v-if="!isFileSelected && isTouched6"
                        class="invalid-feedback"
                    >
                      Пожалуйста, выберите изображение.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 mt-3">
                <div class="mb-3 row">
                  <label class="col-sm-3 col-form-label"
                  >Изображение детальное *</label
                  >
                  <div class="col-sm-9">
                    <input
                        class="form-control"
                        :class="{ 'is-invalid': !isFileSelected && isTouched7 }"
                        ref="fileInput"
                        type="file"
                        @change="handleFileUpload($event, false)"
                        accept="image/*"
                        @input="isTouched7 = true"
                        required
                    />
                    <div
                        v-if="!isFileSelected && isTouched7"
                        class="invalid-feedback"
                    >
                      Пожалуйста, выберите изображение.
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button @click="uploadFile" class="btn btn-outline-primary btn-event">
          Добавить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingDataService from "@/services/MeetingDataService";
import {reactive} from "vue";

export default {
  setup() {
    const stateDescription = reactive({
      content: '',
      _content: '',
      editorOption: {
        placeholder: 'core',
      },
      disabled: false
    })
    const onEditorChange = ({quill, html, text}) => {
      stateDescription._content = html;
    }
    return {stateDescription, onEditorChange}
  },
  data() {
    return {
      imageEditing: require("@/assets/images/image_editing.png"),
      fileAnons: null,
      fileDetail: null,
      imageUrl: null,
      formData: {
        topic: "",
        fromWhom: "Рантье+",
        shortDescription: "",
        previewImagePath: "",
        titleImagePath: "",
        text: "",
        speaker: "",
        meetingDateStart: null,
        meetingDateEnd: null,
        isOnline: true,
        meetingPlace: "",
        price: 0,
        meetingRecordUrl: "",
      },
      content: "",
      editorOption: {
        Placeholder: "Please enter here",
      },
    };
  },
  methods: {
    handleFileUpload(event, isAnons) {
      if (isAnons) this.fileAnons = event.target.files[0];
      else this.fileDetail = event.target.files[0];
    },

    async uploadFile() {
      this.formData.text = this.stateDescription.content;
      // Находим все обязательные поля
      const requiredFields = document.querySelectorAll('input[required], textarea[required]');

// Проверяем каждое обязательное поле
      requiredFields.forEach(field => {
        if (!field.value) {
          // Если поле не заполнено, выделяем его красным
          field.classList.add('is-invalid');
        } else {
          // Если поле заполнено, удаляем класс is-invalid
          field.classList.remove('is-invalid');
        }
      });
      let formDataAnons = new FormData();
      formDataAnons.append("file", this.fileAnons);

      let formDataDetail = new FormData();
      formDataDetail.append("file", this.fileDetail);

      this.formData.titleImagePath =
          await MeetingDataService.fileUploadsMeetingTitle(formDataDetail)
              .then(({data}) => {
                return data.filepath;
              })
              .catch((error) => {
                console.log(error);

              });

      this.formData.previewImagePath =
          await MeetingDataService.fileUploadsMeetingPreview(formDataAnons)
              .then(({data}) => {
                return data.filepath;
              })
              .catch((error) => {
                console.log(error);

              });
      this.postMeeting();
    },
    postMeeting() {
      console.log(this.formData);
      MeetingDataService.postMeeting(this.formData)
          .then(() => {
            this.$router.push("/events/list");
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-image {
  background-size: 50px 50px; /* Размер иконки */
  background-repeat: no-repeat;
  background-position: center center; /* Выравнивание иконки по верхнему краю */
}

.ql-editor.ql-blank {
  opacity: 0 !important;
  color: gray; /* Цвет текста placeholder */
}
.btn.btn-outline-primary:hover{
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
</style>
