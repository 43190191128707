<template>
  <Breadcrumbs title="ДОГОВОР"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 m-auto">
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">ДОГОВОР</h4>
            <h6 class="text-center">уступки прав (цессии) по договору займа</h6>
            <p class="text-end">{{ date }}</p>
            <p>
              {{ userSender.lastName }} {{ userSender.firstName }} {{ userSender.middleName }}, именуемый(ая) в
              дальнейшем "Цедент", с одной стороны, и
              {{ userRecipient.profile?.lastName }} {{ userRecipient.profile?.firstName }}
              {{ userRecipient.profile?.middleName }}, именуемый(ая) в
              дальнейшем "Цессионарий", с другой стороны, заключили настоящий
              договор о нижеследующем.
            </p>
            <h6 class="text-center">1. ПРЕДМЕТ ДОГОВОРА</h6>
            <p>
              1.1. Цедент передает, а Цессионарий принимает права (требования), принадлежащие Цеденту и вытекающие из
              правоотношений между Цедентом и ООО «Рантье Финанс Групп» (ИНН 7743427684, в дальнейшем именуемым
              "Должник") в эквиваленте {{ amount }} рублей как части баланса внутреннего счета в личном
              кабинете проекта «Рантье».
            </p>

            <h6 class="text-center">2. ПЕРЕДАЧА ПРАВ. ПРАВА И ОБЯЗАННОСТИ СТОРОН ПО НАСТОЯЩЕМУ ДОГОВОРУ</h6>
            <p>2.1. Права (требования), принадлежащие Цеденту, возникли в силу правоотношений, основанных на акцепте
              Цедентом пользовательского соглашения, дистрибьюторском соглашении и иных (при наличии) соглашениях между
              Цедентом и Должником, совокупный объём обязательств которых отражается на внутреннем счете в личном
              кабинете проекта «Рантье».</p>
            <p>2.2. За уступаемые права (требования) Цедент вправе потребовать у Цессионария уплаты денежных средств,
              сумма и порядок передачи которых будут определяться дополнительным соглашением Сторон.</p>
            <p>2.3. Цедент уже уведомил Должника о состоявшейся уступке прав по настоящему договору. </p>
            <p>2.4. Цедент отвечает за действительность передаваемых по настоящему договору прав (требований).</p>
            <p>2.5. За неисполнение или ненадлежащее исполнение настоящего договора стороны несут ответственность по
              действующему законодательству РФ.</p>
            <h6 class="text-center">3. КОНФИДЕНЦИАЛЬНОСТЬ</h6>
            <p>3.1. Условия настоящего договора и дополнительных соглашений к нему конфиденциальны и не подлежат
              разглашению.</p>
            <h6 class="text-center">4. РАЗРЕШЕНИЕ СПОРОВ</h6>
            <p>4.1. Все споры и разногласия, которые могут возникнуть между сторонами по вопросам, не нашедшим своего
              разрешения в тексте данного договора, будут разрешаться путем переговоров на основе действующего
              законодательства РФ и обычаев делового оборота.</p>
            <p>4.2. При неурегулировании в процессе переговоров споры разрешаются в суде в порядке, установленном
              действующим законодательством РФ.</p>
            <h6 class="text-center"> 5. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </h6>
            <p>5.1. Настоящий договор вступает в силу с момента его подписания Цедентом и Цессионарием и действует до
              полного исполнения ими своих обязательств.</p>
            <hr/>
            <h6 class="text-center">АДРЕСА И ИНЫЕ ДАННЫЕ О СТОРОНАХ:</h6>
            <div class="row">
              <div class="col">
                <p class="mt-3">Цедент:</p>
                <p>
                  <span class="fw-bold">
                    {{ userSender.lastName }}
                    {{ userSender.firstName }}
                    {{ userSender.middleName }}
                  </span> <br/>
                  Эл. почта:{{ userSender.user?.email }} <br/>
                  Телефон: {{ userSender.phone }} <br><br/>
                  <strong>Паспорт РФ</strong> <br/>
                  <span v-if="userSender.passportNumber">
                Серия и номер: {{ userSender.passportNumber }} <br/>
                  выдан {{ new Date(userSender.passportDate).toLocaleDateString('ru-Ru') }} <br/>
             </span>
                  <span v-if="userSender.passportIssued">{{ userSender.passportIssued }} <br/></span>
                  <span v-if="userSender.passportCode">Код подразделения: {{ userSender.passportCode }}<br/></span>
                  <span v-if="userSender.passportAddress">Адрес регистрации: {{
                      userSender.passportAddress
                    }}<br/></span>
                  <span v-if="userSender.inn">ИНН: {{ userSender.inn }}<br/></span>
                  <span v-if="userSender.snils">СНИЛС: {{ userSender.snils }}<br/></span>
                  <p>_______________ /  {{ userSender.lastName }}    {{ userSender.firstName }}  {{ userSender.middleName }} </p>
                </p>
              </div>
              <div class="col">
                <p class="mt-3">Цессионарий:</p>
                <p>
                 <span class="fw-bold">
                    {{ userRecipient.profile?.lastName }} {{
                     userRecipient.profile?.firstName
                   }} {{ userRecipient.profile?.middleName }}
                 </span> <br/>
                  Эл. почта:{{ userRecipient.email }} <br/>
                </p>
                <p>_______________ / {{ userRecipient.profile?.lastName }} {{ userRecipient.profile?.firstName }}
                  {{ userRecipient.profile?.middleName }} </p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";

export default {
  data() {
    return {
      date: new Date().toLocaleDateString('ru-Ru'),
      errorMessage: '',
      successMessage: '',
      amount: 0,
      userSender: {},
      userRecipient: {},
    };
  },
  components: {
    AlertSuccess,
    AlertError
  },
  mounted() {
    this.amount = JSON.parse(localStorage.getItem('amount'));
    this.userSender = JSON.parse(localStorage.getItem('userSender'));
    this.userRecipient = JSON.parse(localStorage.getItem('userRecipient'));
  },
};
</script>