<template>
  <div class="h-100">
    <div class="card h-100">
      <div class="card-header card-no-border" style="padding: 20px 30px">
        <div class="header-top">
          <h5 class="m-0">СУММАРНЫЙ МОЙ ДОХОД</h5>
        </div>
      </div>
      <div class="white-card balance-card d-inline-block mx-3">
          <h4 class="mb-0">
            {{ sum }} ₽
          </h4>
        </div>
        <div class="order-wrapper w-100 h-100 m-1">
          <apexchart
            height="245"
            type="line"
            v-if="!ecomDashboard.isLoading"
            :options="ecomDashboard.options"
            :series="ecomDashboard.series"
          ></apexchart>
          <div v-else class="d-flex justify-content-center align-items-center w-100 h-100">
            <div class="loader-cover"></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown1 from "./DropDown1.vue";
import { ecomDashboard } from "@/data/comon.js";
import UserDataService from '@/services/UserDataService';

export default {
  components: {
    DropDown1,
  },
  data() {
    return {
      ecomDashboard: ecomDashboard,
      totalIncome: [],
      sum: 0,
    };
  },
  mounted(){
    this.getProfitByMonth()
  },
  methods: {
    async getProfitByMonth() {
      UserDataService.getProfitByMonth()
      .then((response) => {
        response.data.forEach(element => {
            this.totalIncome.push(Math.round(element.amount))
        });
        this.sum = this.totalIncome.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      })
      .catch((e) => {
        console.log(e);
      })
    }
  }
};
</script>
<style scoped>
#SvgjsText1267{
  opacity: 0 !important;
}
</style>
