<template>
  <div class="wrapper-close-chat d-flex ">
    <div class="wrapper-close-chat-img">
      <img src="../../../assets/images/chats/Circle_Warning_Orange.svg" alt="">
    </div>
    <div class="wrapper-close-chat-text" v-if=" typeBlock === 1">
      Этот пользователь ограничил круг лиц, имеющих доступ для отправки ему сообщений.
    </div>
    <div class="wrapper-close-chat-text" v-else-if="typeBlock === 2">
      Вы заблокировали данного пользователя. Чтобы продолжить диалог необходимо разблокировать пользователя.
    </div>
    <div class="wrapper-close-chat-text" v-else-if="typeBlock === 3">
      Этот пользователь ограничил круг лиц, имеющих доступ для отправки ему сообщений.
    </div>
    <div class="wrapper-close-chat-text" v-if=" typeBlock === 4">
      Вы заблокировали данного пользователя. Чтобы продолжить диалог необходимо разблокировать пользователя.
    </div>
    <div class="wrapper-close-chat-text" v-if=" typeBlock === 5">
      Чат был закрыт, и у вас больше нет возможности продолжать переписку.
    </div>
  </div>
</template>

<script>
export default {
  name: "closeChat",
  props:{

    typeBlock:{
      type:Number,
      required: true
    },
  },
}

</script>

<style scoped>
.wrapper-close-chat-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(82, 82, 108, 0.75);
}
.wrapper-close-chat-text{
  margin-left: 10px;
  display: flex;
  text-align: center;
  align-items: center;
}
</style>