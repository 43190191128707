import http from "../http-common";


export default {

    getChats(pageSizeChats) {
        return http.get(`/chats?pageSize=${pageSizeChats}`);
    },

    postChats(respondingUserId) {
        return http.post('/chats', respondingUserId);
    },

    getChat(id, pageSize, page) {
        return http.get(`/chats/${id}?pageSize=${pageSize}&page=${page}`);
    },

    postFiles(data){
        return http.post('/files/upload/chats/files', data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    getListReport(){
        return http.get(`/chats/complaints`)
    },

    postChatReport(blacklist){
        return http.post(`/chats/complaints`, blacklist)
    },

    delChat(id){
      return http.delete(`/chats/${id}`);
    },
}