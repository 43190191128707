<template>
  <div class="alone-page d-flex flex-column align-items-center justify-content-center">
    <div class="wrapper-internal-server-error d-flex flex-column align-items-center justify-content-center">
      <div class="img-internal-server-error">
        <img src="@/assets/svg/sad_smile.svg" alt="">
      </div>
      <div class="title-internal-server-error">
        500
      </div>
      <div class="description-internal-server-error text-center">
        Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время. Приносим извинения за временные неудобства.
      </div>
      <button @click="refreshPage" class="btn btn-danger-custom mt-5">
        Обновить
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ServerFatalError',
  data() {
    return {
      isErrorInternal: JSON.parse(localStorage.getItem('internalError')) || false,
    };
  },
  mounted() {
    this.checkError()
  },
  methods: {
    refreshPage() {
      this.$router.go(-1);
    },
    checkError() {
      if (!this.isErrorInternal) {
        this.$router.go(-1);
      }
    }
  }
};
</script>

