import http from "../http-common";

export default {
    getPaymentsStatistic(startOfPeriod, endOfPeriod) {

        return http.get('/directors-statistics/payments-statistic', {
            params: {
                startOfPeriod: startOfPeriod,
                endOfPeriod: endOfPeriod
            }
        });
    },
    getPaymentsStatisticGraph(startOfPeriod, endOfPeriod) {
        return http.get('/directors-statistics/payments-statistic-graph', {
            params: {
                startOfPeriod: startOfPeriod,
                endOfPeriod: endOfPeriod
            }
            // params: {
            //     startOfPeriod: "2024-07-12T09:58:22.361Z",
            //     endOfPeriod: "2024-08-12T09:58:22.361Z"
            // }
        });
    },
    getUsersStatistic() {
        return http.get('/directors-statistics/users-statistics', {
        });
    },
    getUsersStatisticGraph(startOfPeriod, endOfPeriod) {
        return http.get('/directors-statistics/users-statistics-grapg', {
            params: {
                startOfPeriod: startOfPeriod,
                endOfPeriod: endOfPeriod
            }
        });
    },
    getUsers(page, pageSize) {
        return http.get('/directors-statistics/all-users', {
            params: {
                page: page,
                pageSize: pageSize,
            }
        });
    },
    getFullInfoUser(userId) {
        return http.get('/directors-statistics/user-full-info', {
            params: {
                userId: userId
            }
        });
    },
    getNetworkFirstLevel(userId) {
        return http.get('/directors-statistics/user-network-first-level', {
            params: {
                userId: userId
            }
        });
    },
    getUsersByFilters(page, pageSize, startOfReg, endOfReg, isActivate) {
        return http.get('/directors-statistics/all-users-by-filters', {
            params: {
                page: page,
                pageSize: pageSize,
                isActivate: isActivate,
                startOfReg: startOfReg,
                endOfReg: endOfReg,
            }
        });
    },
    getUsersByEmail(page, pageSize, email) {
        return http.get('/directors-statistics/all-users-by-email', {
            params: {
                page: page,
                pageSize: pageSize,
                email: email
            }
        });
    },
}

