<template>
  <div class="alone-page d-flex flex-column align-items-center justify-content-center">
    <div class="wrapper-not-found d-flex flex-column align-items-center justify-content-center">
      <div class="img-not-found">
        <img src="@/assets/svg/sad_smile.svg" alt="">
      </div>
      <div class="title-not-found">
        404
      </div>
      <div class="description-not-found text-center">
        Страница, на которую вы пытаетесь перейти, в данный момент недоступна. Возможно, это связано с тем, что страница не существует или была перемещена.
      </div>
      <router-link to="/" class="btn btn-danger-custom mt-5">
        Вернуться на главную страницу
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  name: 'notFound',
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

