<template>
    <div class="modal" tabindex="-1" ref="modal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body mb-4">
                    <div class="d-flex justify-content-center">
                        <div class="align-self-center text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16" style="color: #1db71d"
                                v-if="props.type === 'success'">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                class="bi bi-x-circle-fill" viewBox="0 0 16 16" style="color: #b50707"
                                v-else-if="props.type === 'error'">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                class="bi bi-clock-fill" viewBox="0 0 16 16" style="color: #dbc200"
                                v-else-if="props.type === 'waiting'">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                            </svg>
                            <h1 class="mt-2 h3">{{ props.title }}</h1>
                            <p v-html="props.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch, toRefs } from 'vue';
import { Modal } from 'bootstrap';

const props = defineProps({
    type: String,
    title: String,
    description: String,
    openEvent: Boolean
})

const modal = ref()
const modalObj = ref()

function showModal() {
    modalObj.value.show()
}

function hideModal() {
    modalObj.value.hide()
}

watch(() => props.openEvent, (val, oldVal) => {
    showModal()
})

onMounted(() => {
    modalObj.value = new Modal(modal.value)
    if (props['is-active'] == true) showModal()
})
</script>