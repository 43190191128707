<template>
  <div class="wrapper-application-page mt-5 mt-md-0">
    <div class="item-wrappers-application item-phone">
      <div class="content-area d-flex flex-column align-items-center align-items-md-start mx-0 mx-md-5">
        <h1 class="mb-4 text-center text-md-start mt-5 mt-md-0">
          Телеграмм-бот "РАНТЬЕ" — все для вашего бизнеса в одном месте!
        </h1>
        <p class="description-text text-center text-md-start mb-5">
          Откройте новые возможности для бизнеса и заработка с простыми инструментами прямо в Телеграме.
        </p>
        <a class="btn btn-primary" href="https://t.me/RantiieBot">
          Перейти в тг-бот
        </a>
        <div class="block-img w-100">
        </div>
      </div>
    </div>
    <div class="item-wrappers-application">
      <h1 class="title my-5">
        Чем полезен наш бот?
      </h1>
      <div class="content-area d-flex flex-column-reverse flex-xl-row justify-content-between">
        <div>
          <img class="telephone mt-5 mt-xl-0" :src="telephoneImage" alt="Telephone Image">
        </div>
        <div class="list-usefulness-bot flex-grow-1">
          <div v-for="(item, index) in items" :key="index" class="list-usefulness-bot-item d-flex">
            <div class="icon">
              <img :src="item.icon" alt="Icon">
            </div>
            <div class="list-usefulness-bot-text">
              <div class="list-usefulness-bot-item-title">
                {{ item.title }}
              </div>
              <div class="list-usefulness-bot-item-subtitle">
                {{ item.subtitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-wrappers-application">
      <h1 class="title my-5">
        Как начать?
      </h1>
      <div class="content-area d-flex flex-column flex-xl-row justify-content-between row">
        <div class="item-how-start col-xxl-4">
          <div class="item-how-start-title d-flex">
            <div class="item-how-start-title-icon d-flex justify-content-center align-items-center">
              1
            </div>
            <div class="item-how-start-title-text d-flex align-items-center">
              Откройте бота в Телеграме
            </div>
          </div>
          <div class="item-how-start-img">
            <img class="item-how-start-title-abs-mobile second" src="@/assets/images/documentation/how-start/line-mobile-1.svg" alt="Dotted Line">
            <img class="item-how-start-title-img" src="@/assets/images/documentation/how-start/Pic-1-step.png">
          </div>
        </div>
        <div class="item-how-start col-xxl-4">
          <div class="item-how-start-title d-flex">
            <div class="item-how-start-title-icon d-flex justify-content-center align-items-center">
              2
            </div>
            <div class="item-how-start-title-text d-flex align-items-center">
              Нажмите "start" и следуйте простым инструкциям
            </div>
          </div>
          <div class="item-how-start-img text-end text-xxl-start">
            <img class="item-how-start-title-abs first" src="@/assets/images/documentation/how-start/Dotted%20line.png" alt="Dotted Line">
            <img class="item-how-start-title-abs-mobile first" src="@/assets/images/documentation/how-start/line-mobile-2.svg" alt="Dotted Line">
            <img class="item-how-start-title-img" src="@/assets/images/documentation/how-start/Pic-2-step.png" alt="Step Image">
          </div>
        </div>
        <div class="item-how-start col-xxl-4">
          <div class="item-how-start-title d-flex">
            <div class="item-how-start-title-icon d-flex justify-content-center align-items-center">
              3
            </div>
            <div class="item-how-start-title-text d-flex align-items-center">
              Начните пользоваться всеми функциями
            </div>
          </div>
          <div class="item-how-start-img">
            <img class="item-how-start-title-abs second" src="@/assets/images/documentation/how-start/Dotted%20line.png" alt="Dotted Line">
            <img class="item-how-start-title-img" src="@/assets/images/documentation/how-start/Pic-3-step.png">
          </div>
        </div>
      </div>
    </div>
    <div class="item-wrappers-application card">
      <div class="col-12 col-xl-7 text-center text-xl-start">
        <h2 class="text-uppercase text-white text-center text-xl-start">
          начните зарабатывать уже сегодня!
        </h2>
        <span class="last-text text-white d-block mt-4 text-center text-xl-start">
          Присоединяйтесь к Телеграмм-боту "РАНТЬЕ" и откройте для себя уникальные возможности для роста и заработка.
        </span>
        <a class="btn btn-primary mt-5 text-center" href="https://t.me/RantiieBot">
          Перейти в тг-бот
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      telephoneImage: require('@/assets/images/documentation/Pic-Advantages.png'),
      items: [
        {
          icon: require('@/assets/images/documentation/Icons.png'),
          title: 'Бонусы и подарки',
          subtitle: 'Приятные награды за активное участие'
        },
        {
          icon: require('@/assets/images/documentation/Icons1.png'),
          title: 'Развлекательные игры с доходом',
          subtitle: 'Игры с возможностью заработка'
        },
        {
          icon: require('@/assets/images/documentation/Icons2.png'),
          title: 'Искусственный интеллект',
          subtitle: 'ИИ готов ответить на ваши вопросы'
        },
        {
          icon: require('@/assets/images/documentation/Icons3.png'),
          title: 'Рекламная площадка',
          subtitle: 'Возможность продвижения своих ресурсов'
        },
        {
          icon: require('@/assets/images/documentation/Icons4.png'),
          title: 'Увеличение партнерской сети',
          subtitle: 'Легкий способ масштабировать сеть'
        },
        {
          icon: require('@/assets/images/documentation/Icons5.png'),
          title: 'Сокращенные ссылки',
          subtitle: 'Делайте ссылки короткими и удобными'
        },
        {
          icon: require('@/assets/images/documentation/Icons6.png'),
          title: 'Постоянный доход',
          subtitle: 'Возможности ежедневного заработка через бота'
        },
      ]
    }
  }
}
</script>