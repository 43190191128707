<template>
  <div class="h-100">
    <div class="card h-100" style="padding: 20px 30px">
      <div class="wrapper d-flex flex-column justify-content-center align-items-center z-2">
        <img src="@/assets/images/none-event.svg" alt="">
        <div class="d-flex flex-column justify-content-center align-items-center mt-3">
          <h5 class="mb-3 text-center">
            НЕТ БЛИЖАЙШИХ СОБЫТИЙ
          </h5>
          <p class="text-center card-text-sub">
            Информация о самых перспективных проектах
            и возможности инвестирования в инновационные решения.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default({
  name: "Events",
  props: {

  },
  data() {
    return {

    };
  },
  mounted(){

  },
  methods: {


  }
})
</script>

<style scoped>

</style>