<template>
  <div class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card growth-wrap">
          <div class="card-header card-no-border">
            <div class="header-top">
              <h5>Доход помесячный</h5>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="growth-wrapper">
              <apexchart
                height="200"
                type="line"
                :options="apexDashboard.options6"
                :series="apexDashboard.series6"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown3 from "./DropDown3.vue";
import { apexDashboard } from "@/data/comon";
export default {
  components: {
    DropDown3,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
    };
  },
};
</script>
