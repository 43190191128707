w<template>
    <div>
        <Breadcrumbs title="ОБРАЩЕНИЕ ЗА ПОМОЩЬЮ" />

        <div class="text-start">
            <alert-error v-if="errorMessageOne" :text="errorMessageOne" class="message-error-or-succes"/>
            <alert-success v-if="successMessageOne" :text="successMessageOne" class="message-error-or-succes"/>
            <div class="card">
                <div class="card-header pb-0">
                    <h3>ОБРАЩЕНИЕ ЗА ПОМОЩЬЮ</h3>
                </div>
                <form class="form theme-form">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="mb-6">
                                    <label class="form-label" for="exampleInputPassword2">Тема</label>
                                    <input class="form-control" id="exampleInputPassword2" type="text"
                                        placeholder="Тема обращения" v-model="forum.title">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-6">
                                    <label class="form-label" for="exampleFormControlSelect9">Тип запроса</label>
                                    <select class="form-select digits" id="exampleFormControlSelect9"
                                        v-model="forum.requestType">
                                        <option v-for="(item) of typesRequest" :key="item.id" :value="item.id">{{
                        item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-6">
                                    <label class="form-label" for="exampleFormControlSelect9">Тип обращения</label>
                                    <select class="form-select digits" id="exampleFormControlSelect9"
                                        v-model="forum.threadType">
                                        <option v-for="(item) of typesThread" :key="item.id" :value="item.id">{{
                                                    item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <label class="form-label" for="exampleFormControlTextarea4">Текст обращения</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"
                                        v-model="forum.message"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-end">
                        <div class="btn btn-primary  m-r-10" type="submit" @click="postForms">Отправить</div>
                        <input class="btn btn-light" type="reset" value="Сбросить">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import ForumDataService from "@/services/ForumDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";
export default {
    data() {
        return {
            typesThread: [],
            typesRequest: [],
            requestType: [],
            pageSize: 15,
            page: 0,
            errorMessageOne: '',
            successMessageOne: '',
            title: "",
            forum: {
                requestType: Number,
                threadType: Number,
                title: "",
                message: "",
            }
        };
    },
    components: {
        AlertSuccess,
        alertError,
    },
    mounted() {
        ForumDataService.getThread()
            .then((response) => {
                this.typesThread = response.data;
            })
        ForumDataService.getRequest()
            .then((response) => {
                this.typesRequest = response.data;
            })
        ForumDataService.getThread()
            .then((response) => {
                this.typesThread = response.data;
            })


    },
    methods: {
      postForms() {
         ForumDataService.postForum(this.forum, this.pageSize, this.page)
        .then(response => {
            this.successMessageOne = 'Обращение успешно создано.';
          this.$nextTick(() => {
            setTimeout(() => {
              this.$router.push('/forum/user/list');
            }, 500);
          });
        })
        .catch(error => {
            this.errorMessageOne = 'Произошла ошибка при отправке данных.';
          this.$nextTick(() => {
            setTimeout(() => {
              this.errorMessageOne = '';
            }, 3000);
          });
        });

    if (!this.errorMessageOne) {
        this.successMessageOne = '';
    }
    if (!this.successMessageOne) {
        this.errorMessageOne = '';
    }
}
    },

}
</script>

<style scoped>
.message-error-or-succes{
    position: absolute;
    top: 15px;
    right: 50px;
    z-index: 1000;
}
</style>